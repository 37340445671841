import React, { useEffect, useState } from "react";
import Title from "../../components/Title/Title";
import Layout from "../../components/Layout/Layout";
import "./MyLoans.scss";
import NotFoundLoan from "../../components/NotFoundLoan/NotFoundLoan";
import { APICall } from "../../api/APICall";
import { AppConstants } from "../../constants/AppConstants";
import { Get_Loan_Details } from "../../api/APIs";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import useToast from "../../hooks/useToast";
import LoanDetailsCard from "../../components/LoanDetailsCard/LoanDetailsCard";
import { EN } from "../../constants/Strings/EN";
import { setBackButton } from "../../redux/actions";
import { useDispatch } from "react-redux";

function MyLoans() {
  const [isActiveLoans, setIsActiveLoans] = useState(true);
  const [activeLoans, setActiveLoans] = useState([]);
  const [closedLoans, setClosedLoans] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const getLoanDetails = async () => {
    setLoading(true);
    const payload = {
      DATA: {
        lenderCode: AppConstants.lenderCode,
      },
      HEADER: {
        AID: userData.mobile,
        MID: userData.mobile,
        userId: userData.userId,
      },
    };

    await APICall({ url: Get_Loan_Details, payload: payload })
      .then((backendRes) => {
        if (backendRes.RESP_CODE === 300) {
          // setActiveLoans(backendRes.DATA.activeLoan);
          setClosedLoans(backendRes.DATA.closeLoan);
          setActiveLoans(backendRes.DATA.activeLoan);
          setLoading(false);
        } else {
          setLoading(false);
          showToast(backendRes.RESP_MSG, "error", 3000);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getLoanDetails();
    dispatch(
      setBackButton({
        backButton: true,
      })
    );
  }, []);

  return (
    <Layout>
      <div className="loans-container">
        <Title title="My Loans" />
        <div className="tabs">
          <button
            className={`tablinks${isActiveLoans ? " active" : ""}`}
            onClick={() => setIsActiveLoans(true)}
          >
            {EN.active}
          </button>
          <button
            className={`tablinks${isActiveLoans ? "" : " active"}`}
            onClick={() => setIsActiveLoans(false)}
          >
            {EN.closed}
          </button>
        </div>
        {isActiveLoans ? (
          <div id="active-loans">
            {activeLoans.length > 0 ? (
              activeLoans?.map((loan) => {
                return <LoanDetailsCard loanDetails={loan} />;
              })
            ) : (
              <NotFoundLoan type="active" />
            )}
          </div>
        ) : (
          <div id="closed-loans">
            {closedLoans.length > 0 ? (
              closedLoans?.map((loan) => {
                return <LoanDetailsCard loanDetails={loan} />;
              })
            ) : (
              <NotFoundLoan type="closed" />
            )}
          </div>
        )}
      </div>
      <LoadingModal isOpen={loading} setIsopen={setLoading} />
    </Layout>
  );
}

export default MyLoans;
