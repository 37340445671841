const PLATFORM = "/lending/pwa/meesho/platform";

//platform URL
export const User_Dedupe_Detail = `${PLATFORM}/user-dedupe-detail`;
export const Core_Control_Details = `${PLATFORM}/core-control-details`;
export const Generate_Aadhaar_OTP = `${PLATFORM}/generate-aadhaar-otp`;
export const Validate_Aadhaar_Detail = `${PLATFORM}/validate-aadhaar-detail`;
export const create_user_enquiry = `${PLATFORM}/create-user-enquiry`;
export const Check_Serviceable_Pincode = `${PLATFORM}/check-serviceable-pincode`;
export const Validate_Pan_Create_Lead = `${PLATFORM}/validate-pan-create-lead`;
export const Save_User_Consent = `${PLATFORM}/save-user-consent`;
export const Create_User_Enquiry_And_lead = `${PLATFORM}/create-user-enquiry-and-lead`;
export const Validate_Udyam_Detail = `${PLATFORM}/validate-udyam-detail`;
export const upload_Bank_Statement = `${PLATFORM}/uploadBankStatement`;
export const upload_document_multipart = `${PLATFORM}/upload-document-multipart`;
export const Confirm_Lead_Detail = `${PLATFORM}/confirm-lead-detail`;
export const Update_Residence_Details = `${PLATFORM}/update-residence-details`;
export const Get_Pincode_Dtl = `${PLATFORM}/get-pincode-dtl`;
export const Get_Sanction_Loan_Calculation = `${PLATFORM}/get-sanction-loan-calculation`;
export const Confirm_Sanction_Amount = `${PLATFORM}/confirm-sanction-amount`;
export const Get_Bank_Branch_Dtl = `${PLATFORM}/get-bank-branch-dtl`;
export const Save_Bank_Details = `${PLATFORM}/save-bank-details`;
export const Get_Enach_Details = `${PLATFORM}/get-enach-details`;
export const Confirm_Enach_Details = `${PLATFORM}/confirm-enach-details`;
export const Upload_Document = `${PLATFORM}/upload-document-multipart`;
export const Confirm_Business_Details = `${PLATFORM}/confirm-business-details`;
export const Create_Loan = `${PLATFORM}/create-loan`;
export const Get_Profile_Details = `${PLATFORM}/get-user-profile-details`;
export const Get_Loan_Details = `${PLATFORM}/get-active-loan-details`;
export const Generate_Sanction_Letter = `${PLATFORM}/generate-sanction-letter`;
export const Generate_E_Agreement = `${PLATFORM}/generate-e-agreement`;
export const Generate_Esign_Url = `${PLATFORM}/generate-esign-url`;
export const Get_Key_Fact_Details = `${PLATFORM}/get-key-fact-details`;
export const Get_Contact_Details = `${PLATFORM}/get-contact-us-details`;
export const Get_Offer_Details_By_Tenure = `${PLATFORM}/get-offer-details-by-tenure`;
export const Esign_Status_Check = `${PLATFORM}/esign-status-check`;
export const Get_Repayment_Schedule = `${PLATFORM}/get-repayment-schedule`;
