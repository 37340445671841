import React, { useEffect, useRef, useState } from "react";
import { APICall } from "../../api/APICall";
import { Confirm_Lead_Detail, Validate_Pan_Create_Lead } from "../../api/APIs";
import Button from "../../components/Button/Button";
import Faq from "../../components/Faq/Faq";
import InputField from "../../components/InputField/InputField";
import Layout from "../../components/Layout/Layout";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import PermissionModal from "../../components/PermissionModal/PermissionModal";
import Title from "../../components/Title/Title";
import { AppConstants } from "../../constants/AppConstants";
import { EN } from "../../constants/Strings/EN";
import useDedupe from "../../hooks/useDedupe";
import useToast from "../../hooks/useToast";
import {
  panFormatter,
  validateEmail,
  validateMobileNumber,
} from "../../util/util";
import "./Details.scss";
import { useSelector } from "react-redux";

const udyamHelperNode = (
  <>
    {EN.as_per_your_udyam_Registration_Certificate},&nbsp;
    <span className="udyamHelper">
      {EN.dont_have_Udyam_Click_here_to_get_help}
    </span>
  </>
);

export default function Details() {
  let userData = JSON.parse(localStorage.getItem("userData"));
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [faqVisible, setFaqVisible] = useState(false);
  const btnRef = useRef();
  const { callDedupe } = useDedupe();
  const { showToast } = useToast();

  const [panNo, setPanNo] = useState("");
  // const [udyam, setUdyam] = useState("");
  const [email, setEmail] = useState("");
  const [secondaryMobile, setSecondaryMobile] = useState("");
  const [monthlyIncome, setMonthlyIncome] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [TncModalVisible, setTncModalVisible] = useState(false);
  const [helper, setHelper] = useState({
    pan: "",
    // udyam: udyamHelperNode,
    email: EN.should_be_an_active_and_accessible_account,
    pincode: EN.as_per_your_aadhaar_card,
    secondaryMobile: "",
  });
  // const [pincode, setPincode] = useState("");
  // const [validatePincodeFlag, setValidatePincodeFlag] = useState(false);
  const [tnc, setTnc] = useState(false);
  const [panConsent, setPanConsent] = useState(false);
  const [isVerified, setIsVerified] = useState({
    pan: false,
    // udyam: false,
    email: false,
    // pincode: false,
    monthlyIncome: false,
    middleName: false,
    secondaryMobile: false,
  });
  const [error, setError] = useState({
    pan: false,
    // udyam: false,
    email: false,
    // pincode: false,
    monthlyIncome: false,
    middleName: false,
    secondaryMobile: false,
  });
  const [isConfirm, setIsConfirm] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (user?.currentStage === "NEW") setOpenModal(true);
    else {
      getDefault();
      setOpenModal(false);
    }
  }, [user?.currentStage]);

  useEffect(() => {
    getUserData();
    getDefault();
  }, []);

  const getUserData = () => {
    callDedupe();
  };

  const getDefault = async () => {
    setLoading(true);
    if (userData?.panName) {
      setPanNo(userData?.pan);
      setIsVerified((prev) => ({
        ...prev,
        pan: true,
      }));
      setError((prev) => ({
        ...prev,
        pan: false,
      }));
      setHelper((prev) => ({
        ...prev,
        pan: "PAN name - " + userData?.panName,
      }));
    }

    // if (userData?.udyamNumber) {
    //   setUdyam(userData?.udyamNumber);
    //   // setIsVerified((prev) => ({
    //   //   ...prev,
    //   udyam: true,
    //   // }));
    //   setHelper((prev) => ({
    //     ...prev,
    //     udyam: "Business name - " + userData.businessName,
    //   }));
    //   setError((prev) => ({
    //     ...prev,
    //     udyam: false,
    //   }));
    // }
    if (userData?.email) {
      setEmail(userData?.email);
    }
    if (userData?.secondaryMobile) {
      setSecondaryMobile(userData?.secondaryMobile);
    }
    setLoading(false);
  };

  // const validatePan = (e) => {
  //   const res = panFormatter(panNo);
  //   if (res) {
  //     // setPanNo(e.target.value);
  //     verifyPan();
  //   } else {
  //     setHelper((prev) => ({
  //       ...prev,
  //       pan: EN.please_enter_valid_personal_PAN_number,
  //     }));
  //     setError((prev) => ({
  //       ...prev,
  //       pan: true,
  //     }));
  //   }
  // };

  const validateMobile = (e) => {
    const res = validateMobileNumber(secondaryMobile);
    if (res) {
      // setSecondaryMobile(e.target.value);
      setError((prev) => ({
        ...prev,
        secondaryMobile: false,
      }));
      setHelper((prev) => ({
        ...prev,
        secondaryMobile: "",
      }));
    } else {
      setHelper((prev) => ({
        ...prev,
        secondaryMobile: EN.please_enter_valid_mobile_number,
      }));
      setError((prev) => ({
        ...prev,
        secondaryMobile: true,
      }));
    }
  };

  const emailValidation = (e) => {
    const res = validateEmail(email);
    if (res) {
      setHelper((prev) => ({
        ...prev,
        email: EN.should_be_an_active_and_accessible_account,
      }));
      setError((prev) => ({
        ...prev,
        email: false,
      }));
    } else {
      setHelper((prev) => ({ ...prev, email: EN.please_provide_valid_email }));
      setError((prev) => ({
        ...prev,
        email: true,
      }));
    }
  };

  // const udyamValidation = () => {
  //   const res = validateUdyam(udyam);
  //   if (res) {
  //     verifyUdyam();
  //   } else {
  //     setHelper((prev) => ({
  //       ...prev,
  //       udyam: EN.please_enter_valid_Udyam_registration_number,
  //     }));
  //     setError((prev) => ({
  //       ...prev,
  //       udyam: true,
  //     }));
  //   }
  // };

  useEffect(() => {
    emailValidation();
  }, [email]);

  // useEffect(() => {
  //   pincode.length == 6 && validatePin();
  // }, [pincode]);

  // useEffect(() => {
  //   if (udyam.length > 0) {
  //     udyamValidation();
  //   } else {
  //     setHelper((prev) => ({
  //       ...prev,
  //       udyam: udyamHelperNode,
  //     }));
  //     setError((prev) => ({
  //       ...prev,
  //       udyam: false,
  //     }));
  //   }
  // }, [udyam]);

  useEffect(() => {
    if (secondaryMobile.length > 0) {
      validateMobile();
    } else if (secondaryMobile.length == 0) {
      setHelper((prev) => ({ ...prev, secondaryMobile: "" }));
      setError((prev) => ({ ...prev, secondaryMobile: false }));
    }
  }, [secondaryMobile]);

  // useEffect(() => {
  //   !userData?.pan && panNo.length == 10 && validatePan();
  // }, [panNo]);

  // const verifyPan = async () => {
  //   try {
  //     setLoading(true);
  //     let PAYLOAD = {
  //       DATA: {
  //         lenderCode: AppConstants.lenderCode,
  //         pan: panNo,
  //       },
  //       HEADER: {
  //         AID: userData?.mobile,
  //         MID: userData?.mobile,
  //         userId: userData?.userId,
  //       },
  //     };
  //     let res = await APICall({
  //       url: Validate_Pan_Create_Lead,
  //       payload: PAYLOAD,
  //     });
  //     if (res.RESP_CODE === 300) {
  //       getUserData();
  //       setIsVerified((prev) => ({
  //         ...prev,
  //         pan: true,
  //       }));
  //       setError((prev) => ({
  //         ...prev,
  //         pan: false,
  //       }));
  //       setHelper((prev) => ({
  //         ...prev,
  //         pan: "PAN name - " + res.DATA?.panName,
  //       }));
  //     } else {
  //       setError((prev) => ({
  //         ...prev,
  //         pan: true,
  //       }));
  //       setHelper((prev) => ({ ...prev, pan: res?.RESP_MSG }));
  //       showToast(res?.RESP_MSG, "error", 3000);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  // const verifyUdyam = async () => {
  //   try {
  //     setLoading(true);
  //     let PAYLOAD = {
  //       DATA: {
  //         lenderCode: AppConstants.lenderCode,
  //         udyam: udyam,
  //       },
  //       HEADER: {
  //         AID: userData?.mobile,
  //         MID: userData?.mobile,
  //         userId: userData?.userId,
  //       },
  //     };

  //     let res = await APICall({
  //       url: Validate_Udyam_Detail,
  //       payload: PAYLOAD,
  //     });

  //     if (res.RESP_CODE === 300) {
  //       getUserData();
  //       setIsVerified((prev) => ({
  //         ...prev,
  //         udyam: true,
  //       }));
  //       setError((prev) => ({
  //         ...prev,
  //         udyam: false,
  //       }));
  //       setHelper((prev) => ({
  //         ...prev,
  //         udyam: res?.DATA.businessName,
  //       }));
  //     } else if (res.RESP_CODE === 301) {
  //       setIsVerified((prev) => ({
  //         ...prev,
  //         udyam: true,
  //       }));
  //       setError((prev) => ({
  //         ...prev,
  //         udyam: false,
  //       }));

  //       setHelper((prev) => ({ ...prev, udyam: res?.RESP_MSG }));
  //     } else if (res.RESP_CODE === 302) {
  //       setHelper((prev) => ({ ...prev, udyam: res?.RESP_MSG }));
  //       setIsVerified((prev) => ({
  //         ...prev,
  //         udyam: true,
  //       }));
  //       setError((prev) => ({
  //         ...prev,
  //         udyam: false,
  //       }));
  //       getUserData();
  //     } else {
  //       setIsVerified((prev) => ({
  //         ...prev,
  //         udyam: false,
  //       }));
  //       setError((prev) => ({
  //         ...prev,
  //         udyam: true,
  //       }));
  //       setHelper((prev) => ({ ...prev, udyam: res?.RESP_MSG }));
  //       showToast(res?.RESP_MSG, "error", 3000);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  // const validatePin = async () => {
  //   try {
  //     setLoading(true);
  //     let PAYLOAD = {
  //       DATA: {
  //         lenderCode: AppConstants.lenderCode,
  //         pincode: pincode,
  //       },
  //       HEADER: {
  //         AID: userData?.mobile,
  //         MID: userData?.mobile,
  //         userId: userData?.userId,
  //       },
  //     };

  //     let res = await APICall({
  //       url: Check_Serviceable_Pincode,
  //       payload: PAYLOAD,
  //     });
  //     if (res?.RESP_CODE === 300) {
  //       setValidatePincodeFlag(true);
  //       setHelper((prev) => ({ ...prev, pincode: res?.RESP_MSG }));
  //       setError((prev) => ({
  //         ...prev,
  //         pincode: false,
  //       }));
  //     } else {
  //       setValidatePincodeFlag(false);
  //       setHelper((prev) => ({ ...prev, pincode: res?.RESP_MSG }));
  //       setError((prev) => ({
  //         ...prev,
  //         pincode: true,
  //       }));
  //       showToast(res?.RESP_MSG, "error", 3000);
  //     }
  //     setLoading(false);
  //   } catch (e) {
  //     setLoading(false);
  //   }
  // };

  const verifyHandler = () => {
    setIsVerified({
      pan: true,
      // udyam: true,
      email: true,
      // pincode: true,
      monthlyIncome: true,
      middleName: true,
      secondaryMobile: true,
    });
    setError({
      pan: false,
      // udyam: false,
      email: false,
      // pincode: false,
      monthlyIncome: false,
      middleName: false,
    });
    setIsConfirm(true);
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          pan: userData?.pan,
          // udyam: userData?.udyamNumber,
          email: email,
          // pincode: pincode,
          secondaryMobile: secondaryMobile,
          monthlyIncome: Number(monthlyIncome),
          fatherOrSpouseFullName: middleName,
        },
        HEADER: {
          AID: userData.mobile,
          MID: userData.mobile,
          userId: userData.userId,
        },
      };

      let res = await APICall({
        url: Confirm_Lead_Detail,
        payload: PAYLOAD,
      });
      if (res?.RESP_CODE === 300) {
        setLoading(false);
        callDedupe(true);
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="detail-body">
        <Title
          title={EN.enter_your_details}
          subTitle={
            EN.we_will_use_this_information_to_perform_checks_n_validation_to_process_your_loan
          }
        />
        <div className="input-consent-container">
          <div className="input-container">
            <InputField
              label={EN.pan_number}
              value={panNo}
              maxLength={10}
              disabled={isVerified.pan}
              tick={isVerified.pan}
              helperText={helper.pan}
              error={error.pan}
              onChange={(e) => setPanNo(e.target.value.toUpperCase())}
            />
            {/* <InputField
              type="text"
              label={EN.pincode}
              maxLength={6}
              // onKeyPress={inputHandler}
              value={pincode}
              helperText={
                pincode ? helper.pincode : EN.as_per_your_aadhaar_card
              }
              error={error.pincode}
              onChange={(e) => {
                const inputValue = e.target.value;
                const regex = /^\d{0,6}$/;
                if (regex.test(inputValue)) {
                  setPincode(inputValue);
                }
              }}
              disabled={isVerified.pincode}
            /> */}
            {/* <InputField
              label={EN.udyam_Registration_Number}
              value={udyam}
              maxLength={19}
              helperText={helper.udyam}
              disabled={isVerified.udyam}
              // disabled={isConfirm}
              error={error.udyam}
              tick={isConfirm}
              // tick={isVerified.udyam}
              onChange={(e) =>
                isVerified.pan && setUdyam(e.target.value.toUpperCase())
              }
              onHelperTextClick={() => setFaqVisible(true)}
            /> */}
            <InputField
              label={EN.secondary_mobile_number}
              helperText={helper.secondaryMobile}
              maxLength={10}
              value={secondaryMobile}
              error={error.secondaryMobile}
              onChange={(e) => {
                const inputValue = e.target.value;
                const regex = /^\d{0,10}$/;
                if (regex.test(inputValue)) {
                  setSecondaryMobile(inputValue);
                }
              }}
              disabled={isVerified.secondaryMobile}
            />
            <InputField
              label={EN.email_address}
              helperText={helper.email}
              value={email}
              error={error.email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isVerified.email}
            />

            <InputField
              label={EN.monthly_income}
              value={monthlyIncome}
              onChange={(e) => {
                const newValue = e.target.value;
                if (/^\d+$/.test(newValue) || newValue === "") {
                  setMonthlyIncome(newValue);
                }
              }}
              helperText={
                monthlyIncome <= 25000 && monthlyIncome.length > 0
                  ? EN.monthly_income_helper
                  : ""
              }
              error={monthlyIncome < 25000 && monthlyIncome.length}
              disabled={isVerified.monthlyIncome}
            />
            <InputField
              label={EN.father_or_spouse_full_name}
              value={middleName}
              onChange={(e) => {
                const newValue = e.target.value;
                if (/^[a-zA-Z\s]+$/.test(newValue) || newValue === "") {
                  setMiddleName(newValue);
                }
              }}
              disabled={isVerified.middleName}
            />
          </div>

          <div className="footer-container">
            <Button
              label={EN.submit_n_next}
              ref={btnRef}
              activeBtn={
                panNo &&
                // udyam &&
                middleName &&
                monthlyIncome >= 25000 &&
                email &&
                helper.email == EN.should_be_an_active_and_accessible_account &&
                // pincode.length == 6 &&
                // validatePincodeFlag &&
                // isVerified.udyam &&
                secondaryMobile.length == 10 &&
                !error.secondaryMobile
              }
              onClick={() => {
                verifyHandler();
                submitHandler();
              }}
            />
          </div>
        </div>
      </div>

      {openModal && (
        <PermissionModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          setLoading={setLoading}
          // setisLocationPermission={setisLocationPermission}
          // setIsCameraPermission={setIsCameraPermission}
          // setOpenEnquiryModal={setOpenEnquiryModal}
        />
      )}
      <Faq isOpen={faqVisible} setIsOpen={setFaqVisible} />
      <LoadingModal isOpen={loading} setIsopen={setLoading} />
    </Layout>
  );
}
