import React from "react";
import "./CardView.scss";

const CardView = ({ label = "", style = {}, children }) => {
  return (
    <div className="gray-card-container" style={style}>
      <p className="label">{label}</p>
      {children}
    </div>
  );
};

export default CardView;
