import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import { APICall } from "../../api/APICall";
import { AppConstants } from "../../constants/AppConstants";
import { Get_Profile_Details } from "../../api/APIs";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import useToast from "../../hooks/useToast";
import "./Profile.scss";
import profileImg from "../../../src/assets/ProfileImg.jpg";
import { EN } from "../../constants/Strings/EN";

function Profile() {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [profileDetails, setProfileDetails] = useState();

  const getUserDetails = async () => {
    setLoading(true);
    const payload = {
      DATA: {
        lenderCode: AppConstants.lenderCode,
      },
      HEADER: {
        AID: userData.mobile,
        MID: userData.mobile,
        userId: userData.userId,
      },
    };

    await APICall({ url: Get_Profile_Details, payload: payload })
      .then((backendRes) => {
        if (backendRes.RESP_CODE === 300) {
          setProfileDetails(backendRes.DATA);
          setLoading(false);
        } else {
          setLoading(false);
          showToast(backendRes.RESP_MSG, "error", 3000);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <Layout>
      {profileDetails ? (
        <div className="profile-container">
          <Title title="My Profile" />
          <div className="pic-button-container">
            <div className="pic-container">
              <img className="pic" src={profileImg} alt="" />
            </div>
            {/* <button className="change-btn">{EN.change}</button> */}
          </div>
          <table>
            <tbody>
              <tr>
                <td>{EN.name}</td>
                <td>{profileDetails?.name}</td>
              </tr>
              <tr>
                <td>{EN.phone}</td>
                <td>{profileDetails?.mobile}</td>
              </tr>
              <tr>
                <td>{EN.email}</td>
                <td>{profileDetails?.email}</td>
              </tr>
              <tr>
                <td>{EN.pan}</td>
                <td>{profileDetails?.pan}</td>
              </tr>
              <tr>
                <td>{EN.address}</td>
                <td>{profileDetails?.currentAddress}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
      <LoadingModal isOpen={loading} setIsopen={setLoading} />
    </Layout>
  );
}

export default Profile;
