export const setUser = (user) => ({
  type: "SET_USER",
  payload: user,
});

export const setStep = (step) => ({
  type: "SET_STEP",
  payload: step,
});

export const setBackButton = (backButton) => ({
  type: "SET_BACK_BUTTON",
  payload: backButton,
});

export const setOpenAddressModelOnBack = (openAddressModelOnBack) => ({
  type: "OPEN_ADDRESS_MODEL",
  payload: openAddressModelOnBack,
});

export const setIsOfferConfirm = (isConfirm) => ({
  type: "SET_IS_OFFER_CONFIRM",
  payload: isConfirm,
});

export const setEnachAuthBankUrl = (url) => ({
  type: "SET_ENACH_AUTH_BANK_URL",
  payload: url,
});

export const setEsignUrl = (url) => ({
  type: "SET_ESIGN_URL",
  payload: url,
});
