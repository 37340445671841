import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import { IoMdClose } from "react-icons/io";

const useToast = () => {
  const showToast = (text, type, time) => {
    switch (type) {
      case "success":
        toast.success(text, { autoClose: time });
        break;
      case "error":
        toast.error(text, { autoClose: time });
        break;
      case "warning":
        toast.warning(text, { autoClose: time });
        break;
      default:
        toast.info(text, { autoClose: time });
        break;
    }
  };

  return {
    ToastContainerComponent: () => (
      <ToastContainer
        style={{
          padding: "16px",
          alignItems: "center",
        }}
        toastStyle={{ color: "#000", fontSize: "14px", fontWeight: "600", fontFamily: "unset" }}
        closeButton={
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              //   margin: "0px 1rem",
            }}
          >
            <IoMdClose size={24} />
          </div>
        }
      />
    ),
    showToast,
  };
};

export default useToast;
