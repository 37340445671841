import { Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Button from "../Button/Button";
import "./PermissionModal.scss";
import { EN } from "../../constants/Strings/EN";
import PDFViewLayout from "../PDFViewLayout/PDFViewLayout";
import InstructionIcon from "../../assets/icons/instruction-icon.svg";
import locationIcon from "../../assets/icons/locationIcon.svg";
import cameraIcon from "../../assets/icons/cameraIcon.svg";
import kycIcon from "../../assets/icons/kycIcon.svg";
import tncIcon from "../../assets/icons/tncIcon.svg";
import Footer from "../Footer/Footer";
import TncModal from "./TncModal";
import { AppConstants } from "../../constants/AppConstants";
import useToast from "../../hooks/useToast";
import { APICall } from "../../api/APICall";
import {
  Create_User_Enquiry_And_lead,
  Save_User_Consent,
} from "../../api/APIs";
import axios from "axios";
import useDedupe from "../../hooks/useDedupe";

function PermissionModal({
  openModal = false,
  setOpenModal,
  setLoading,
  setIsCameraPermission,
  setisLocationPermission,
  // setOpenEnquiryModal,
}) {
  let userData = JSON.parse(localStorage.getItem("userData"));
  const btnRef = useRef();
  const { callDedupe } = useDedupe();
  const constraint = { video: true, audio: false };
  const [locnPerm, setLocPerm] = useState(false);
  const [camPerm, setCamPerm] = useState(false);
  const [camCheck, setCamCheck] = useState(false);
  const [locCheck, setLocCheck] = useState(false);
  const [activeBtn, setActiveBtn] = useState(false);
  const [showInfo, setShowInfo] = useState({
    camera: false,
    location: false,
    tnc: false,
    kyc: false,
  });
  const { showToast } = useToast();
  const [networkData, setNetworkData] = useState({});

  const data = [
    {
      key: "camera",
      label: "Camera Access",
      icon: cameraIcon,
      info: "The camera access will be used to verify your Selfie.",
    },
    {
      key: "location",
      label: "Location Access",
      icon: locationIcon,
      info: "The location is taken to record your coordinates while you are performing this journey from a complience point of view.",
    },
    {
      key: "tnc",
      label: "Terms and Conditions",
      icon: tncIcon,
      info: "",
    },
    {
      key: "kyc",
      label: "KYC Verification",
      icon: kycIcon,
      info: "I concent to PAN verification against submitted KYC information for credit and risk evaluation.",
    },
  ];

  useEffect(() => {
    locationPermission();
  }, []);

  const locationPermission = async () => {
    if (navigator.geolocation) {
      const res = await navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
            setLocPerm(true);
            // setisLocationPermission(true);
            getLocation();
            cameraPermission();
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition((position) => {
              setLocPerm(true);
              cameraPermission();
            });
          } else if (result.state === "denied") {
            setLocPerm(false);
            alert("Please allow permission from setting");
          }
        });
    }
  };

  const getLocation = async () => {
    try {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          async function (position) {
            setNetworkData({
              lat: position?.coords?.latitude,
              long: position?.coords?.longitude,
            });
            const res = await axios.get("https://api.ipify.org/?format=json");
            if (res?.data.ip)
              setNetworkData((prev) => ({ ...prev, ip: res?.data?.ip }));
            else console.log("response else", res.data);
          },
          function (e) {
            showToast(`Location Err ${e.message}`, "error", 3000);
          },
          {
            timeout: 5000,
            enableHighAccuracy: true,
          }
        );
      } else {
      }
    } catch (error) {}
  };

  const cameraPermission = () => {
    navigator.mediaDevices
      .getUserMedia(constraint)
      .then((mediaStream) => {
        setCamPerm(true);
        // setIsCameraPermission(true);
      })
      .catch((e) => {
        setCamPerm(false);
      });
  };

  // const { locationPermission, cameraPermission, camPerm } = useAskpermission();

  // useEffect(() => {
  //   if (locnPerm && camPerm) {
  //     setOpenModal(false);
  //     // setOpenEnquiryModal(true);
  //   }
  // }, [locnPerm, camPerm]);

  const createUserEntry = async () => {
    try {
      setLoading(true);
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants?.lenderCode,
          applicationName: userData?.applicationName,
          pan: userData?.pan,
          supplierId: userData?.supplierId,
          userLoanProcessingFeePercentage:
            userData?.userLoanProcessingFeePercentage,
          secondaryMobile: userData?.secondaryMobile,
          ifscCode: userData?.bankIfsc || "",
          accountNumber: userData?.bankAccountNumber || "",
          bankName: userData?.bankName || "",
          email: userData?.email || "",
          latitude: networkData.lat,
          longitude: networkData.long,
          ipAddress: networkData.ip,
        },

        HEADER: {
          AID: userData?.mobile,
          MID: userData?.mobile,
        },
      };

      let res = await APICall({
        url: Create_User_Enquiry_And_lead,
        payload: PAYLOAD,
      });
      if (res?.RESP_CODE === 300) {
        setOpenModal(false);
        callDedupe();
        // setLoading(false);
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onAcceptAll = async () => {
    try {
      setLoading(true);
      let PAYLOAD = {
        DATA: [
          {
            lenderCode: AppConstants.lenderCode,
            consentText: "Camera Access",
            consentType: "ConsentType.Camera",
          },
          {
            lenderCode: AppConstants.lenderCode,
            consentText: "Location Access",
            consentType: "ConsentType.Location",
          },
          {
            lenderCode: AppConstants.lenderCode,
            consentText: "Terms and Conditions",
            consentType: "ConsentType.Terms & Condition/CIBIL",
          },
          {
            lenderCode: AppConstants.lenderCode,
            consentText: "KYC Verification",
            consentType: "ConsentType.PAN",
          },
        ],

        HEADER: {
          AID: userData?.mobile,
          MID: userData?.mobile,
          userId: userData?.userId,
        },
      };

      let res = await APICall({
        url: Save_User_Consent,
        payload: PAYLOAD,
      });
      if (res?.RESP_CODE === 300) {
        await createUserEntry();
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleInfo = (key) => {
    setShowInfo((prevShowInfo) => ({
      ...prevShowInfo,
      [key]: !prevShowInfo[key],
    }));
  };

  return (
    <>
      <Modal
        open={openModal}
        //   onClose={handleClose}
        // className="modal-container"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            backgroundColor: "#fff",
            outline: "none",
            borderRadius: "1rem",
          }}
        >
          <PDFViewLayout
            label={EN.we_need_your_permission}
            viewOnly
            contentStyle={{ height: "auto" }}
            content={
              <div className="modal">
                <div className="modal-content">
                  <div className="modal-labels">
                    <span className="modal-subtitle">
                      {EN.permission_descn1}
                    </span>
                  </div>

                  <div className="modal-checkbox-container">
                    {data.map((i) => {
                      return (
                        <div
                          key={i.key}
                          style={{
                            display: "flex",
                            gap: "0.5rem",
                            alignItems: "flex-start",
                          }}
                          onClick={() => handleToggleInfo(i.key)}
                        >
                          <img
                            src={i.icon}
                            // style={{ height: 20, width: 20 }}
                          />
                          <div>
                            <div className="item-row">
                              <label>{i.label}</label>
                              <img src={InstructionIcon} />
                            </div>
                            {showInfo[i.key] && (
                              <div className="info-container">{i.info}</div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <Button
                  style={{ width: "80%", marginBottom: "1rem" }}
                  ref={btnRef}
                  label={"Accept All"}
                  onClick={onAcceptAll}
                  activeBtn
                  // className={activeBtn ? "active-form-btn" : "disable-form-btn"}
                />
              </div>
            }
          />
        </div>
      </Modal>
      <TncModal
        open={showInfo.tnc}
        onClose={() => {
          setShowInfo((prevShowInfo) => ({
            ...prevShowInfo,
            tnc: !prevShowInfo.tnc,
          }));
        }}
      />
    </>
  );

  return (
    <Modal
      open={openModal}
      //   onClose={handleClose}
      className="modal-container"
    >
      <div className="modal">
        <div className="modal-content">
          <div className="modal-labels">
            <span className="modal-title">{EN.provide_access}</span>
            <span className="modal-subtitle">{EN.permission_descn1}</span>
            <span className="modal-subtitle">{EN.permission_descn2}</span>
          </div>

          <div className="modal-checkbox-container">
            <div>
              <input type="checkbox" onChange={() => setLocCheck(!locCheck)} />
              <label>Location Access</label>
            </div>

            <div>
              <input type="checkbox" onChange={() => setCamCheck(!camCheck)} />
              <label>Camera Access</label>
            </div>
          </div>
        </div>

        <Button
          ref={btnRef}
          label={"Allow Access"}
          onClick={locationPermission}
          className={activeBtn ? "active-form-btn" : "disable-form-btn"}
        />
      </div>
    </Modal>
  );
}

export default PermissionModal;
