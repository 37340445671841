import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import InputField from "../../components/InputField/InputField";
import Button from "../../components/Button/Button";
import { EN } from "../../constants/Strings/EN";
import Title from "../../components/Title/Title";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import "./BankDetails.scss";
import Dropdown from "../../components/Dropdown/Dowpdown";
import { APICall } from "../../api/APICall";
import {
  Core_Control_Details,
  Get_Bank_Branch_Dtl,
  Save_Bank_Details,
} from "../../api/APIs";
import useToast from "../../hooks/useToast";
import { AppConstants } from "../../constants/AppConstants";
import { DropdownListFormat } from "../../util/formatter";
import useDedupe from "../../hooks/useDedupe";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBackButton } from "../../redux/actions";

const BankDetails = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [acctNo, setAcctNo] = useState("");
  const [confirmAcctNo, setConfirmAcctNo] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountType, setAccountType] = useState({});
  const [accountTypeList, setAccountTypeList] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [ifsc, setIfsc] = useState("");
  const [disabled, setDisabled] = useState(false);
  const { showToast } = useToast();
  const { callDedupe } = useDedupe();
  const navigate = useNavigate();

  useEffect(() => {
    userData?.currentStage == "BANK_DETAIL" && setDisabled(true);
    getDefaultValues();
  }, []);

  useEffect(() => {
    getAcctTypes();
    dispatch(
      setBackButton({
        backButton: false,
      })
    );
  }, []);

  useEffect(() => {
    ifsc?.length === 11 && getIfscDetails();
  }, [ifsc]);

  const getDefaultValues = () => {
    setIfsc(userData?.bankIfsc);
    setAcctNo(userData?.bankAccountNumber);
    setConfirmAcctNo(userData?.bankAccountNumber);
    // setSelectedBank(userData?.bankName);
    setAccountType({
      label: userData?.bankAccountTypeName,
      value: userData?.bankAccountTypeCode,
    });
    // setDisabled(true);
  };

  const getAcctTypes = async () => {
    try {
      setLoading(true);
      let PAYLOAD = {
        DATA: {
          MasterDataType: "AccountType",
        },
        HEADER: {
          AID: userData.mobile,
        },
      };

      let res = await APICall({ url: Core_Control_Details, payload: PAYLOAD });

      if (res.RESP_CODE == 300) {
        setAccountTypeList(DropdownListFormat({ list: res.DATA }));
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getIfscDetails = async () => {
    try {
      setLoading(true);
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          ifsc: ifsc,
        },
        HEADER: {
          userId: userData.userId,
          AID: userData.mobile,
          MID: userData.mobile,
        },
      };

      let res = await APICall({ url: Get_Bank_Branch_Dtl, payload: PAYLOAD });

      if (res.RESP_CODE == 300) {
        setSelectedBank(res.DATA.bankName);
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          accountNumber: acctNo,
          // accountHolderName: accountHolderName,
          ifscCode: ifsc,
          accountType: accountType.value,
          bankName: selectedBank,
        },
        HEADER: {
          userId: userData.userId,
          AID: userData.mobile,
          MID: userData.mobile,
        },
      };

      let res = await APICall({ url: Save_Bank_Details, payload: PAYLOAD });

      if (res.RESP_CODE == 300 || res.RESP_CODE == 301) {
        callDedupe(true);
        setLoading(false);
      }
      // else if (res.RESP_CODE == 301) {
      //   setLoading(false);
      //   navigate("/LoanApplicationPending");
      // }
      else {
        showToast(res?.RESP_MSG, "error", 3000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="bank-detail-body">
        <Title
          title={EN.enter_your_bank_details}
          subTitle={
            EN.we_need_to_verify_this_account_for_your_loan_disbursement_and_EMIs
          }
        />
        <div className="input-consent-container">
          <div className="input-container">
            <InputField
              label={EN.bank_ifsc}
              value={ifsc}
              maxLength={11}
              onChange={(e) => setIfsc(e.target.value.toUpperCase())}
              disabled={disabled}
            />

            <InputField label={EN.bank_name} value={selectedBank} disabled />

            <InputField
              label={EN.bank_A_C_no}
              value={acctNo}
              maxLength={25}
              type="password"
              onChange={(e) => setAcctNo(e.target.value)}
              disabled={disabled}
            />
            <InputField
              label={EN.confirm_bank_A_C_no}
              value={confirmAcctNo}
              maxLength={25}
              onChange={(e) => setConfirmAcctNo(e.target.value)}
              disabled={disabled}
              error={acctNo !== confirmAcctNo && confirmAcctNo?.length > 0}
              helperText={
                acctNo !== confirmAcctNo &&
                confirmAcctNo?.length > 0 &&
                "Please reconfirm your account number"
              }
            />
            {disabled ? (
              <InputField
                label={EN.account_type}
                value={accountType.label}
                disabled={disabled}
              />
            ) : (
              <Dropdown
                label={EN.account_type}
                name="accountType"
                value={accountType}
                options={accountTypeList}
                onSelect={(e) => setAccountType(e)}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            )}
            {/* <InputField
              label={EN.account_holder_name}
              value={accountHolderName}
              onChange={(e) => setAccountHolderName(e.target.value)}
            /> */}
          </div>
          <Button
            label={EN.submit}
            activeBtn={
              (ifsc?.length == 11 &&
                acctNo?.length > 7 &&
                // accountHolderName &&
                acctNo === confirmAcctNo &&
                accountType.value &&
                selectedBank) ||
              disabled
            }
            onClick={submitHandler}
          />
        </div>
      </div>
      <LoadingModal isOpen={loading} setIsopen={setLoading} />
    </Layout>
  );
};

export default BankDetails;
