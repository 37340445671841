import React, { Fragment } from "react";
import "./Dashboard.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Title from "../../components/Title/Title";
import Layout from "../../components/Layout/Layout";
import mylaonsImage from "../../assets/SigningStep.svg";
import myProfileImage from "../../assets/SigningStep.svg";
import DashProfile from "../../assets/DashProfile.png";
import Polygon from "../../assets/Polygon.png";
import { Link } from "react-router-dom";
import { EN } from "../../constants/Strings/EN";
import { DashboardProfileIcon } from "../../constants/SVG";

const Dashboard = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  return (
    <Layout>
      <div className="dashboard-container">
        <Title
          title={EN.dashboard}
          subTitle={`Hi ${userData?.applicationName},`}
        />

        <div className="dashboard">
          <Link
            to="/profile"
            className="dashboard-profile"
            // style={{
            //   background: "#fcc4ac",
            //   backgroundImage: `url(${Polygon})`,
            //   backgroundPosition: "center",
            //   backgroundSize: "cover",
            //   backgroundRepeat: "no-repeat",
            //   backgroundPositionY: -50,
            //   backgroundPositionX: -40,
            //   backgroundHeight: 100,
            //   backgroundWidth: 100,
            // }}
          >
            <img src={Polygon} className="polygon" />
            <p>{EN.my_profile}</p>
            {/* <img src={Polygon} style={{ position: "inherit" }} /> */}
            <img className="my-profile-image" src={DashProfile} />
          </Link>
          <Link to="/my-loans" className="dashboard-loan">
            <img src={Polygon} className="polygon" />
            <p>{EN.my_loans}</p>
            <img className="my-profile-image" src={myProfileImage} />
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
