import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Aadhaar from "../screens/Aadhaar/Aadhaar";
import Details from "../screens/Details/Details";
import Home from "../screens/Home/Home";
import LoanLending from "../screens/LoanLending/LoanLending";
import Regret from "../screens/Regret/Regret";
import Selfie from "../screens/Selfie/Selfie";
import UploadDocument from "../screens/UploadDocument/UploadDocument";
import LoadingModal from "../components/LoadingModal/LoadingModal";
import Offer from "../screens/Offer/Offer";
import LoanOfferPending from "../screens/LoanOfferPending/LoanOfferPending";
import KycDocument from "../screens/KycDocument/KycDocument";
import UploadUdyamCertificate from "../screens/UploadUdyamCertificate/UploadUdyamCertificate";
import ViewFiles from "../screens/ViewFiles/ViewFiles";
import ENachDetails from "../screens/Enach/ENachDetails";
import BankDetails from "../screens/BankDetails/BankDetails";
import LoanApplicationPending from "../screens/LoanApplicationPending/LoanApplicationPending";
import AdditionalDocument from "../screens/AdditionalDocuments/AdditionalDocument";

import Dashboard from "../screens/Dashboard/Dashboard";
import Profile from "../screens/Profile/Profile";
import MyLoans from "../screens/MyLoans/MyLoans";
import LoanDisbursementPending from "../screens/LoanDisbursementPending/LoanDisbursementPending";
import EnachPending from "../screens/Enach/EnachPending";
import ESignPending from "../screens/ViewFiles/ESignPending";
import CibilRegret from "../screens/Regret/CibilRegret";

function Routing() {
  return (
    // <div>Routing</div>
    <Suspense>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Layout" element={<Layout />} />
        <Route path="/LoanLending" element={<LoanLending />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/my-loans" element={<MyLoans />} />
        <Route path="/Details" element={<Details />} />
        <Route path="/Regret" element={<Regret />} />
        <Route path="/CibilRegret" element={<CibilRegret />} />
        <Route path="/Selfie" element={<Selfie />} />
        <Route path="/Aadhaar" element={<Aadhaar />} />
        <Route path="/UploadDocument" element={<UploadDocument />} />
        <Route path="/KycDocument" element={<KycDocument />} />
        <Route path="/Offer" element={<Offer />} />
        <Route path="/LoanOfferPending" element={<LoanOfferPending />} />
        <Route
          path="/LoanDisbursementPending"
          element={<LoanDisbursementPending />}
        />
        <Route
          path="/LoanApplicationPending"
          element={<LoanApplicationPending />}
        />
        <Route path="/UploadUdyam" element={<UploadUdyamCertificate />} />
        <Route path="/ENachDetails" element={<ENachDetails />} />
        <Route path="/ViewFiles" element={<ViewFiles />} />
        <Route path="/BankDetails" element={<BankDetails />} />
        <Route path="/AdditionalDocument" element={<AdditionalDocument />} />
        <Route path="/EnachPending" element={<EnachPending />} />
        <Route path="/ESignPending" element={<ESignPending />} />
      </Routes>
    </Suspense>
  );
}

export default Routing;
