import { Modal } from "@mui/material";
import React from "react";
import PDFViewLayout from "../PDFViewLayout/PDFViewLayout";
import { EN } from "../../constants/Strings/EN";

const TncModal = ({ open, onClose }) => {
  return (
    <Modal open={open}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          backgroundColor: "#fff",
          outline: "none",
          borderRadius: "1rem",
        }}
      >
        <PDFViewLayout
          label={"Terms & Conditions"}
          viewOnly
          isModal
          onClose={onClose}
          content={
            <div style={{ padding: "1rem" }}>
              <p>
                I hereby declare that I am an Indian National. I agree to
                Arthmate Financing India Private Limited (AFIPL) privacy policy,
                grievance redressal policy and authorize AFIPL to carry out
                credit enquiries on the Credit Bureaus. I give my consent to one
                or more lending partners of AFIPL to make one or multiple credit
                enquiries with the credit bureaus or any other agencies
                authorized by RBI. I also authorize AFIPL and one or more
                lending partners of AFIPL to retrieve credit reports on my
                behalf from CRIF Highmark Private Limited or Experian India
                Private Limited or Transunion CIBIL Limited.
              </p>
              <p>
                By clicking on verify, you consent to receiving communications
                including but not limited to SMS, e-mails, phone calls, whatsapp
                from us and/or our partners, including Banks and NBFCs, with
                respect to your transaction on the website or for any other
                purpose. This consent will override any registration for
                DNC/NDNC. You hereby consent to AFIPL being appointed as your
                authorized representative to receive your Credit Information
                from CIBIL and sharing it with its affiliate financial
                partners(Specified Users under CICRA, 2005) for the purpose of
                providing curated products and sharing the Credit Information
                with such affiliate financial partners. ("End User Purpose") I
                consent to a PAN and Aadhaar Verification against submitted KYC
                information for credit and risk evaluation.
              </p>
              <p>
                Privacy Policy Link - https://www.arthmate.com/helpTopicPlease
                read through the “privacy policy” section. The same policy
                governs the usage/interaction with our web appGrievance
                RedressalPolicy Link - https://www.arthmate.com/helpTopicPlease
                read through the “grievance redressal policy” section. The same
                policy governs the usage/interaction with our web app
              </p>
            </div>
          }
        />
      </div>
    </Modal>
  );
};

export default TncModal;
