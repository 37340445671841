import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { APICall } from "../../api/APICall";
import { Confirm_Enach_Details, Get_Enach_Details } from "../../api/APIs";
import TimerIcon from "../../assets/icons/timerIcon.svg";
import verificationGif from "../../assets/gifs/verificationGif.gif";
import Button from "../../components/Button/Button";
import InputField from "../../components/InputField/InputField";
import Label from "../../components/Label/Label";
import Layout from "../../components/Layout/Layout";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import Title from "../../components/Title/Title";
import { AppConstants } from "../../constants/AppConstants";
import { EN } from "../../constants/Strings/EN";
import useDedupe from "../../hooks/useDedupe";
import useToast from "../../hooks/useToast";
import { setBackButton, setEnachAuthBankUrl } from "../../redux/actions";
import "./ENachDetails.scss";
import "./EnachPending.scss";

const ENachDetails = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openEnachPending, setOpenEnachPending] = useState(false);
  const [enachData, setEnachData] = useState({});
  const [networkData, setNetworkData] = useState({});
  const { showToast } = useToast();
  const { callDedupe } = useDedupe();
  const newWindowRef = useRef(null);

  useEffect(() => {
    locationPermission();
    getEnachDetails();
    getLocation();
    dispatch(
      setBackButton({
        backButton: false,
      })
    );
  }, []);

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    if (
      userData?.currentStage !== "LOAN_CREATION_SUCCESS" &&
      userData?.currentStage !== "ENACH_FAILED"
    ) {
      openUrl();
    }
  }, []);

  const openUrl = async () => {
    setOpenEnachPending(true);

    let intet = setInterval(() => {
      let userData = JSON.parse(localStorage.getItem("userData"));
      if (
        userData?.currentStage === "ENACH_SUCCESS" ||
        userData?.currentStage === "ENACH_FAILED" ||
        userData?.currentStage === "LOAN_CREATION_SUCCESS"
      ) {
        clearInterval(intet);
        closeHandler();
        callDedupe(true);
        setLoading(false);
        setOpenEnachPending(false);
      } else {
        callDedupe();
      }
    }, 5000);
  };

  const clickHandler = (url) => {
    newWindowRef.current = window.open(url, "_blank");
    openUrl(url);
  };

  const closeHandler = () => {
    newWindowRef?.current?.close();
  };

  const locationPermission = async () => {
    if (navigator.geolocation) {
      const res = await navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition((position) => {});
          } else if (result.state === "denied") {
            alert("Please allow permission from setting");
          }
        });
    }
  };

  const getLocation = async () => {
    try {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            setNetworkData({
              lat: position?.coords?.latitude,
              long: position?.coords?.longitude,
            });
          },
          function (e) {
            showToast(`Location Err ${e.message}`, "error", 3000);
          },
          {
            timeout: 5000,
            enableHighAccuracy: true,
          }
        );
      } else {
      }

      const res = await axios.get("https://api.ipify.org/?format=json");
      if (res?.data.ip)
        setNetworkData((prev) => ({ ...prev, ip: res?.data?.ip }));
    } catch (error) {}
  };

  const getEnachDetails = async () => {
    try {
      setLoading(true);
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
        },
        HEADER: {
          AID: userData?.mobile,
          MID: userData?.mobile,
          userId: userData?.userId,
        },
      };

      let res = await APICall({ url: Get_Enach_Details, payload: PAYLOAD });

      if (res.RESP_CODE == 300) {
        setEnachData(res.DATA);
        setLoading(false);
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          latitude: networkData?.lat || "",
          longitude: networkData?.long || "",
          ipAddress: networkData?.ip || "",
        },
        HEADER: {
          AID: userData.mobile,
          MID: userData.mobile,
          userId: userData.userId,
        },
      };

      let res = await APICall({ url: Confirm_Enach_Details, payload: PAYLOAD });

      if (res.RESP_CODE == 300) {
        dispatch(
          setEnachAuthBankUrl({
            eNachUrl: res.DATA?.bankAuthLink,
          })
        );
        callDedupe();
        clickHandler(res.DATA?.bankAuthLink);

        setLoading(false);
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Layout>
      {openEnachPending ? (
        <div className="enach-pending-container">
          <div className="process-img-container">
            <img src={verificationGif} />
          </div>
          <div className="process-main-content">
            <p className="process-subtitle">
              {
                EN.your_NaCH_mandate_has_been_initiated_this_may_take_some_time_Please_reach_out_to_the_POCs_to_get_this_checked_if_it_has_taken_more_than_2_days
              }
            </p>
            <p className="process-subtitle">
              <span
                onClick={() => {
                  let userData = JSON.parse(localStorage.getItem("userData"));
                  if (userData?.currentStage === "ENACH_INITIATED")
                    clickHandler(userData?.enachLink);
                }}
              >
                Click here
              </span>
              &nbsp; to re-initiate the NACH mandate.
            </p>
          </div>
        </div>
      ) : (
        <div className="enach-detail-body">
          <Title
            title={EN.setup_NACH}
            subTitle={
              EN.we_will_deduct_your_EMIs_from_this_account_heathy_balance_has_to_be_maintained_and_it_should_be_your_own_account
            }
          />
          <div className="input-consent-container">
            <InputField label={EN.name} value={enachData.userName} disabled />

            <InputField
              label={EN.phone}
              value={`+91 ${enachData?.userPhone || "0000000000"}`}
              disabled
            />
            <InputField
              label={EN.email}
              value={enachData?.userEmail}
              disabled
            />

            <Label label={EN.mandate_details} />

            <InputField
              label={EN.bank_name}
              value={enachData?.bankName}
              disabled
            />
            <InputField
              label={EN.bank_A_C_no}
              value={enachData?.maskedAccountNumber}
              disabled
            />
            <InputField
              label={EN.account_type}
              value={enachData?.accountType}
              disabled
            />
            <InputField label={EN.bank_ifsc} value={enachData?.ifsc} disabled />

            <InputField
              label={EN.account_holder_name}
              value={enachData?.accountHolderName}
              disabled
            />

            <Button label={EN.submit} activeBtn onClick={submitHandler} />
          </div>
        </div>
      )}

      <LoadingModal isOpen={loading} setIsopen={setLoading} />
    </Layout>
  );
};

export default ENachDetails;
