import React, { useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import RegretImg from "../../assets/RegretImg.svg";
import "./CibilRegret.scss";
import BetterBankStatement from "../UploadDocument/BetterBankStatement";
import { setBackButton } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { EN } from "../../constants/Strings/EN";

function CibilRegret() {
  let userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  useEffect(() => {
    userData?.currentStage == "SANCTION_OFFER_RETRY" &&
      dispatch(
        setBackButton({
          backButton: false,
        })
      );
  }, []);

  return (
    <Layout>
      <div
        className={
          userData?.currentStage !== "SANCTION_OFFER_RETRY"
            ? "regret-body"
            : "regret-body upload-doc"
        }
      >
        <div className="regret-img-container">
          <img src={RegretImg} />
        </div>
        <div className="regret-main-content">
          <div className="regret-title-container">
            <span>Regret!</span>
            <span>Thank your for applying with us</span>
          </div>
          <span className="regret-subtitle">
            {
              EN.we_cannot_process_your_loan_request_based_on_the_submitted_bank_statement
            }
          </span>
        </div>
        {/* <span className="regret-caption">
          Please do not press the back button. Page will automatically redirect
          in few seconds.
        </span> */}

        {userData?.currentStage == "SANCTION_OFFER_RETRY" && (
          <BetterBankStatement />
        )}
      </div>
    </Layout>
  );
}

export default CibilRegret;
