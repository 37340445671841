import React, { Fragment, useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import "./Selfie.scss";
import SelfieIcon from "../../assets/SelfieIcon.jpg";
import Button from "../../components/Button/Button";
import InfoIcon from "../../assets/InfoIcon.svg";
import { APICall } from "../../api/APICall";
import { Upload_Document } from "../../api/APIs";
import { AppConstants } from "../../constants/AppConstants";
import { useNavigate } from "react-router-dom";
import useToast from "../../hooks/useToast";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import useDedupe from "../../hooks/useDedupe";
import { setBackButton } from "../../redux/actions";
import { useDispatch } from "react-redux";

function Selfie() {
  const dispatch = useDispatch();
  const { callDedupe } = useDedupe();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [selfieFile, setSelfieFile] = useState();
  const userData = JSON.parse(localStorage.getItem("userData"));
  let constraints = { video: { facingMode: "user" }, audio: false };
  const cameraRef = useRef();
  const cameraViewRef = useRef();
  const cameraOutputRef = useRef();
  const cameraSensorRef = useRef();
  let track;

  useEffect(() => {
    dispatch(
      setBackButton({
        backButton: false,
      })
    );
  }, []);

  const cameraStart = () => {
    cameraRef.current.style.visibility = "visible";
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        track = stream.getTracks()[0];
        cameraViewRef.current.srcObject = stream;
      })
      .catch((error) => {});
  };

  const capturePhoto = async () => {
    cameraSensorRef.current.width = cameraViewRef.current.videoWidth;
    cameraSensorRef.current.height = cameraViewRef.current.videoHeight;
    const context = cameraSensorRef.current.getContext("2d");
    context.drawImage(cameraViewRef.current, 0, 0);
    const selfieURL = await cameraSensorRef.current.toDataURL("image/png");
    cameraOutputRef.current.src = selfieURL;
    convertUrlToFile(selfieURL);
    cameraOutputRef.current.classList.add("taken");
    // console.log(await cameraSensorRef.current.toDataURL("image/png"));
    cameraRef.current.style.visibility = "hidden";
    track.stop();
    context.clearRect(
      0,
      0,
      cameraSensorRef.current.width,
      cameraSensorRef.current.height
    );
  };

  const convertUrlToFile = (dataURL) => {
    var parts = dataURL.split(";base64,");
    var contentType = parts[0].split(":")[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    var blob = new Blob([uInt8Array], { type: contentType });
    var file = new File([blob], "canvas_image.png", { type: blob.type });

    setSelfieFile(file);
  };

  const uploadSelfie = async () => {
    setLoading(true);
    const formData = new FormData();
    const requestObj = {
      DATA: {
        lenderCode: AppConstants.lenderCode,
        documentType: "KycDocumentType.Selfie",
      },
      HEADER: {
        AID: userData.mobile,
        MID: userData.mobile,
        userId: userData.userId,
      },
    };
    formData.append("request", JSON.stringify(requestObj));
    formData.append("file", selfieFile);

    await APICall({ url: Upload_Document, payload: formData })
      .then((backendRes) => {
        if (backendRes.RESP_CODE === 300) {
          setLoading(false);
          showToast(backendRes?.RESP_MSG, "success", 3000);
          callDedupe(true);
        } else {
          setLoading(false);
          showToast(backendRes?.RESP_MSG, "error", 3000);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <Layout>
        <div className="selfie-body">
          <Title
            title="Verify your profile"
            subTitle="Take a selfie to verify your profile. Don’t worry your selfie is safe with us."
          ></Title>

          <div className="selfie-inner-body">
            <div className="selfie-container">
              <img
                ref={cameraOutputRef}
                src={SelfieIcon}
                className="selfie-img"
              />
              {selfieFile ? (
                <button className="retake-btn" onClick={cameraStart}>
                  Retake
                </button>
              ) : (
                ""
              )}
            </div>
            <div className="selfie-note-container">
              <img src={InfoIcon} />
              <span>
                Ensure your face is within the frame and it is visible. Please
                ensure you are in a well lit place. Don't wear hat, glasses or
                any other accessories which blocks clarity of your face
              </span>
            </div>
          </div>
          {selfieFile ? (
            <Button
              label="Submit & Next"
              activeBtn={true}
              onClick={uploadSelfie}
            />
          ) : (
            <Button
              label="Selfie & Liveliness Test"
              activeBtn={true}
              onClick={cameraStart}
            />
          )}
        </div>
        <LoadingModal isOpen={loading} setIsopen={setLoading} />
      </Layout>
      <section id="camera" ref={cameraRef}>
        <video
          id="camera-view"
          ref={cameraViewRef}
          src=""
          autoPlay
          playsInline
        ></video>
        <canvas id="camera-sensor" ref={cameraSensorRef}></canvas>
        <button id="camera-trigger" onClick={capturePhoto}></button>
      </section>
    </Fragment>
  );
}

export default Selfie;
