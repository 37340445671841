import React, { useState } from "react";
import Down from "../../assets/icons/down.svg";
import Up from "../../assets/icons/up.svg";

const AccordionCard = ({
  label = "",
  labelStyle = {},
  content,
  contentContainerStyle = {},
  containerStyle = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      style={{
        border: "1px solid #D8D9DE",
        borderRadius: 12,
        ...containerStyle,
      }}
    >
      <div
        style={{
          backgroundColor: "#E5E5E8",
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          borderBottomLeftRadius: !isOpen ? 12 : 0,
          borderBottomRightRadius: !isOpen ? 12 : 0,
          height: 40,
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
          paddingInline: 14,
        }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <label style={labelStyle}>{label}</label>
        <img src={isOpen ? Up : Down} />
      </div>
      {isOpen ? <div style={contentContainerStyle}>{content}</div> : null}
    </div>
  );
};

export default AccordionCard;
