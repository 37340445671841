const initialState = {
  eNachUrl: "",
  eSignUrl: "",
};

const UrlsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ENACH_AUTH_BANK_URL":
      return {
        ...state,
        eNachUrl: action.payload.eNachUrl,
      };

    case "SET_ESIGN_URL":
      return {
        ...state,
        eSignUrl: action.payload.eSignUrl,
      };

    default:
      return state;
  }
};

export default UrlsReducer;
