import React from "react";
import { useNavigate } from "react-router-dom";

const useNavigateDedupe = () => {
  const navigate = useNavigate();

  const getPath = (result) => {
    switch (result) {
      case "NEW":
      case "BASIC_INFO":
      case "LEAD_CREATION":
        localStorage.setItem("step", JSON.stringify(1));
        navigate("/Details");
        break;

      case "LEAD_CONFIRM":
      case "KYC_AADHAAR":
        localStorage.setItem("step", JSON.stringify(2));
        navigate("/Aadhaar");
        break;

      case "CURRENT_ADDRESS_PROOF":
      case "BANK_STMT_NAME_NOT_MATCH":
      case "INVALID_BANK_STATEMENT":
      case "BANK_STMT_RETRY":
        localStorage.setItem("step", JSON.stringify(3));
        navigate("/UploadDocument");
        break;

      case "INCOME_PROOF_DOC":
        navigate("/LoanOfferPending");
        break;

      case "SANCTION_OFFER_RECEIVED":
        localStorage.setItem("step", JSON.stringify(4));
        navigate("/Offer");
        break;

      case "SANCTION_OFFER_CONFIRMED":
        localStorage.setItem("step", JSON.stringify(4));
        navigate("/Selfie");
        break;

      case "KYC_DOCUMENT":
      case "BANK_DETAIL":
        localStorage.setItem("step", JSON.stringify(4));
        navigate("/BankDetails");
        break;

      case "SANCTION_OFFER_REJECTED":
      case "SANCTION_OFFER_RETRY":
        navigate("/Regret");
        break;

      case "LOAN_CREATION_INPROGRESS":
        localStorage.setItem("step", JSON.stringify(5));
        navigate("/LoanApplicationPending");
        break;

      case "SELFIE":
      case "BUSINESS_INFO":
      case "BUSINESS_INFO_MANUAL":
      case "LEAD_CONFIRM_UDYAM_MANUAL":
      case "LEAD_CONFIRM_UDYAM_MANUAL_FAILED":
      case "LEAD_CONFIRM_UDYAM_MANUAL_SUCCESS":
      case "SANCTION_OFFER_CONFIRMED_UDYAM_REQUIRED":
      case "LEAD_CONFIRM_UDYAM_MANUAL_FAILED_VINTAGE":
      case "LEAD_CONFIRM_UDYAM_MANUAL_FAILED_PINCODE":
      case "LEAD_CONFIRM_UDYAM_MANUAL_FAILED_INVALID_DOC":
        localStorage.setItem("step", JSON.stringify(4));
        navigate("/KycDocument");
        break;

      case "SANCTION_OFFER_REJECTED_SCORE_A":
        localStorage.setItem("step", JSON.stringify(1));
        navigate("/CibilRegret");
        break;

      case "LOAN_CREATION_SUCCESS":
        localStorage.setItem("step", JSON.stringify(5));
        navigate("/ENachDetails");
        break;

      case "ENACH_SUCCESS":
        localStorage.setItem("step", JSON.stringify(5));
        localStorage.setItem("showHelper", JSON.stringify(false));
        navigate("/ViewFiles");
        break;

      case "ESIGN_SUCCESS":
        localStorage.setItem("step", JSON.stringify(5));
        navigate("/LoanDisbursementPending");
        break;

      case "LOAN_DISBURSMENT_PENDING":
        localStorage.setItem("step", JSON.stringify(5));
        navigate("/LoanDisbursementPending");
        break;

      case "LOAN_DISBURSED":
        localStorage.setItem("step", JSON.stringify(5));
        navigate("/my-loans");
        break;

      case "ENACH_INITIATED":
        localStorage.setItem("step", JSON.stringify(5));
        // navigate("/EnachPending");
        navigate("/ENachDetails");
        break;

      case "ESIGN_INITIATED":
        localStorage.setItem("step", JSON.stringify(5));
        navigate("/ESignPending");
        break;

      case "ENACH_FAILED":
        localStorage.setItem("step", JSON.stringify(5));
        navigate("/ENachDetails");
        break;

      case "ESIGN_FAILED":
        localStorage.setItem("step", JSON.stringify(5));
        localStorage.setItem("showHelper", JSON.stringify(true));
        navigate("/ViewFiles");
        break;

      default:
        break;
    }
  };

  return { getPath };
};

export default useNavigateDedupe;
