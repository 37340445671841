import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import DisbursementPendingIcon from "../../assets/disbursementPendingIcon.svg";
import "./LoanDisbursementPending.scss";
import { EN } from "../../constants/Strings/EN";
import useDedupe from "../../hooks/useDedupe";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setBackButton } from "../../redux/actions";

const LoanDisbursementPending = () => {
  const { callDedupe } = useDedupe();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    callDedupe(true);
    dispatch(
      setBackButton({
        backButton: false,
      })
    );
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      callDedupe(true);
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Layout>
      <div className="loan-disbursement-container">
        <div className="process-img-container">
          <img src={DisbursementPendingIcon} />
        </div>
        <div className="process-main-content">
          <div>
            <p>{EN.congratulations}</p>
            <p className="process-subtitle-1">
              Your loan application is completed. Your money will be disbursed
              soon.
            </p>
          </div>
          <div>
            <p className="process-subtitle-2">
              We will contact you if we require any additional documents. You
              can upload additional documents by clicking below
            </p>
            <p
              className="upload-additional-document"
              onClick={() => {
                navigate("/AdditionalDocument");
              }}
            >
              {EN.upload_additional_document}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoanDisbursementPending;
