import React from 'react'
import './NotFoundLoan.scss'
import { NotFoundIcon } from '../../constants/SVG'
import notFoundIcon from '../../assets/notFound.svg'
import { EN } from '../../../src/constants/Strings/EN'

function NotFoundLoan({ type }) {
    return (
        <div className="not-found-container">
            {/* <NotFoundIcon  viewBox="0 0 200 200" /> */}
            <img src={notFoundIcon} alt="not-found" srcset="" />
            <p className="error-title">{EN.not_found}</p>
            <p className="error-info">
                {
                    type === 'active' ?
                        EN.right_now_you_dont_have_any_active_loan_in_your_account
                        :
                        EN.right_now_you_dont_have_any_closed_loan_in_your_account
                }
            </p>
        </div>
    )
}

export default NotFoundLoan