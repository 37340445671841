import React from "react";
import BottomImg from "../../assets/icons/bottomImg.svg";
import "./Footer.scss";
const Footer = () => {
  return (
    <div className="footer">
      <img src={BottomImg} />
    </div>
  );
};

export default Footer;
