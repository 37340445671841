import { Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TimerIcon from "../../assets/icons/timerIcon.svg";
import Layout from "../../components/Layout/Layout";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { EN } from "../../constants/Strings/EN";
import useDedupe from "../../hooks/useDedupe";
import "./EnachPending.scss";
import { setBackButton } from "../../redux/actions";
import Button from "../../components/Button/Button";

const EnachPending = () => {
  const { callDedupe } = useDedupe();
  const [loading, setLoading] = useState(false);
  const [openEnachModel, setOpenEnachModel] = useState(false);
  const URL = useSelector((state) => state?.urls?.eNachUrl);
  const dispatch = useDispatch();
  const newWindowRef = useRef(null);

  useEffect(() => {
    // openUrl();
    dispatch(
      setBackButton({
        backButton: false,
      })
    );
  }, []);

  const openUrl = async () => {
    URL != "" && setOpenEnachModel(true);

    let intet = setInterval(() => {
      let userData = JSON.parse(localStorage.getItem("userData"));
      if (
        userData?.currentStage === "ENACH_SUCCESS" ||
        userData?.currentStage === "ENACH_FAILED"
      ) {
        clearInterval(intet);
        callDedupe(true);
        setLoading(false);
        setOpenEnachModel(false);
        closeHandler();
      } else {
        callDedupe();
      }
    }, 5000);
  };

  const clickHandler = () => {
    newWindowRef.current = window.open(URL, "_blank");
  };

  const closeHandler = () => {
    newWindowRef?.current?.close();
  };

  return (
    <Layout>
      <div className="enach-pending-container">
        <div className="process-img-container">
          <img src={TimerIcon} />
        </div>
        <div className="process-main-content">
          <p className="process-subtitle">
            {
              EN.your_NaCH_mandate_has_been_initiated_this_may_take_some_time_Please_reach_out_to_the_POCs_to_get_this_checked_if_it_has_taken_more_than_2_days
            }
            <span>Click here</span>
            to re-initiate the NACH mandate.
          </p>
        </div>
      </div>
      <Modal open={openEnachModel}>
        <div className="enach-model-container">
          <div
            onClick={() => {
              setOpenEnachModel(false);
            }}
          >
            {/* <Button label="No" /> */}
            <p style={{ padding: "0 0 7px 7px" }}>Enach Register</p>
            {/* <img alt="cross" src={leftArrow}/> */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            {/* <iframe
              height="98%"
              width="98%"
              src={URL}
              title="enach auth url"
            ></iframe> */}
            <Button
              label="Click to Process ENACH"
              activeBtn
              onClick={clickHandler}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal isOpen={loading} />
    </Layout>
  );
};

export default EnachPending;
