import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const HOST = "https://mepwaapi.arthmate.com";

export const APICall = async ({ url = "", method = "post", payload = {} }) => {
  try {
    let API = HOST + url;
    let apiRes = await axios.post(API, payload, { timeout: 120000 });

    if (apiRes?.data) {
      return apiRes.data;
    } else {
    }
  } catch (error) {
    toast.error(
      `We are facing some technical issue, please try again after some time. You can also reach out to our customer support using the "i" button on top of the screen.`
    );
  }
};
