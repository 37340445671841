import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import { EN } from "../../constants/Strings/EN";
import "./UploadUdyamCertificate.scss";
import UploadButton from "../../components/UploadButton/UploadButton";
import instructionIcon from "../../assets/icons/Group.svg";
import Button from "../../components/Button/Button";
import InputField from "../../components/InputField/InputField";
import { APICall } from "../../api/APICall";
import { AppConstants } from "../../constants/AppConstants";
import {
  Confirm_Business_Details,
  upload_document_multipart,
} from "../../api/APIs";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import useDedupe from "../../hooks/useDedupe";
import useToast from "../../hooks/useToast";
import Faq from "../../components/Faq/Faq";
import { useDispatch, useSelector } from "react-redux";
import { setBackButton } from "../../redux/actions";

function UploadUdyamCertificate() {
  const { callDedupe } = useDedupe();
  const [successResCode, setSuccessResCode] = useState(0);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  let userData = JSON.parse(localStorage.getItem("userData"));
  const [activeBtn, setactiveBtn] = useState(true);
  const [faqOpen, setFaqOpen] = useState(false);
  const [error, setError] = useState("");
  const [udyamCertificate, setUdyamCertificate] = useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const { showToast } = useToast();
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const [isVerified, setIsVerified] = useState({
    udyamNumber: false,
    businessName: false,
    udyamAddressLine1: false,
    businessVintageInYears: false,
  });

  const Messages = [
    EN.we_are_collecting_your_details_please_hang_in_there,
    EN.almost_there_please_hang_in_there,
  ];
  const [currentStringIndex, setCurrentStringIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentStringIndex((prevIndex) => (prevIndex + 1) % Messages.length);
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (successResCode == 300) {
      callDedupe();
      let intervalId = setInterval(() => {
        let localUser = JSON.parse(localStorage.getItem("userData"));
        if (localUser.currentStage == "LEAD_CONFIRM_UDYAM_MANUAL_SUCCESS") {
          clearInterval(intervalId);
          handleClose();
        } else if (
          localUser.currentStage == "LEAD_CONFIRM_UDYAM_MANUAL_FAILED"
        ) {
          handleClose();
          showToast(localUser?.remark, "error", 3000);
          clearInterval(intervalId);
          setSuccessResCode(0);
        } else {
          callDedupe();
        }
      }, 30000);
    }
  }, [successResCode]);

  useEffect(() => {
    let localUser = JSON.parse(localStorage.getItem("userData"));
    if (localUser?.currentStage == "LEAD_CONFIRM_UDYAM_MANUAL_SUCCESS") {
      handleClose();
    }
  }, [userData?.currentStage]);

  const handleSubmit = async () => {
    try {
      handleOpen();
      let formData = new FormData();
      formData.append("file", udyamCertificate);
      formData.append(
        "request",
        JSON.stringify({
          DATA: {
            lenderCode: AppConstants.lenderCode,
            documentType: "DocumentType.UdyamCertificate(4Pager)",
          },
          HEADER: {
            AID: userData.mobile,
            MID: userData.mobile,
            userId: userData.userId,
          },
        })
      );
      const res = await APICall({
        url: upload_document_multipart,
        payload: formData,
      });
      if (res.RESP_CODE === 300) {
        setError("");
        setSuccessResCode(res.RESP_CODE);
      } else {
        setError(res.RESP_MSG);
        handleClose();
      }
    } catch (error) {
      handleClose();
    }
  };

  const submitUdyamDetail = async () => {
    try {
      handleOpen();
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
        },
        HEADER: {
          AID: userData.mobile,
          MID: userData.mobile,
          userId: userData.userId,
        },
      };
      const res = await APICall({
        url: Confirm_Business_Details,
        payload: PAYLOAD,
      });
      if (res.RESP_CODE === 300) {
        setError("");
        callDedupe(true);
        handleClose();
      } else {
        setError(res.RESP_MSG);
        handleClose();
      }
    } catch (error) {
      handleClose();
    }
  };

  useEffect(() => {
    let localUser = JSON.parse(localStorage.getItem("userData"));
    if (localUser?.currentStage == "LEAD_CONFIRM_UDYAM_MANUAL_SUCCESS") {
      dispatch(
        setBackButton({
          backButton: true,
        })
      );
    } else {
      dispatch(
        setBackButton({
          backButton: false,
        })
      );
    }
    setIsVerified((prev) => ({
      ...prev,
      udyamNumber: true,
      businessName: true,
      udyamAddressLine1: true,
      businessVintageInYears: true,
    }));
    callDedupe(true);
  }, []);

  return (
    <Layout>
      <LoadingModal isOpen={isOpen} subTitle={Messages[currentStringIndex]} />
      <Faq isOpen={faqOpen} setIsOpen={setFaqOpen} />
      <div className="container">
        <div className="udyam-certificate-body">
          <Title
            title={EN.upload_udyam_certificate}
            subTitle={
              EN.we_utilise_this_information_to_evaluate_your_credit_worthiness
            }
          />
          {userData?.currentStage == "LEAD_CONFIRM_UDYAM_MANUAL_SUCCESS" ? (
            <div className="udyam-details-box">
              <div className="udyam-detail-input">
                <InputField
                  disabled={isVerified.udyamNumber}
                  value={userData.udyamNumber}
                  label="Udyam Number"
                />
              </div>
              <div className="udyam-detail-input">
                <InputField
                  disabled={isVerified.businessName}
                  value={userData.businessName}
                  label="Business Name"
                />
              </div>
              <div className="udyam-detail-input">
                <InputField
                  disabled={isVerified.udyamAddressLine1}
                  value={userData.udyamAddressLine1}
                  label="Business Address"
                />
              </div>
              <div className="udyam-detail-input">
                <InputField
                  disabled={isVerified.businessVintageInYears}
                  value={`${userData.businessVintageInYears} (year)`}
                  label="Business Vintage as per date of incorporation"
                />
              </div>
            </div>
          ) : (
            <div className="udyam-input-box">
              <UploadButton
                error={error}
                setError={setError}
                setDocument={setUdyamCertificate}
                label="Udyam Certificate"
                accept="application/pdf"
              />
              {udyamCertificate && <p className="error-msg">{error}</p>}
              <div className="instruction-box">
                <img src={instructionIcon} alt="instructionIcon" />
                <p className="instruction-content">
                  {/* {
                    EN.please_upload_the_4_pager_Udyam_Click_here_to_know_how_to_download_4_pager_Udyam
                  } */}
                  Please upload 3/4/5 pager Udyam with Annexure(PDF Only)&nbsp;
                  {/* URC with 3 or more pages should be allowed Change the message
                  below URC upload button to - Please upload 3/4/5 pager Udyam
                  with Annexure (PDF Only) */}
                  <b onClick={() => setFaqOpen(true)}>
                    <u>Click here</u>
                  </b>{" "}
                  to know how to download 3 or more pager Udyam
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="udyam-submit-btn">
          <Button
            onClick={
              userData?.currentStage == "LEAD_CONFIRM_UDYAM_MANUAL_SUCCESS"
                ? submitUdyamDetail
                : handleSubmit
            }
            label={EN.submit}
            activeBtn={
              (udyamCertificate?.name?.length && activeBtn) ||
              (userData?.currentStage == "LEAD_CONFIRM_UDYAM_MANUAL_SUCCESS" &&
                activeBtn)
            }
          />
        </div>
      </div>
    </Layout>
  );
}

export default UploadUdyamCertificate;
