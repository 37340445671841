import { CircularProgress, Modal } from "@mui/material";
import React, { useState } from "react";
import { EN } from "../../constants/Strings/EN";

function LoadingModal({ subTitle, isOpen, setIsopen, onClose, onSubmitOTP }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    backgroundColor: "#fff",
    padding: "20px 8px",
    outline: "none",
    borderRadius: "8px",
  };

  return (
    <Modal
      open={isOpen}
      //   onClose={handleClose}
    >
      <div style={style}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <label
            style={{
              color: "#161719",
              fontWeight: "400",
              fontSize: "16px",
              textAlign: "center",
              margin: "10px 0px",
            }}
          >
            {EN.please_wait}
          </label>
          <label
            style={{
              color: "#767888",
              fontWeight: "400",
              fontSize: "12px",
              textAlign: "center",
              //   margin: "10px 0px",
            }}
          >
            {subTitle}
          </label>
        </div>
      </div>
    </Modal>
  );
}

export default LoadingModal;
