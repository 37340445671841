// Dropdown.js
import React, { useEffect, useRef, useState } from "react";
import Down2 from "../../assets/icons/down2.svg";
import "./Dropdown.scss";

function Dropdown({
  label = "",
  options = [],
  onSelect,
  selectedOption = "",
  setSelectedOption,
}) {
  const ref = useRef();
  // const [selectedOption, setSelectedOption] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  if (isOpen) {
    // ref.current.style.height = "auto";
    ref.current.style.maxHeight = "10vh";
  }

  useEffect(() => {
    setIsOpen(false);
  }, [selectedOption]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div
      ref={ref}
      className="dropdown-field"
      style={selectedOption.label && { height: "auto" }}
      onClick={() => {
        options?.length && setIsOpen(!isOpen);
      }}
    >
      {/* <select
        value={selectedOption}
        onChange={(e) => handleOptionClick(e.target.value)}
        className="dropdown-select"
      >
        <option value="" disabled>
          Select an option
        </option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select> */}

      <div
        className="dropdown-header"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {selectedOption ? selectedOption.label : ""}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <label className="dropdown-label">{label}</label>
        <img src={Down2} style={{ height: 16, width: 16 }} />
      </div>

      {isOpen && (
        <div className="dropdown-options">
          {options.map((option) => (
            <div
              key={option.value}
              className="dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
