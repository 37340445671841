import { configureStore } from "@reduxjs/toolkit";
import BackButtonReducer from "../reducers/BackButtonReducer";
import ProgressbarReducer from "../reducers/ProgressbarReducer";
import UrlsReducer from "../reducers/UrlsReducer";
import UserReducer from "../reducers/UserReducer";

const store = configureStore({
  reducer: {
    reducer: UserReducer,
    user: UserReducer,
    step: ProgressbarReducer,
    BackButton: BackButtonReducer,
    urls: UrlsReducer,
  },
});

export default store;
