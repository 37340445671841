import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import ApplicationPendingIcon from "../../assets/applicationPendingIcon.svg";
import reviewGif from "../../assets/gifs/reviewGif.gif";

import "./LoanOfferPending.scss";
import { EN } from "../../constants/Strings/EN";
import useDedupe from "../../hooks/useDedupe";

const LoanOfferPending = () => {
  const { callDedupe } = useDedupe();
  const Messages = [
    EN.bank_statement_under_review_please_hang_in_there,
    EN.bureau_is_under_review_please_hang_in_there,
    EN.we_are_alomost_there_with_your_offer,
  ];
  const [currentStringIndex, setCurrentStringIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentStringIndex((prevIndex) => (prevIndex + 1) % Messages.length);
    }, 30000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    callDedupe(true);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      callDedupe(true);
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Layout>
      <div className="offer-pending-container">
        <div className="offer-img-container">
          <img src={reviewGif} />
        </div>
        <div className="offer-main-content">
          <span className="offer-subtitle">
            {/* {EN.generating_best_offer_for_you_it_may_take_few_minutes} */}
            {Messages[currentStringIndex]}
          </span>
        </div>
      </div>
    </Layout>
  );
};

export default LoanOfferPending;
