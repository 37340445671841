import React, { useRef, useState } from "react";
import "./InputField.scss";
import uploadButtonIcon from "../../assets/icons/uploadArrow.svg";
import { GreenTickIcon } from "../../constants/SVG";

function InputField({
  label = "",
  value = "",
  disabled = false,
  style = {},
  tick = false,
  error = false,
  helperText,
  onChange,
  onHelperTextClick,
  ...rest
}) {
  const inputRef = useRef(null);

  return (
    <div>
      <div
        onClick={() => inputRef.current.focus()}
        className="steps-form-field"
        style={{
          ...style,
          backgroundColor: disabled ? "#D8D9DE" : "#fff",
          border: `1px solid ${error ? "#d71a00" : "#cccdd3"}`,
        }}
      >
        <input
          ref={inputRef}
          placeholder={""}
          type="text"
          value={value}
          className="steps-form-input"
          id="panNumber"
          autoComplete="off"
          onChange={onChange}
          disabled={disabled}
          {...rest}
        />
        <label className="steps-form-label" htmlFor="panNumber">
          {label}
        </label>
        {tick && (
          <GreenTickIcon
            style={{ position: "absolute", right: "0.8rem", top: "0.8rem" }}
          />
        )}
      </div>
      <div
        className="helper-text"
        style={{ color: error ? "#d71a00" : "#767888" }}
        onClick={onHelperTextClick && onHelperTextClick}
      >
        {helperText}
      </div>
    </div>
  );
}

export default InputField;

export function FileInputField({
  uploadButton = "",
  label = "",
  onChange,
  inputId,
  singleInput,
  setisFilepresent,
  index,
  handleDeleteDoc,
  setError,
  dynamicInputs,
  // handleFileChange,
  error,
  ...rest
}) {
  const [fileName, setfileName] = useState("");
  const handleFileChange = (e) => {
    setError("");
    singleInput.file = e.target.files[0];
    singleInput.documentName = e.target.files[0]?.name;
    setisFilepresent(e.target.files[0]);
    setfileName(e.target.files[0]?.name);
  };

  return (
    <div
      className={
        // fileName &&
        error.length
          ? "steps-form-field error-border"
          : fileName
          ? "steps-form-field success-border"
          : "steps-form-field"
      }
    >
      <input
        placeholder={""}
        type="file"
        //   onFocus={() => setpanInputFocus(true)}
        //   onBlur={() => setpanInputFocus(false)}
        className="file-steps-form-input"
        // value={fileName}
        //   onChange={handlePanNumber}
        id={inputId}
        autoComplete="off"
        onChange={handleFileChange}
        accept="application/pdf"
        {...rest}
      />
      <div className="file-steps-form-label">
        <div
          className={
            fileName
              ? "file-label-inner-box small-label"
              : "file-label-inner-box"
          }
        >
          <div>
            <p>{label}</p>
            <p className="file-name">{singleInput.documentName}</p>
          </div>
          <div className="uploadButtonBox">
            {fileName ? (
              ""
            ) : (
              <img src={uploadButtonIcon} alt="uploadButtonIcon" />
            )}
            {index > 0 && fileName ? (
              <div>
                <label htmlFor={inputId}>
                  {fileName ? "Change/" : "Upload"}
                </label>
                {/* {index == dynamicInputs.length - 1 && ( */}
                <p
                  className="deleteBtn"
                  onClick={() => {
                    handleDeleteDoc(index);
                  }}
                >
                  Delete
                </p>
                {/* )} */}
              </div>
            ) : (
              <label htmlFor={inputId}>{fileName ? "Change" : "Upload"}</label>
            )}
          </div>
        </div>
      </div>

      {/* <img src={verifiedCheck} className="verified-check" /> */}
    </div>
  );
}
