import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { APICall } from "../../api/APICall";
import { upload_Bank_Statement } from "../../api/APIs";
import Group from "../../assets/icons/Group.svg";
import Button from "../../components/Button/Button";
import Faq from "../../components/Faq/Faq";
import InputField, {
  FileInputField,
} from "../../components/InputField/InputField";
import Layout from "../../components/Layout/Layout";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import Title from "../../components/Title/Title";
import { AppConstants } from "../../constants/AppConstants";
import { EN } from "../../constants/Strings/EN";
import useDedupe from "../../hooks/useDedupe";
import { setBackButton } from "../../redux/actions";
import "./UploadDocument.scss";

function UploadDocument() {
  let userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [isFilepresent, setisFilepresent] = useState();
  const [activeBtn, setactiveBtn] = useState(true);
  const [isOpen, setIsOpen] = React.useState(false);
  const [faqOpen, setFaqOpen] = React.useState(false);
  const { callDedupe } = useDedupe();
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const [dynamicInputs, setdynamicInput] = useState([
    { id: 1, documentName: "", file: "" },
  ]);

  useEffect(() => {
    dispatch(
      setBackButton({
        backButton: false,
      })
    );
  }, []);

  useEffect(() => {
    switch (userData?.currentStage) {
      case "BANK_STMT_RETRY":
      case "CURRENT_ADDRESS_PROOF":
        setError(
          `Please upload the bank statement from ${
            JSON.parse(userData?.remark).start
          } to ${JSON.parse(userData?.remark).end}`
        );
        break;

      case "BANK_STMT_NAME_NOT_MATCH":
      case "INVALID_BANK_STATEMENT":
        setError(userData?.remark);
        break;

      default:
        break;
    }
  }, []);

  // validate slected file size.
  const validateSelectedFile = () => {
    const MAX_FILE_SIZE = 10000;
    const fileSizeKiloBytes = isFilepresent.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setError("File size is greater than 10MB");
    }
  };
  // Add Dynamic Inputs
  const handleAddInput = () => {
    const lastNumericPart =
      dynamicInputs.length > 0
        ? Math.max(...dynamicInputs.map((input) => Number(input.id)))
        : 0;
    const newInputId = lastNumericPart + 1;
    const newInput = { id: newInputId, label: `Document ${newInputId}` };
    setdynamicInput((prevInputs) => [...prevInputs, newInput]);
  };

  // Remove Dynamic Inputs
  const handleDeleteDoc = (index) => {
    const dynamicInputsCopy = [...dynamicInputs];
    dynamicInputsCopy.splice(index, 1);
    setdynamicInput(dynamicInputsCopy);
  };

  const onChange = (e) => {
    setError("");
    setPassword(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      if (!error) {
        validateSelectedFile();
        handleOpen();
        let formData = new FormData();
        const fileNamesArray = dynamicInputs.map((input) => input.file);
        fileNamesArray.forEach((fileName, index) => {
          formData.append(`file`, fileName);
        });
        formData.append(
          "request",
          JSON.stringify({
            DATA: {
              lenderCode: AppConstants.lenderCode,
              documentType: "IncomeProofDocument.BankStatement",
              documentPassword: password ? password : "",
            },
            HEADER: {
              AID: userData.mobile,
              MID: userData.mobile,
              userId: userData.userId,
            },
          })
        );
        if (!error) {
          let res = await APICall({
            url: upload_Bank_Statement,
            payload: formData,
          });

          if (res.RESP_CODE !== 300) {
            // Handle success
            setError(res.RESP_MSG);
            setdynamicInput([{ id: 1, documentName: "", file: "" }]);
            handleClose();
          } else {
            setError("");
            handleClose();
            callDedupe(true);
          }
        }
      }
    } catch (error) {
      handleClose();
    }
  };

  return (
    <>
      <Layout>
        <LoadingModal
          isOpen={isOpen}
          subTitle={EN.we_are_checking_the_submitted_document}
        />
        <div className="container">
          <div className="upload-document-body">
            <div>
              <Title
                title={EN.upload_documents}
                subTitle={
                  EN.please_upload_your_bank_statement_with_higher_transaction_preferably_the_account_using_which_you_pay_other_EMIs
                }
              ></Title>
              <div className="bank-statement-box">
                {/* <FileInputField setisFilepresent={setisFilepresent}  label={EN.bank_statement_6_months} /> */}

                {dynamicInputs.map((singleInput, index) => (
                  <FileInputField
                    handleDeleteDoc={handleDeleteDoc}
                    index={index}
                    setisFilepresent={setisFilepresent}
                    inputId={index + 1}
                    singleInput={singleInput}
                    key={index}
                    error={
                      error.includes("Please upload the bank statement from") &&
                      userData?.currentStage == "CURRENT_ADDRESS_PROOF"
                        ? false
                        : error
                    }
                    setError={setError}
                    dynamicInputs={dynamicInputs}
                    label={EN.bank_statement_6_months}
                  />
                ))}
                {isFilepresent && <p className="error-msg">{error}</p>}
                {error.includes("Please upload the bank statement from") && (
                  <p
                    className={
                      userData?.currentStage == "CURRENT_ADDRESS_PROOF"
                        ? "non-error-helper-msg"
                        : "error-msg"
                    }
                  >
                    {error}
                  </p>
                )}
                {(userData?.currentStage == "BANK_STMT_NAME_NOT_MATCH" ||
                  userData?.currentStage == "INVALID_BANK_STATEMENT") && (
                  <p className="error-msg">{error}</p>
                )}
                {/* {userData?.currentStage == "BANK_STMT_RETRY" &&
                  !isFilepresent && (
                    <p className="error-msg-6-months">
                      Please upload latest&nbsp;<b>6 months</b>&nbsp;bank
                      statement
                    </p>
                  )} */}
                {!isFilepresent?.name?.length ? (
                  ""
                ) : (
                  <p
                    className="upload-additional-doc"
                    onClick={handleAddInput}
                    style={{
                      display: dynamicInputs[dynamicInputs.length - 1].file
                        ? "inline-block"
                        : "none",
                    }}
                  >
                    {EN.upload_additional_bank_statements}
                  </p>
                )}
                {/*<div className="last-bank-statement">
            <input type="checkbox" /> <p>This is the last Bank Statement</p>
          </div>*/}
              </div>
              <div className="pdf-upload-instruction">
                <div className="instruction-icon">
                  <img alt="pdf-upload-instruction" src={Group} />
                </div>
                <div className="instruction-content">
                  <p>-{EN.original_and_PDF_only_up_to_5MB}</p>
                  <p>
                    -
                    <b style={{ color: "#777" }}>
                      Latest 6 months complete bank statement
                    </b>
                  </p>
                  <p>-{EN.upload_the_Net_banking_statement}</p>
                  <p>
                    -
                    {
                      EN.if_you_have_multiple_statements_for_the_6_months_period__use_the_Upload_additional_documents_button_to_upload_them_one_by_one
                    }
                  </p>
                  <p>-{EN.do_not_upload_bank_statement_of_multiple_banks}</p>
                  <p>
                    Need help with Bank statement upload,{" "}
                    <span onClick={() => setFaqOpen(true)}>Click here</span>
                  </p>
                </div>
              </div>
              <div className="file-pass-instruction">
                <p>
                  If the files are password-protected, please provide the
                  password.
                </p>
                <InputField
                  value={password}
                  onChange={onChange}
                  label={EN.enter_file_password}
                />
              </div>
            </div>
          </div>
          <div className="submit-and-next-btn-box">
            <Button
              onClick={handleSubmit}
              activeBtn={isFilepresent?.name?.length && activeBtn}
              label={EN.submit_n_next}
            />
          </div>
        </div>
        <Faq isOpen={faqOpen} setIsOpen={setFaqOpen} />
      </Layout>
    </>
  );
}

export default UploadDocument;
