import { CircularProgress, Modal } from "@mui/material";
import React, { useState } from "react";
import { EN } from "../../constants/Strings/EN";
import "./BackModel.scss";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

function BackModel({ subTitle, isOpen, setIsOpen, onClose, onSubmitOTP }) {
    const [activeBtn, setActiveBtn] = useState(true)
    const navigate = useNavigate()
    const handleActionOnYes =()=>{
        navigate("/")
    }
  return (
    <Modal
      open={isOpen}
      //   onClose={handleClose}
    >
      <div className="back-model-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <label className="back-content-box">
            <div className="back-inner-content-box" >
              <p>Back?</p>
              <p className="warning">
                Are you sure you want to go back? Don't worry, once you return,
                the application will resume from where you left off.
              </p>
              <div className="action-button-box">
                <div>
                  <Button label="Yes" onClick={handleActionOnYes} activeBtn={activeBtn} />
                </div>
                <div onClick={()=>{setIsOpen(false)}}>
                  <Button label="No" />
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
    </Modal>
  );
}

export default BackModel;
