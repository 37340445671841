import React, { forwardRef, useState } from "react";
import "./Button.scss";

const Button = forwardRef(function Button(props, ref) {
  const {
    label,
    activeBtn = false,
    color = "",
    textColor = "",
    onClick,
    ...otherProps
  } = props;
  return (
    <div
      className={activeBtn ? "active-form-btn" : "disable-form-btn"}
      style={{ backgroundColor: color || "" }}
      ref={ref}
      {...otherProps}
      onClick={onClick}
    >
      <span style={{ color: textColor || "#C0C1C8" }}>{label}</span>
    </div>
  );
});

export default Button;
