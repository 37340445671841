import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APICall } from "../../api/APICall";
import {
  Get_Pincode_Dtl,
  Update_Residence_Details,
  upload_document_multipart,
} from "../../api/APIs";
import ExclamationMark from "../../assets/icons/exclamationMark.svg";
import Button from "../../components/Button/Button";
import Dropdown from "../../components/Dropdown/Dowpdown";
import InputField from "../../components/InputField/InputField";
import Label from "../../components/Label/Label";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import UploadButton from "../../components/UploadButton/UploadButton";
import { AppConstants } from "../../constants/AppConstants";
import { EN } from "../../constants/Strings/EN";
import useDedupe from "../../hooks/useDedupe";
import useToast from "../../hooks/useToast";
import { setBackButton } from "../../redux/actions";
import { DropdownListFormat } from "../../util/formatter";
import "./VerifiedAadhaar.scss";

const VerifiedAadhaar = ({
  setNewAddress,
  setAddressModal,
  addressModal,
  newAddress,
}) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [identityProofDoc, setidentityProofDoc] = useState();
  const [activeBtn, setactiveBtn] = useState(false);
  const [error, setError] = useState("");

  const [pincodeData, setPincodeData] = useState({});
  const [cityList, setCityList] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [address, setAddress] = useState({
    pincode: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
  });
  const { showToast } = useToast();
  const { callDedupe } = useDedupe();

  useEffect(() => {
    address?.pincode?.length === 6 && getPinDetails();
  }, [address?.pincode]);

  useEffect(() => {
    if (identityProofDoc?.name?.length) {
      setactiveBtn(true);
    }
  }, [identityProofDoc]);

  useEffect(() => {
    dispatch(
      setBackButton({
        backButton: true,
      })
    );
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress((prev) => ({ ...prev, [name]: value }));
  };

  const getPinDetails = async () => {
    setSelectedOption();
    setCityList([]);
    setLoading(true);
    try {
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          pincode: address.pincode,
        },
        HEADER: {
          AID: userData.mobile,
          MID: userData.mobile,
          userId: userData.userId,
        },
      };

      let res = await APICall({ url: Get_Pincode_Dtl, payload: PAYLOAD });
      if (res?.RESP_CODE === 300) {
        setPincodeData(res.DATA);
        setCityList(DropdownListFormat({ list: res.DATA?.cityArray }));
        setLoading(false);
      } else showToast(res?.RESP_MSG, "error", 3000);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // Upload Identity Proof
  const uploadIdentityProof = async () => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("file", identityProofDoc);
      formData.append(
        "request",
        JSON.stringify({
          DATA: {
            lenderCode: AppConstants.lenderCode,
            documentType: "DocumentType.ResidentialAddressProof",
          },
          HEADER: {
            AID: userData.mobile,
            MID: userData.mobile,
            userId: userData.userId,
          },
        })
      );
      const res = await APICall({
        url: upload_document_multipart,
        payload: formData,
      });
      if (res.RESP_CODE === 300) {
        setError("");
        submitHandler();
        setLoading(false);
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
        setError(res.RESP_MSG);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Submit residencial address
  const submitHandler = async () => {
    setLoading(true);
    try {
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          address: {
            addressTypeCode: "AddressType.Current",
            addressLine1: address.addressLine1,
            addressLine2: address.addressLine2,
            city: address?.city,
            state: pincodeData.stateName || "",
            pincode: address.pincode,
          },
        },
        HEADER: {
          AID: userData.mobile,
          MID: userData.mobile,
          userId: userData.userId,
        },
      };

      let res = await APICall({
        url: Update_Residence_Details,
        payload: PAYLOAD,
      });
      if (res.RESP_CODE === 300) {
        callDedupe(true);
        setLoading(false);
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div style={{ margin: "1.5rem 0" }}>
      <Label label={EN.enter_current_address} />
      <div className="input-container">
        <InputField
          label={EN.pincode}
          name="pincode"
          value={address.pincode}
          // onChange={handleChange}
          onChange={(e) => {
            const inputValue = e.target.value;
            const regex = /^\d{0,6}$/;
            if (regex.test(inputValue)) {
              setAddress((prev) => ({ ...prev, pincode: inputValue }));
            }
          }}
        />
        <InputField
          label={EN.address_line_1}
          name="addressLine1"
          value={address.addressLine1}
          onChange={handleChange}
          maxLength={40}
          error={
            address.addressLine1.length > 0 &&
            (address.addressLine1.length < 5 ||
              address.addressLine1.length > 41)
          }
          helperText={
            address.addressLine1.length > 0 &&
            (address.addressLine1.length < 5 ||
              address.addressLine1.length > 41) &&
            EN.please_provide_the_complete_address_to_proceed_further
          }
        />
        <InputField
          label={EN.address_line_2}
          value={address.addressLine2}
          name="addressLine2"
          onChange={handleChange}
          maxLength={40}
          error={
            address.addressLine2.length > 0 &&
            (address.addressLine2.length < 5 ||
              address.addressLine2.length > 41)
          }
          helperText={
            address.addressLine2.length > 0 &&
            (address.addressLine2.length < 5 ||
              address.addressLine2.length > 41) &&
            EN.please_provide_the_complete_address_to_proceed_further
          }
        />
        <div style={{ display: "flex", gap: "14px", alignItems: "center" }}>
          <div style={{ width: "50%" }}>
            <InputField
              label={EN.state}
              value={pincodeData?.stateName}
              disabled
            />
            {/* <Dropdown label={EN.state} /> */}
          </div>
          <div style={{ width: "50%" }}>
            <Dropdown
              label={EN.city}
              name="city"
              options={cityList}
              onSelect={(e) => {
                setAddress((prev) => ({ ...prev, city: e.value }));
              }}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
        </div>
        <UploadButton
          label={EN.proof_of_address}
          setDocument={setidentityProofDoc}
        />
      </div>
      <div className="consent-container">
        <img src={ExclamationMark} />
        <span>
          {
            EN.rent_agreement_utility_bills_like_electricity_gas_wifi_telephone_bills
          }
        </span>
      </div>
      <Button
        label={EN.submit_n_next}
        activeBtn={
          activeBtn &&
          address.pincode.length > 5 &&
          address.addressLine1.length > 4 &&
          address.addressLine1.length < 41 &&
          address.addressLine2.length > 4 &&
          address.addressLine2.length < 41
        }
        onClick={uploadIdentityProof}
      />
      <LoadingModal
        isOpen={loading}
        setIsopen={setLoading}
        subtitle={EN.we_are_checking_the_verifying_the_information}
      />
    </div>
  );
};

export default VerifiedAadhaar;
