import React, { useRef, useState } from "react";
import Upload from "../../assets/icons/upload.svg";
import "./UploadButton.scss";
import coorectICon from "../../assets/icons/correct-icon.svg";

const UploadButton = ({
  label,
  index,
  setCurrentDocIndex,
  setkycDocArray,
  error,
  setError,
  setDocument,
  accept,
  disabled,
  ...rest
}) => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState();
  const handleFileChange = (event) => {
    setError && setError("");
    setCurrentDocIndex && setCurrentDocIndex(0);
    setkycDocArray &&
      setkycDocArray((prev) =>
        prev.map((item, i) =>
          i === index
            ? { ...item, file: event.target.files[0], error: "" }
            : item
        )
      );
    setSelectedFile(event.target?.files[0]);
    setDocument && setDocument(event.target?.files[0]);
  };

  return (
    <div
      style={{ backgroundColor: disabled ? "#D8D9DE" : "#fff" }}
      className={
        selectedFile && error?.length
          ? "button-error button"
          : selectedFile
          ? "button-border button"
          : " button"
      }
    >
      <div className="row">
        <div className="inner-row">
          {selectedFile?.name && error?.length
            ? ""
            : selectedFile?.name && (
                <img
                  className="correct-icon"
                  alt="correct-icon"
                  src={coorectICon}
                />
              )}
          <div>
            <p className={selectedFile?.name && "label-name"}>{label}</p>
            <p>{selectedFile?.name}</p>
          </div>
        </div>
        <div style={{ display: "flex" }} onClick={() => {}}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept={accept}
            disabled={disabled}
            {...rest}
          />
          {selectedFile?.name ? (
            <label
              className="upload"
              onClick={() => fileInputRef.current.click()}
            >
              Change
            </label>
          ) : (
            <>
              <img src={Upload} style={{ marginRight: "10px" }} />
              <label
                className="upload"
                onClick={() => fileInputRef.current.click()}
              >
                Upload
              </label>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadButton;
