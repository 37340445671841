import { Modal } from "@mui/material";
import React, { useState } from "react";
import { EN } from "../../constants/Strings/EN";
import AccordionCard from "../AccordionCard/AccordionCard";
import ModalTitle from "../ModalTitle/ModalTitle";
import "./Faq.scss";
import { IoMdClose } from "react-icons/io";
import ContactUsModal from "../ContactUsModal/ContactUsModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  backgroundColor: "#fff",
  padding: "20px 8px",
  outline: "none",
  borderRadius: "8px",
};

const Faq = ({ isOpen = false, setIsOpen }) => {
  const [contactOpen, setContactOpen] = useState(false);

  let GeneralFaqData = [
    {
      question: "How can I contact customer support?",
      answer:
        'You can contact customer support if you face any issues by clicking on the "i" button located in the top right corner of all screens',
    },
    {
      question:
        " How much time does it take to get the loan amount credited in my bank account?    ",
      answer:
        "The loan amount will generally be deposited into your bank account within 2 working days",
    },
    {
      question: "What documents do I need to provide?",
      answer:
        "Required documents include proof of identity (PAN card and Aadhaar card), proof of income (Bank statement) and Udyam registration certificate",
    },
    {
      question: "How much can I borrow?",
      answer:
        "The amount you can borrow depends on factors such as your income and creditworthiness.",
    },
    {
      question:
        "What should I do if my loan application is rejected and what are the reasons?",
      answer:
        "If your loan application is rejected, please upload another bank statement showing a higher volume of transactions. Your loan application may be rejected for various reasons, such as a low CIBIL score, high EMI bounces, or failure to meet our policy.",
    },
    {
      question:
        "My loan is in manual kyc status , how much time should I wait?",
      answer: "Please check back after 10-15 mins.",
    },
    {
      question: "What to do if I am getting a penny dropped failed error?",
      answer: `If you are receiving a 'penny drop failed' error, it indicates that the account details you have provided are invalid or the account is not active.`,
    },
    {
      question: "What to do if I am getting a name match error in PAN?",
      answer:
        "This error occurs when your Airtel account name does not match your PAN name. Contact the Airtel team to get the name changed in your app.",
    },
    {
      question:
        "How can I check the status of my loan after submitting the application?",
      answer:
        "Please check back within 2 working days. If the loan is disbursed, you will be directed to the active loan dashboard screen.",
    },
  ];

  const Note = () => {
    return (
      <p className="note">
        Note: Please do not click on the browser back button at any stage in the
        journey.
      </p>
    );
  };

  const GeneralFaqs = () => {
    return (
      <div className="card-content-container">
        {GeneralFaqData.map((i) => {
          return (
            <ul>
              <li>
                <b>{i.question}</b>
                <ul>
                  <li>{i.answer}</li>
                </ul>
              </li>
            </ul>
          );
        })}
        <Note />
      </div>
    );
  };
  const BankStatementFaq = () => {
    return (
      <div className="card-content-container">
        <ul>
          <li>
            Upload Net banking PDF for a higher chance of success in receiving
            the loan offer.
            <ul>
              <li>The file size is limited to 5MB</li>
              <li>Provide the last 6 months' complete bank statement only.</li>
            </ul>
          </li>
          <li>
            Don’t upload any scanned or tampered Bank statement as they will be
            rejected
          </li>
          <li>
            Upload a bank statement with a healthy number of transactions,
            ideally from your primary account, for a better loan offer.
          </li>
          <li>
            Do not upload bank statements from Overdraft accounts and Cash
            Credit (CC) accounts.
          </li>
          <li>
            If you encounter any issues, contact the Points of Contact (POCs)
            with a screenshot of the problem
          </li>
          <li>
            Ensure that the bank statement name matches the applicant’s name or
            business name according to PAN and Udyam, respectively; otherwise,
            the application will be rejected.
          </li>
        </ul>
        <Note />
      </div>
    );
  };
  const UdyamUploadFaq = () => {
    return (
      <div className="card-content-container">
        <ul>
          <li>
            Upload the 4-page Udyam (in some cases, it can be 5/3-page as well)
            <ul>
              <li>
                Kindly refer to the link for instructions on how to download the
                document:{" "}
                <a
                  href="https://www.youtube.com/watch?v=33akeOG_AVg"
                  target="_blank"
                >
                  &nbsp;www.youtube.com
                </a>
              </li>
              <li>
                Kindly refer to the link for instructions on how to register for
                Udyam registration certificate{" "}
                <a
                  href="https://www.youtube.com/watch?v=hVzKAS6To38"
                  target="_blank"
                >
                  &nbsp;www.youtube.com
                </a>
              </li>
              <li>
                Ensure the document is clearly visible, with no content cut off.
              </li>
            </ul>
          </li>
          <li>Upload the PDF of the Udyam, scanned copies are not accepted.</li>
          <li>
            The business vintage must be at least 12 months or more.
            <ul>
              <li>The Date of incorporation should be present in Udyam.</li>
            </ul>
          </li>
        </ul>
        <Note />
      </div>
    );
  };
  const AdditionalDocsFaq = () => {
    return (
      <div className="card-content-container">
        <ul>
          <li>
            There are multiple labels(text) for uploading additional documents.
            <ul>
              <li>
                These documents are not required in all cases; it will be on a
                case-by-case basis.
              </li>
            </ul>
          </li>
          <li>
            Upload documents in their respective labels(text); use
            “other/miscellaneous” only if another document is requested that is
            not listed in the labels(text).
          </li>
          <li>
            After uploading all the documents, click on the submit button and
            return to the dashboard screen.
          </li>
        </ul>
        <Note />
      </div>
    );
  };

  const data = [
    {
      id: 1,
      label: EN.general_faqs,
      content: <GeneralFaqs />,
    },
    {
      id: 2,
      label: EN.bank_statement_faqs,
      content: <BankStatementFaq />,
    },
    {
      id: 3,
      label: EN.udyam_upload,
      content: <UdyamUploadFaq />,
    },
    {
      id: 4,
      label: EN.additional_docs,
      content: <AdditionalDocsFaq />,
    },
  ];

  return (
    <>
      <Modal open={isOpen}>
        <div style={style} className="faq-container">
          <div className="faq-container">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ModalTitle title="FAQ" />
              <IoMdClose size={30} onClick={() => setIsOpen(false)} />
            </div>
            <p>
              If you encounter any challenges not addressed by the FAQs, please
              feel free to reach out to the Points of Contact (PoCs) for
              assistance. We are here to help you
            </p>
            {data.map((i) => {
              return (
                <div key={i.id} className="component-container">
                  <AccordionCard label={i.label} content={i.content} />
                </div>
              );
            })}
            <span className="descn">
              Your query is still not resolved? Please click{" "}
              <span
                onClick={() => {
                  setContactOpen(true);
                  setIsOpen(false);
                }}
              >
                <b>
                  <u>here</u>
                </b>
              </span>{" "}
              to reachout to our customer support
            </span>
          </div>
        </div>
      </Modal>
      <ContactUsModal
        isOpen={contactOpen}
        handleClose={() => setContactOpen(false)}
      />
    </>
  );
};

export default Faq;
