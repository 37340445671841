import React from "react";
import { IoMdClose } from "react-icons/io";
import "./PDFViewLayout.scss";
import { DownloadIcon } from "../../constants/SVG";

const PDFViewLayout = ({
  label = "",
  src = "",
  isModal = false,
  onClose,
  viewOnly = false,
  content = "",
  contentStyle = {},
}) => {
  const handleDownload = () => {
    const pdfUrl = src;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="card-container">
      <div className="card-header">
        <div
          style={{ display: "flex", flex: 1, justifyContent: "center" }}
          onClick={onClose}
        >
          {isModal && <IoMdClose />}
        </div>
        <div
          style={{
            display: "flex",
            flex: 8,
            justifyContent: "center",
          }}
        >
          <p>{label}</p>
        </div>
        <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
          {/* {src && (
            <DownloadIcon viewBox="0 -4 15 30" onClick={handleDownload} />
          )} */}
        </div>
      </div>
      <div className="card-content-container" style={contentStyle}>
        {src ? (
          <iframe
            title="PDF Viewer"
            src={src}
            width="100%"
            height="100%"
            style={{ border: "none" }}
          />
        ) : viewOnly ? (
          <div style={{ height: "100%", overflow: "scroll" }}>
            <p>{content}</p>
          </div>
        ) : (
          <center>
            <h4>Loading...!</h4>
          </center>
        )}
      </div>
    </div>
  );
};

export default PDFViewLayout;
