export const AmountFormatter = (amt) => {
  return `\u20B9${
    !!amt
      ? amt?.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",")
      : ""
  }`;
};

export const DropdownListFormat = ({ list = [], label = "", value = "" }) => {
  let newList = list?.map((i) => {
    return { label: i[label || "value"], value: i[value || "key"] };
  });
  return newList;
};
