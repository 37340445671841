import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import useToast from "./hooks/useToast";
import store from "./redux/store";
import Routing from "./router/Routing";

function App() {
  const { ToastContainerComponent } = useToast();

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routing />

        <ToastContainerComponent />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
