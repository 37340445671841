import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import { FilledInput } from "@mui/material";
import InputField from "../../components/InputField/InputField";
import { EN } from "../../constants/Strings/EN";
import UploadButton from "../../components/UploadButton/UploadButton";
import "./AdditionalDocument.scss";
import InstructionIcon from "../../assets/icons/instruction-icon.svg";
import Button from "../../components/Button/Button";
import useDedupe from "../../hooks/useDedupe";
import { AppConstants } from "../../constants/AppConstants";
import { APICall } from "../../api/APICall";
import { upload_document_multipart } from "../../api/APIs";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { useDispatch } from "react-redux";
import { setBackButton } from "../../redux/actions";

function AdditionalDocument() {
  let userData = JSON.parse(localStorage.getItem("userData"));
  const [documentToUpload, setdocumentToUpload] = useState([]);
  const { callDedupe } = useDedupe();
  const [activeBtn, setactiveBtn] = useState(false);
  const [currentDocIndex, setCurrentDocIndex] = useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const dispatch = useDispatch();

  const [additionalDocArray, setadditionalDocArray] = useState([
    {
      label: EN.Dual_Name_Declaration,
      file: "",
      error: "",
      documentType: "DocumentType.DualNameDeclaration",
    },
    { label: EN.NOCs, file: "", error: "", documentType: "DocumentType.NOC" },
    {
      label: EN.Affidavit_Declaration_Others,
      file: "",
      error: "",
      documentType: "DocumentType.Affidavit",
    },
    {
      label: EN.Udyam_Registration_Certificate,
      file: "",
      error: "",
      documentType: "DocumentType.UdyamCertificate(4Pager).NoParsing",
    },
    {
      label: EN.other_1,
      file: "",
      error: "",
      documentType: "DocumentType.Other_1",
    },
    {
      label: EN.other_2,
      file: "",
      error: "",
      documentType: "DocumentType.Other_2",
    },
  ]);
  // console.log("additionalDocArray----------->", additionalDocArray);
  useEffect(() => {
    dispatch(
      setBackButton({
        backButton: true,
      })
    );
  }, []);

  useEffect(() => {
    const atLeastOneFilePresent = additionalDocArray.some(
      (item) => item?.file?.name?.length > 0
    );
    setactiveBtn(atLeastOneFilePresent);
  }, [additionalDocArray]);

  const handleUpload = async () => {
    let sendToBackendArray = additionalDocArray.filter((item) => {
      return item.file?.name;
    });
    setdocumentToUpload(sendToBackendArray);

    try {
      handleOpen();
      let formData = new FormData();
      formData.append("file", sendToBackendArray[currentDocIndex].file);
      const documentType = sendToBackendArray[currentDocIndex].documentType;
      formData.append(
        "request",
        JSON.stringify({
          DATA: {
            lenderCode: AppConstants.lenderCode,
            documentType: documentType,
          },
          HEADER: {
            AID: userData.mobile,
            MID: userData.mobile,
            userId: userData.userId,
          },
        })
      );
      const res = await APICall({
        url: upload_document_multipart,
        payload: formData,
      });
      if (res.RESP_CODE === 300) {
        setCurrentDocIndex((pre) => pre + 1);
        // callDedupe();
      } else {
        // Set the error for the current input
        setadditionalDocArray((prev) => {
          const updatedArray = [...prev];
          updatedArray[currentDocIndex].error = res.RESP_MSG;
          return updatedArray;
        });
        setCurrentDocIndex(0);
        handleClose();
      }
    } catch (error) {
      handleClose();
    }
  };

  useEffect(() => {
    if (currentDocIndex > 0) {
      if (currentDocIndex < documentToUpload.length) {
        handleUpload();
      } else {
        handleClose();
        callDedupe(true);
      }
    }
  }, [currentDocIndex]);

  return (
    <Layout>
      <LoadingModal
        isOpen={isOpen}
        subTitle={EN.we_are_checking_the_submitted_document}
      />
      <div className="container">
        <Title
          title="Additional Documents"
          subTitle="For loan disbursement, we will request for these additional documents"
        />
        <div className="additional-doc-input-box">
          {additionalDocArray?.map((singleDoc, index) => {
            return (
              <div className="singleInput-box" key={singleDoc?.documentType}>
                <UploadButton
                  label={singleDoc.label}
                  setkycDocArray={setadditionalDocArray}
                  index={index}
                  error={singleDoc.error}
                />
                {singleDoc.error && (
                  <p className="additional-doc-error">{singleDoc.error}</p>
                )}
              </div>
            );
          })}
          <div className="doc-instruction">
            <img
              alt="InstructionIcon"
              className="instruction"
              src={InstructionIcon}
            />
            <p>PDF upto 5MB</p>
          </div>
        </div>
        <div className="submit-btn">
          <Button
            activeBtn={activeBtn}
            label={EN.submit_n_next}
            onClick={handleUpload}
          />
        </div>
      </div>
    </Layout>
  );
}

export default AdditionalDocument;
