import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Layout.scss";
import { useLocation } from "react-router-dom";

const Layout = ({ children, ...rest }) => {
  const { pathname } = useLocation();

  const screens = ["/Details", "/Regret", "LoanProcess"];

  return (
    <div {...rest} className="layout">
      {pathname == screens.includes(pathname) ? null : (
        <div className="layout-header">
          <Header />
        </div>
      )}
      <div className="layout-body">{children}</div>
      <div className="layout-footer">
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
