import React, { useEffect, useState } from "react";
import { APICall } from "../../api/APICall";
import { Create_Loan } from "../../api/APIs";
import ApplicationPendingIcon from "../../assets/applicationPendingIcon.svg";
import verificationGif from "../../assets/gifs/verificationGif.gif";
import Button from "../../components/Button/Button";
import Layout from "../../components/Layout/Layout";
import { EN } from "../../constants/Strings/EN";
import useDedupe from "../../hooks/useDedupe";
import useToast from "../../hooks/useToast";
import "./LoanApplicationPending.scss";
import LoadingModal from "../../components/LoadingModal/LoadingModal";

const LoanApplicationPending = () => {
  const { callDedupe } = useDedupe();
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  let userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    callDedupe(true);
    const interval = setInterval(() => {
      callDedupe(true);
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const submitHandler = async () => {
    try {
      setLoading(true);
      let PAYLOAD = {
        DATA: {},
        HEADER: {
          AID: userData.mobile,
          MID: userData.mobile,
          userId: userData.userId,
        },
      };

      let res = await APICall({ url: Create_Loan, payload: PAYLOAD });

      if (res?.RESP_CODE === 300) {
        callDedupe(true);
        setLoading(false);
      } else if (res?.RESP_CODE === 301) {
        showToast(res?.RESP_MSG, "warning", 3000);
      } else showToast(res?.RESP_MSG, "error", 3000);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="process-container">
        <div className="process-img-container">
          <img src={verificationGif} />
        </div>
        <div className="process-main-content">
          <span className="process-subtitle">
            {
              EN.the_loan_is_undergoing_manual_KYC_verification_Please_check_back_in_10_to_15_minutes
            }
          </span>
        </div>
        {/* <Button label={EN.check_status} activeBtn onClick={submitHandler} /> */}
      </div>
      <LoadingModal isOpen={loading} setIsopen={setLoading} />
    </Layout>
  );
};

export default LoanApplicationPending;
