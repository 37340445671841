import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APICall } from "../../api/APICall";
import {
  Confirm_Sanction_Amount,
  Get_Offer_Details_By_Tenure,
  Get_Sanction_Loan_Calculation,
} from "../../api/APIs";
import Medal from "../../assets/medal.svg";
import Button from "../../components/Button/Button";
import Layout from "../../components/Layout/Layout";
import ModalTitle from "../../components/ModalTitle/ModalTitle";
import { AppConstants } from "../../constants/AppConstants";
import { HelpIcon } from "../../constants/SVG";
import { EN } from "../../constants/Strings/EN";
import useDedupe from "../../hooks/useDedupe";
import useToast from "../../hooks/useToast";
import { setBackButton, setIsOfferConfirm } from "../../redux/actions";
import { AmountFormatter } from "../../util/formatter";
import "./Offer.scss";

const Offer = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState("");
  const [tenureSlider, setTenureSlider] = useState(36);
  const [tenureShowSlider, setTenureShowSlider] = useState(36);

  const [amountLimits, setAmountLimits] = useState({});
  const [tenureData, setTenureData] = useState({});
  const [fees, setFees] = useState({});
  const [otherCharges, setOtherCharges] = useState("");
  const [helperText, setHelperText] = useState(
    EN.increase_the_tenure_to_avail_the_max_amount
  );
  const [defaultTenureFlag, setDefaultTenureFlag] = useState(true);
  const [error, setError] = useState(false);
  const [amountSlider, setAmountSlider] = useState(
    tenureData.LENDER_DETAIL?.OFFER_MAX_AMOUNT || 50000
  );
  const [amountShowSlider, setAmountShowSlider] = useState(
    tenureData.LENDER_DETAIL?.OFFER_MAX_AMOUNT || 50000
  );
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [loanData, setLoanData] = useState([]);
  const { showToast } = useToast();
  const { callDedupe } = useDedupe();
  const dispatch = useDispatch();
  const { isOfferConfirm } = useSelector((state) => state.BackButton);

  useEffect(() => {
    getOfferData();
  }, []);

  // useEffect(() => {
  //   // getLoanDetails(tenureSlider);
  //   getTenureData(tenureSlider);
  // }, []);

  useEffect(() => {
    isOfferConfirm
      ? dispatch(
          setBackButton({
            backButton: true,
          })
        )
      : dispatch(
          setBackButton({
            backButton: false,
          })
        );
  }, [isOfferConfirm]);

  const getLoanDetails = (tenure) => {
    const selectedTenure = tenureData?.LENDER_DETAIL?.TENURE_LIST.find(
      (item) => item.TENURE == tenure
    );

    if (selectedTenure) {
      setLoanData(selectedTenure);
    } else {
      return null;
    }
  };

  const getTenureData = async (tenure) => {
    try {
      setLoading(true);
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          tenure: tenure,
        },
        HEADER: {
          userId: userData?.userId,
          AID: userData?.mobile,
          MID: userData?.mobile,
        },
      };
      let res = await APICall({
        url: Get_Offer_Details_By_Tenure,
        payload: PAYLOAD,
      });

      if (res?.RESP_CODE === 300) {
        setError(false);
        res.DATA?.RECOMMENDED_TENURE != "36" &&
        res.DATA?.RECOMMENDED_TENURE != 36
          ? setHelperText(EN.increase_the_tenure_to_avail_the_max_amount)
          : setHelperText("");

        const selectedTenure = res.DATA?.LENDER_DETAIL?.TENURE_LIST.find(
          (item) => item.TENURE == tenure
        );
        if (selectedTenure) {
          setLoanData(selectedTenure);
        } else {
          return null;
        }
        setTenureData(res.DATA);
        setAmountSlider(res?.DATA?.LENDER_DETAIL?.OFFER_MAX_AMOUNT);
        setAmountShowSlider(res?.DATA?.LENDER_DETAIL?.OFFER_MAX_AMOUNT);
        setAmountLimits({
          min: res?.DATA.LENDER_DETAIL?.OFFER_MIN_AMOUNT,
          max: res?.DATA.LENDER_DETAIL?.OFFER_MAX_AMOUNT,
        });
        let feesData = res?.DATA.CHARGES.find(
          (item) => item?.CHARGE_CODE === "LoanCharge.ProcessingFees"
        );
        let applnData = res?.DATA.CHARGES.find(
          (item) => item?.CHARGE_CODE === "LoanCharge.StampCharge"
        );

        setOtherCharges(
          applnData.CHARGE_AMOUNT
          // + res?.DATA.BROKEN_PERIOD_AMOUNT
        );
        setFees(feesData);
        setLoading(false);
      } else if (res?.RESP_CODE === 302) {
        setError(true);
        setHelperText(EN.please_increase_the_tenure_to_get_an_offer);
        setLoanData({});
        setLoading(false);
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChangeonBlur = (e) => {
    setAmountSlider(e.target.value);
    getOfferData(amountShowSlider);
  };
  const handleChange = (e) => {
    setAmountShowSlider(e.target.value);
  };

  const handleTenureChange = (e) => {
    setTenureShowSlider(e.target.value);
  };
  const handleTenureChangeBlur = (e) => {
    setTenureSlider(e.target.value);
    getTenureData(tenureShowSlider);
  };

  const getOfferData = async (amt = null) => {
    try {
      setLoading(true);
      let PAYLOAD = {
        DATA: amt
          ? {
              lenderCode: AppConstants.lenderCode,
              loanAmount: amt,
            }
          : {
              lenderCode: AppConstants.lenderCode,
            },
        HEADER: {
          userId: userData.userId,
          AID: userData.mobile,
          MID: userData.mobile,
        },
      };

      let res = await APICall({
        url: Get_Sanction_Loan_Calculation,
        payload: PAYLOAD,
      });
      if (res?.RESP_CODE === 300) {
        setTenureData(res.DATA);
        tenureShowSlider != "36" && tenureShowSlider != 36
          ? setHelperText(EN.increase_the_tenure_to_avail_the_max_amount)
          : setHelperText("");

        setAmountLimits({
          min: res?.DATA.LENDER_DETAIL?.OFFER_MIN_AMOUNT,
          max: res?.DATA.LENDER_DETAIL?.OFFER_MAX_AMOUNT,
        });
        let feesData = res?.DATA.CHARGES.find(
          (item) => item?.CHARGE_CODE === "LoanCharge.ProcessingFees"
        );
        let applnData = res?.DATA.CHARGES.find(
          (item) => item?.CHARGE_CODE === "LoanCharge.StampCharge"
        );

        setOtherCharges(
          applnData.CHARGE_AMOUNT
          // + res?.DATA.BROKEN_PERIOD_AMOUNT
        );

        setFees(feesData);
        if (!amt) {
          setAmountSlider(res?.DATA?.LENDER_DETAIL?.OFFER_MAX_AMOUNT);
          setAmountShowSlider(res?.DATA?.LENDER_DETAIL?.OFFER_MAX_AMOUNT);
        }
        const selectedTenure = res.DATA?.LENDER_DETAIL?.TENURE_LIST.find(
          (item) => {
            if (defaultTenureFlag) {
              return item.TENURE == res.DATA?.RECOMMENDED_TENURE;
            } else {
              return item.TENURE == tenureSlider;
            }
          }
        );
        if (defaultTenureFlag) {
          setTenureShowSlider(res.DATA?.RECOMMENDED_TENURE);
          setTenureSlider(res.DATA?.RECOMMENDED_TENURE);
          setDefaultTenureFlag(false);
        }
        if (selectedTenure) {
          setLoanData(selectedTenure);
        } else {
          return null;
        }
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      let PAYLOAD = {
        DATA: {
          userSelectedAmt: amountSlider,
          tenure: tenureSlider,
          lenderCode: AppConstants.lenderCode,
        },
        HEADER: {
          userId: userData.userId,
          AID: userData.mobile,
          MID: userData.mobile,
        },
      };

      let res = await APICall({
        url: Confirm_Sanction_Amount,
        payload: PAYLOAD,
      });
      if (res.RESP_CODE === 300) {
        callDedupe(true);
        setConfirmationModal(false);
        setLoading(false);
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Layout>
      <div className="offer-container">
        <div className="title-container">
          <img src={Medal} />
          <p className="congratulations">{EN.congratulations}</p>
          <p className="sub-head">{EN.you_are_eligible_for_the_loan}</p>
        </div>
        {isOfferConfirm && (
          <div className="expiry-container">
            <HelpIcon />
            <p>{EN.offer_expires_in_48_hours}</p>
          </div>
        )}
        <div style={{ margin: "10px 0" }}>
          <div className="card-amount-container">
            {error ? (
              <p className="reset">{EN.reset_to_max_offer}</p>
            ) : (
              <p className="amount">₹ {tenureData?.SANCTION_AMOUNT}</p>
            )}
            {error ? (
              <p
                style={{ textDecoration: "underline" }}
                onClick={() => window.location.reload()}
              >
                Click Here
              </p>
            ) : (
              <p>
                {tenureData?.SANCTION_AMOUNT ===
                tenureData?.LENDER_DETAIL?.OFFER_MAX_AMOUNT
                  ? EN.max_loan_amount
                  : `${EN.avail_upto} ${tenureData?.LENDER_DETAIL?.OFFER_MAX_AMOUNT}`}
              </p>
            )}
          </div>
          <div className="card-loan-details-container">
            <div>
              <p>{EN.tenure}</p>
              <p> {`${loanData?.TENURE || "0"} ${EN.months}`}</p>
            </div>
            <div>
              <p>Flat Interest(Per Month)</p>
              <p>{`${tenureData?.FLAT_RATE_OF_INTEREST || "0"}%`}</p>
            </div>
            <div>
              <p>{EN.monthly_emi}</p>
              <p>₹{loanData?.INSTALLMENT || "0"}</p>
            </div>

            {isOfferConfirm && (
              <>
                <div>
                  <p>{EN.processing_fee}</p>
                  <p> {`₹${fees?.CHARGE_AMOUNT || "0"}`}</p>
                </div>
                <div>
                  <p>{EN.GST_on_P_F_18}</p>
                  <p>{`₹${fees?.CHARGE_GST || "0"}`}</p>
                </div>
                <div>
                  <p>{EN.other_charges}</p>
                  <p>₹{otherCharges || "0"}</p>
                </div>
                <div>
                  <p>{EN.net_disbursement}</p>
                  <p>₹{tenureData?.DISBURSEMENT_AMOUNT || "0"}</p>
                </div>
              </>
            )}
          </div>
        </div>
        {!isOfferConfirm && (
          <>
            <div className="amount-slider-container">
              <p className="slider-label">{EN.how_much_money_do_you_need}</p>
              <p className="slider-value">
                {AmountFormatter(amountShowSlider)}
              </p>

              <input
                type="range"
                disabled={error}
                value={amountShowSlider}
                onChange={(e) => {
                  !error ? handleChange(e) : console.log("No Available");
                }}
                onTouchEnd={(e) => {
                  !error ? handleChangeonBlur(e) : console.log("No Available");
                }}
                onMouseUp={(e) => {
                  !error ? handleChangeonBlur(e) : console.log("No Available");
                }}
                valueLabelDisplay="auto"
                max={amountLimits.max || 100000}
                min={amountLimits.min || 10000}
                step={500}
                style={{ accentColor: "#161719", width: "100%" }}
              />
            </div>
            <div className="tenure-slider-container">
              <p className="slider-label">
                {EN.do_you_want_to_change_your_tenure}
              </p>
              <p className="slider-value">
                {tenureShowSlider} {EN.months}
              </p>

              <input
                type="range"
                value={tenureShowSlider}
                onChange={handleTenureChange}
                onTouchEnd={handleTenureChangeBlur}
                onMouseUp={handleTenureChangeBlur}
                max={36}
                step={6}
                min={6}
                style={{ accentColor: "#161719", width: "100%" }}
              />
              <p
                style={{
                  color: error ? "#CC0000" : "#134CDE",
                }}
              >
                {helperText || ""}
              </p>
            </div>
          </>
        )}
        <div
          className={
            isOfferConfirm ? "action-button-container" : "button-container"
          }
        >
          {isOfferConfirm ? (
            <Button
              label={EN.Accept_n_Next}
              activeBtn
              onClick={submitHandler}
            />
          ) : (
            <Button
              label={EN.update_n_next}
              activeBtn={!error}
              onClick={() => {
                dispatch(
                  setIsOfferConfirm({
                    isOfferConfirm: true,
                  })
                );
              }}
            />
          )}
        </div>
        <Modal
          open={confirmationModal}
          //   onClose={handleClose}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "75%",
              backgroundColor: "#fff",
              padding: "20px",
              outline: "none",
              borderRadius: "8px",
            }}
          >
            <ModalTitle
              title={EN.back + "?"}
              subtitle={
                EN.are_you_considering_dropping_your_application_No_worries_The_offer_is_still_valid_for_the_next_48_hours_Feel_free_to_come_in_anytime_during_this_period_to_continue_the_process
              }
            />

            <div style={{ display: "flex", gap: "14px" }}>
              <div style={{ width: "100%" }}>
                <Button
                  label={EN.yes}
                  activeBtn={true}
                  // onClick={submitHandler}
                />
              </div>
              <div style={{ width: "100%" }}>
                <Button
                  label={EN.no}
                  activeBtn={true}
                  color="#F1F1F3"
                  textColor="#000"
                  onClick={() => setConfirmationModal(false)}
                />
              </div>
            </div>
          </div>
        </Modal>

        <Modal open={loading}>
          <div />
        </Modal>
      </div>
    </Layout>
  );
};

export default Offer;
