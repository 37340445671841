export const EN = {
  // common
  submit: "Submit",
  verify: "Verify",
  submit_n_next: "Submit & Next",
  update_n_next: "Update & Next",
  Accept_n_Next: "Accept & Next",
  back: "Back",
  reset: "Reset",
  yes: "Yes",
  no: "No",
  cancel: "Cancel",
  change: "Change",
  resend_otp: "Resend OTP",
  please_wait: "Please wait...",
  loan_amount: "Loan Amount",
  processing_fee: "Processing fee",
  GST_on_P_F_18: "GST on P.F (18%)",
  net_disbursement: "Net disbursement",
  we_are_checking_the_verifying_the_information:
    "We are checking the verifying the information.",
  state: "State",
  city: "City",
  proof_of_address: "Proof of address",
  upload: "Upload",
  pincode: "Pincode",
  regret: "Regret!",
  thank_your_for_applying_with_us: "Thank your for applying with us",
  we_have_reviewed_your_loan_application_but_cannot_process_your_request_for_the_loan_as_per_your_eligibility:
    "We have reviewed your loan application but cannot process your request for the loan as per your eligibility.",
  we_cannot_process_your_loan_request_based_on_the_submitted_bank_statement:
    "We cannot process your loan request based on the submitted bank statement",
  please_do_not_press_the_back_button: "Please do not press the back button.",
  page_will_automatically_redirect_in_few_seconds:
    "Page will automatically redirect in few seconds.",
  are_you_sure_you_want_to_go_back: "Are you sure you want to go back",
  we_are_checking_the_submitted_document:
    "We are checking the submitted document.",
  congratulations: "Congratulations!",
  if_the_files_are_password_protected_please_provide_the_password:
    "If the files are password-protected, please provide the password.",
  your_loan_application_submitted_successfully_we_will_update_you_as_soon_as_possible:
    "Your loan application submitted successfully we will update you as soon as possible.",
  generating_best_offer_for_you_it_may_take_few_minutes:
    "Generating best offer for you, it may take few minutes.",
  back_to_home: "Back to home",
  key_fact_statement: "Summary of Key Facts Statement",
  loan_is_in_manual_KYC_verification_Please_wait_for_some_time:
    "Loan is in manual KYC verification. Please wait for some time.",
  the_loan_is_undergoing_manual_KYC_verification_Please_check_back_in_10_to_15_minutes:
    "The loan is undergoing manual KYC verification. Please check back in 10 to 15 minutes",
  check_status: "Check Status",
  tnc_content: `These terms and conditions outline the rules and regulations for the use of Company Name's Website, located at Website.com.
  By accessing this website we assume you accept these terms and conditions. Do not continue to use Website Name if you do not agree to take all of the terms and conditions stated on this page.
  The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: “Client”, “You” and “Your” refers to you, the person log on this website and compliant to the Company's terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.
  Cookies
  We employ the use of cookies. By accessing Website Name, you agreed to use cookies in agreement with the Company Name's Privacy Policy.
  Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
  License
  Unless otherwise stated, Company Name and/or its licensors own the intellectual property rights for all material on Website Name. All intellectual property rights are reserved. You may access this from Website Name for your own personal use subjected to restrictions set in these terms and conditions.`,
  we_are_collecting_your_details_please_hang_in_there:
    "We are collecting your details, please hang in there",
  almost_there_please_hang_in_there: "Almost there, please hang in there",
  your_query_is_still_not_resolved_Please_click_here_to_reachout_to_our_customer_support:
    "Your query is still not resolved? Please click here to reachout to our customer support",
  your_e_sign_process_has_been_initiated_please_complete_the_same_to_submit_your_application_Click_on_verify_and_complete_the_rest_of_the_process_to_successfully_submit_your_application:
    "Your e-sign process has been initiated, please complete the same to submit your application. Click on verify and complete the rest of the process to successfully submit your application",
  other_charges: "Other charges(Stamp, Doc charges)",
  permission_descn1: `To complete your loan application journey, please provide us with the following access:`,
  permission_descn2: `The camera access will be used to verify your Selfie and the location is taken to record your coordinates while you are performing this journey from a compliance point of view`,
  provide_access: "Provide Access",
  view_files_descn: `If you are redirected here, due to signing failure. Please tick the checkbox again and click on verify to proceed. If you are still facing issue, contact us with the help of "i" button.`,
  //Dashboard
  dashboard: "Dashboard",
  hi: "Hi",
  my_profile: "My profile",
  my_loans: "My loans",

  //My Profile
  my_profile: "My profile",
  change: "Change",
  name: "Name",
  phone: "Phone",
  email: "Email",
  pan: "PAN",
  address: "Address",

  //Contact Us
  contact_us: "Contact us",
  call_us: "Call us",
  email: "Email",

  //FAQs
  faqs: "FAQs",
  general_faqs: "General FAQs",
  bank_statement_faqs: "Bank Statement FAQs",
  udyam_upload: "Udyam Upload",
  additional_docs: "Additional Docs",

  //Loan Lending
  basic_details: "Basic details",
  enter_PAN_email_udyam_number_and_pincode:
    "Enter PAN, email, udyam number and pincode.",
  kyc_verification: "KYC verification",
  digital_e_KYC_with_aadhaar_n_pan_authentication:
    "Digital e-KYC with aadhaar & pan authentication.",
  selfie_verification: "Selfie verification",
  authenticate_your_face_with_your_respective_documents:
    "Authenticate your face with your respective documents.",
  Financial_n_other_documents: "Financial & other documents",
  six_months_bank_statement_PAN_Aadhaar_Front_n_Back:
    "6 months bank statement, PAN, Aadhaar Front & Back",
  loan_offer_acceptance_n_signing: "Loan offer acceptance & signing",
  we_utilise_this_information_to_evaluate_your_finances_NaCH_n_LBA_signing_to_be_done_here:
    "We utilise this information to evaluate your finances. NaCH & LBA signing to be done here",

  //My Loans
  my_loans: "My loans",
  active: "Active",
  closed: "Closed",
  msme_business_loan: "MSME Business Loan",
  loan: "Loan",
  utrno: "UTR No",

  interest_rate: "Interest Rate",
  tenure: "Tenure",
  months: "months",
  p_a: "p.a",
  upload_additional_document: "Upload Additional Document (Optional)",
  not_found: "Not found",
  right_now_you_dont_have_any_active_loan_in_your_account:
    "Right now you don't have any active loan in your account",
  right_now_you_dont_have_any_closed_loan_in_your_account:
    "Right now you don't have any closed loan in your account",
  additional_documents: "Additional Documents",
  for_loan_disbursement_were_using_the_bank_information_provided_here: `For loan disbursement, we're using the bank information provided here`,
  document_1: "Document 1",
  document_2: "Document 2",
  document_3: "Document 3",
  optional: "optional",

  //Details
  enter_your_details: "Enter your personal details",
  please_enter_valid_personal_PAN_number:
    "Please enter valid personal PAN number",
  please_enter_valid_mobile_number: "Please enter valid mobile no.",
  we_will_use_this_information_to_perform_checks_n_validation_to_process_your_loan:
    "We will use this information to perform checks & validation to process your loan",
  pan_number: "PAN number",
  monthly_income: "Monthly Income",
  father_or_spouse_full_name: "Father or Spouse Full Name",
  udyam_Registration_Number: "Udyam registration number",
  udyam_Number: "Udyam Number",
  bussiness_name: "Business Name",
  business_address: "Business Address",
  business_vintage_as_per_date_of_incorporation:
    "Business Vintage as per date of incorporation",
  please_enter_valid_Udyam_registration_number:
    "Please enter valid Udyam registration number",
  as_per_your_udyam_Registration_Certificate:
    "As per your Udyam Certificate (format: UDYAM-XY-07-1234567)",
  please_provide_valid_email: "Please provide valid email",
  email_address: "Email Address",
  secondary_mobile_number: "Mobile Number",
  should_be_an_active_and_accessible_account:
    "Should be an active and accessible account",
  as_per_your_aadhaar_card: "As per your Aadhaar card",
  i_accept: "I accept",
  terms_n_conditions: "terms & conditions",
  Terms_n_conditions: "Terms & conditions",
  i_consent_to_a_pan_verification_against_submitted_kyc_information_for_credit_and_risk_evaluation:
    "I consent to a PAN Verification against submitted KYC information for credit and risk evaluation",
  are_you_sure_you_want_to_go_back_this_will_reset_your_inputs:
    "Are you sure you want to go back, this will reset your inputs.",
  dont_have_Udyam_Click_here_to_get_help:
    "Don't have one or forgot it? Click here to register",
  monthly_income_helper:
    "Monthly income must be at least 25,000 to be eligible for a loan",

  //verify aadhaar
  please_provide_the_complete_address_to_proceed_further:
    "Please provide the complete address to proceed further",
  we_will_use_this_information_to_perform_checks_n_validation_to_process_your_loan:
    "We will use this information to perform checks & validation to process your loan",
  verify_your_aadhaar: "Verify your aadhaar",
  verify_your_current_address: "Verify your current address",
  aadhaar_number: "Aadhaar number",
  i_consent_to_an_OTP_based_Aadhaar_validation_for_the_loan_request:
    "I consent to an OTP based Aadhaar validation for the loan request",
  aadhaar_verification: "Aadhaar Verification",
  please_enter_6_digit_OTP_sent_to_your_Aadhaar_linked_mobile_number:
    "Please enter 6 digit OTP sent to your Aadhaar linked mobile number.",
  resend_in_56_sec: "Resend in 56 sec",
  aadhaar_verified: "Aadhaar verified",
  confirm_address: "Confirm current address",
  is_your_current_address_same_as_aadhaar_registered_address:
    "Is your current address same as aadhaar registered address?",
  address: "Address",
  we_utilise_this_information_to_evaluate_your_credit_worthiness:
    "We utilise this information to evaluate your credit worthiness.",
  enter_current_address: "Enter current address",
  address_line_1: "Address line 1",
  address_line_2: "Address line 2",
  rent_agreement_utility_bills_like_electricity_gas_wifi_telephone_bills:
    "Rent Agreement / Utility Bills like Electricity / Gas / Wifi / Telephone bills",

  //verify profile
  verify_your_profile: "Verify your profile",
  take_a_selfie_to_verify_your_profile_Dont_worry_your_selfie_is_safe_with_us:
    "Take a selfie to verify your profile. Don’t worry your selfie is safe with us.",
  ensure_your_face_is_within_the_frame_and_it_is_visible:
    "Ensure your face is within the frame and it is visible.",
  please_ensure_you_are_in_a_well_lit_place:
    "Please ensure you are in a well lit place.",
  dont_wear_hat_glasses_or_any_other_accessories_which_blocks_clarity_of_your_face: `Don't wear hat, glasses or any other accessories which blocks clarity of your face`,
  selfie_n_Liveliness_test: "Selfie & Liveliness Test",
  take_a_selfie_to_verify_your_profile_Dont_worry_your_selfie_is_safe_with_us:
    "Take a selfie to verify your profile. Don’t worry your selfie is safe with us.",
  selfie_verified: "Selfie verified",

  //Upload documents
  upload_documents: "Upload documents",
  please_upload_your_bank_statement_with_higher_transaction_preferably_the_account_using_which_you_pay_other_EMIs:
    "Please upload your bank statement with high number of transactions (Primary account). Preferably this account should have been used to pay other EMIs.",
  bank_statement_6_months: "Bank Statement (6 months)",
  original_and_PDF_only_upto_50MB: "Original and PDF only upto 50MB",

  enter_file_password: "Enter file password",
  original_and_PDF_only_up_to_5MB: "Original and PDF only up to 5MB ",
  latest_6_months_complete_statement_i_e_if_you_are_applying_for_loan_before_15th_of_the_month_then_provide_us_the_last_6_months_BS_else_in_addition_to_the_last_6_months_BS_provide_the_statement_for_the_additional_days_if_applying_after_15th_of_the_month:
    "Latest 6 months complete statement, i.e if you are applying for loan before 10th of the month then provide us the last 6 months complete bank statement. If you are applying after 10th of the month, please provide bank statement for the extra days of the current month, in addtion to the 6 month complete bank statement.",
  upload_the_Net_banking_statement: "Upload the Net banking statement",
  do_not_upload_bank_statement_of_multiple_banks:
    "Do not upload bank statement of multiple banks.",
  bank_statement_verified: "Bank statement verified",
  if_you_have_multiple_statements_for_the_6_months_period__use_the_Upload_additional_documents_button_to_upload_them_one_by_one:
    'If you have multiple statements for the 6 months period , use the "Upload additional documents" button to upload them one by one.',
  you_are_eligible_for_the_loan: "You are eligible for the Loan !",
  max_loan_amount: "Max Loan amount",
  please_increase_the_tenure_to_get_an_offer:
    "Please increase the tenure to get an offer",
  avail_upto: "Avail upto",
  increase_the_tenure_to_avail_the_max_amount:
    "Increase the tenure to avail the max amount",
  reset_to_max_offer: "Reset to max offer",
  tenure: "Tenure",
  interest_rate: "Interest rate",
  monthly_emi: "Monthly EMI",
  how_much_money_do_you_need: "How much money do you need?",
  do_you_want_to_change_your_tenure: "Do you want to change your tenure?",
  scanned_copy_not_allowed: "Scanned copy not allowed",
  please_enter_correct_password: "Please enter correct password.",
  please_use_a_better_Bank_statement_to_get_an_updated_offer:
    "Please use a better Bank statement to get an updated offer.",

  we_are_getting_your_offer_ready: "We are getting your offer ready",
  offer_expires_in_48_hours: "Offer expires in 48 hours.",
  are_you_considering_dropping_your_application_No_worries_The_offer_is_still_valid_for_the_next_48_hours_Feel_free_to_come_in_anytime_during_this_period_to_continue_the_process:
    "Are you considering dropping your application? No worries! The offer is still valid for the next 48 hours. Feel free to come in anytime during this period to continue the process.",
  upload_udyam_certificate: "Upload Udyam Certificate",
  please_upload_the_4_pager_Udyam_Click_here_to_know_how_to_download_4_pager_Udyam:
    "Please upload the 4 pager Udyam (PDF Only) Click here to know how to download 4 pager Udyam",
  Dual_Name_Declaration: "Dual Name Declaration",
  NOCs: "NOCs",
  Affidavit_Declaration_Others: "Affidavit/Declaration/Others",
  Udyam_Registration_Certificate: "Udyam Registration Certificate",
  other_1: "Other 1",
  other_2: "Other 2",
  //Upload KYC documents
  upload_kyc_documents: "Upload KYC documents",
  upload_additional_bank_statements: "Upload additional bank statement(s)",
  we_utilise_this_information_to_evaluate_your_credit_worthiness:
    "We utilise this information to evaluate your credit worthiness.",
  pan_card: "PAN card",
  aadhaar_card_front: "Aadhaar card front",
  aadhaar_card_back: "Aadhaar card back",
  udyam_certificate: "Udyam certificate",
  we_need_this_document_for_performing_the_KYC_checks:
    "We need these documents for performing the KYC checks",
  file_size_is_larger_than_5MB: "File size is larger than 5MB.",

  //Enter bank details
  enter_your_bank_details: "Enter your bank details",
  we_need_to_verify_this_account_for_your_loan_disbursement_and_EMIs:
    "We need to verify this account for your loan disbursement and EMIs.. The beneficiary account name , bank statement name and PAN/Business name should match",
  bank_ifsc: "Bank IFSC",
  bank_name: "Bank name",
  bank_A_C_no: "Bank A/C no.",
  confirm_bank_A_C_no: "Confirm bank A/C no.",
  account_type: "Account type",
  account_holder_name: "Account holder name",
  we_are_verifying_the_submitted_bank_details:
    "We are verifying the submitted bank details.",
  name_mismatch: "Name mismatch.",
  penny_drop_failed: "Penny drop failed.",
  please_correct_your_name: "Please correct your name.",
  bank_statement_under_review_please_hang_in_there:
    "Bank Statement under review, please hang in there",
  bureau_is_under_review_please_hang_in_there:
    "Bureau is under review, please hang in there",
  we_are_alomost_there_with_your_offer: "We are almost there with your offer",

  //Setup NACH
  setup_NACH: "Setup NACH",
  we_will_deduct_your_EMIs_from_this_account_heathy_balance_has_to_be_maintained_and_it_should_be_your_own_account:
    "We will deduct your EMIs from this account, heathy balance has to be maintained and it should be your own account.",
  your_NaCH_mandate_has_been_initiated_this_may_take_some_time_Please_reach_out_to_the_POCs_to_get_this_checked_if_it_has_taken_more_than_2_days: `Your NACH mandate has been initiated. This process may take some time. For further assistance, please reach out to our customer support by clicking on the "i" button located in the top right corner.`,

  //Create E-Mandate
  create_E_Mandate: "Create E-Mandate",
  title: "Title",
  mobile_number: "Mobile number",
  telephone_number: "Telephone number",
  email_id: "Email ID",
  mandate_details: "Mandate details",
  bank: "Bank",
  amount: "Amount",
  fixed_amount: "Fixed amount",
  maximum_amount: "Maximum amount",
  start_date: "Start date",
  end_date: "End date",
  until_cancelled: "Until cancelled",
  frequency: "Frequency",
  monthly: "Monthly",
  purpose_of_mandate: "Purpose of mandate",
  authentication_mode: "Authentication mode",
  i_hereby_authorize_ARTHMATE_FINANCE_to_debit_my_account_as_per_the_mention_mandate_and_bank_account_details_I_understand_that_the_bank_where_I_have_authorized_the_debit_may_levy_mandate_processing_charges_as_mentioned_in_the_banks_latest_schedule_of_charges:
    "I hereby authorize ARTHMATE FINANCE to debit my account, as per the mention mandate and bank account details. I understand that the bank where I have authorized the debit may levy mandate processing charges as mentioned in the bank’s latest schedule of charges.",
  e_mandate_Powered_by: "E-Mandate Powered by",
  sanction_letter: "Sanction Letter",
  kfs_and_sanction_letter: "KFS and Sanction Letter",
  lender_borrower_agreement: "Lender Borrower Agreement",

  //Increase loan amount
  increase_loan_amount: "Increase loan amount",
  upload_a_better_bank_statement_to_increase_the_loan_amount:
    "Upload a better bank statement to increase the loan amount.",
  past: "Past",

  //Permission
  we_need_your_permission: "We need your permission",
};
