import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APICall } from "../../api/APICall";
import {
  Create_User_Enquiry,
  Create_User_Enquiry_And_lead,
  Save_User_Consent,
  Validate_Pan_Create_Lead,
} from "../../api/APIs";
import BackButton from "../../assets/BackButton.svg";
import BasicDetails from "../../assets/BasicDetails.svg";
import DocumentStep from "../../assets/DocumentStep.svg";
import KYCStep from "../../assets/KYCStep.svg";
import LendingSvg from "../../assets/Lending.svg";
import SelfieStep from "../../assets/SelfieStep.svg";
import SigningStep from "../../assets/SigningStep.svg";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import { AppConstants } from "../../constants/AppConstants";
import "./LoanLending.scss";
import { EN } from "../../constants/Strings/EN";
import useDedupe from "../../hooks/useDedupe";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import useToast from "../../hooks/useToast";
import PermissionModal from "../../components/PermissionModal/PermissionModal";
import { setBackButton } from "../../redux/actions";
import axios from "axios";
import DocumentModal from "../../components/DocumentModal/DocumentModal";

function LoanLending() {
  let userData = JSON.parse(localStorage.getItem("userData"));

  const { callDedupe } = useDedupe();
  const constraint = { video: true, audio: false };
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let loc = JSON.parse(localStorage.getItem("userData"));
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const [isCameraPermission, setIsCameraPermission] = useState(false);
  const [isLocationPermission, setisLocationPermission] = useState(false);
  const [networkData, setNetworkData] = useState({});
  const [userId, setUserId] = useState("");
  const [tnc, setTnc] = useState(false);
  const [panConsent, setPanConsent] = useState(false);
  const [TncModalVisible, setTncModalVisible] = useState(false);

  useEffect(() => {
    tnc && tncHandler();
  }, [tnc]);
  useEffect(() => {
    panConsent && panConsentHandler();
  }, [panConsent]);

  useEffect(() => {
    getLocation();
    dispatch(
      setBackButton({
        backButton: false,
      })
    );
    // setOpenModal(true);
  }, []);

  useEffect(() => {
    isCameraPermission && isLocationPermission && verifyPan();
  }, [isCameraPermission, isLocationPermission]);

  const steps = [
    {
      id: 1,
      title: EN.basic_details,
      subtitle: EN.enter_PAN_email_udyam_number_and_pincode,
      img: BasicDetails,
    },
    {
      id: 2,
      title: EN.kyc_verification,
      subtitle: EN.digital_e_KYC_with_aadhaar_n_pan_authentication,
      img: KYCStep,
    },
    {
      id: 3,
      title: EN.selfie_verification,
      subtitle: EN.authenticate_your_face_with_your_respective_documents,
      img: SelfieStep,
    },
    {
      id: 4,
      title: EN.Financial_n_other_documents,
      subtitle: EN.six_months_bank_statement_PAN_Aadhaar_Front_n_Back,
      img: DocumentStep,
    },
    {
      id: 5,
      title: EN.loan_offer_acceptance_n_signing,
      subtitle:
        EN.we_utilise_this_information_to_evaluate_your_finances_NaCH_n_LBA_signing_to_be_done_here,
      img: SigningStep,
    },
  ];

  const startApplicationHandler = async () => {
    try {
      setLoading(true);
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants?.lenderCode,
          applicationName: loc?.applicationName,
          pan: loc?.pan,
          supplierId: loc?.supplierId,
          userLoanProcessingFeePercentage: loc?.userLoanProcessingFeePercentage,
          secondaryMobile: loc?.secondaryMobile,
          ifscCode: loc?.bankIfsc || "",
          accountNumber: loc?.bankAccountNumber || "",
          bankName: loc?.bankName || "",
          email: loc?.email || "",
        },

        HEADER: {
          AID: loc?.mobile,
          MID: loc?.mobile,
        },
      };

      let res = await APICall({
        url: Create_User_Enquiry_And_lead,
        payload: PAYLOAD,
      });
      if (res?.RESP_CODE === 300) {
        setUserId(res.DATA?.userId);
        setOpenModal(true);
        setLoading(false);
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const verifyPan = async () => {
    try {
      setLoading(true);
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          latitude: networkData.lat,
          longitude: networkData.long,
          ipAddress: networkData.ip,
          secondaryMobile: loc?.secondaryMobile,
        },
        HEADER: {
          AID: loc?.mobile,
          MID: loc?.mobile,
          userId: userId,
        },
      };
      let res = await APICall({
        url: Validate_Pan_Create_Lead,
        payload: PAYLOAD,
      });
      if (res.RESP_CODE === 300) {
        callDedupe(true);
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const cameraPermission = () => {
    navigator.mediaDevices
      .getUserMedia(constraint)
      .then((mediaStream) => {
        setIsCameraPermission(true);
      })
      .catch((e) => {
        setIsCameraPermission(false);
      });
  };

  const getLocation = async () => {
    try {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            setNetworkData({
              lat: position?.coords?.latitude,
              long: position?.coords?.longitude,
            });
          },
          function (e) {
            showToast(`Location Err ${e.message}`, "error", 3000);
          },
          {
            timeout: 5000,
            enableHighAccuracy: true,
          }
        );
      } else {
      }

      const res = await axios.get("https://api.ipify.org/?format=json");
      if (res?.data.ip)
        setNetworkData((prev) => ({ ...prev, ip: res?.data?.ip }));
    } catch (error) {}
  };

  const locationPermission = async () => {
    if (navigator.geolocation) {
      const res = await navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
            setisLocationPermission(true);
            cameraPermission();
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition((position) => {
              setisLocationPermission(true);
              cameraPermission();
            });
          } else if (result.state === "denied") {
            setisLocationPermission(false);
            alert("Please allow permission from setting");
          }
        });
    }
  };

  const tncHandler = async () => {
    try {
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          consentText: "I accept all terms & condition.",
          consentType: "ConsentType.SummaryInfo",
        },
        HEADER: {
          AID: userData?.mobile,
          userId: userData?.userId,
        },
      };
      let res = await APICall({
        url: Save_User_Consent,
        payload: PAYLOAD,
      });
      if (res?.RESP_CODE === 300) {
      } else showToast(res?.RESP_MSG, "error", 3000);
    } catch (e) {}
  };

  const panConsentHandler = async () => {
    try {
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          consentText:
            EN.i_consent_to_a_pan_verification_against_submitted_kyc_information_for_credit_and_risk_evaluation +
            ".",
          consentType: "ConsentType.CreateLead",
        },
        HEADER: {
          AID: userData?.mobile,
          userId: userData?.userId,
        },
      };

      let res = await APICall({
        url: Save_User_Consent,
        payload: PAYLOAD,
      });
      if (res?.RESP_CODE === 300) {
      } else showToast(res?.RESP_MSG, "error", 3000);
    } catch (e) {}
  };

  const RenderTnc = () => {
    return (
      <div className="tnc-content-container">
        <b>T&Cs</b>
        <div>
          <p>
            I hereby declare that I am an Indian National. I agree to Arthmate
            Financing India Private Limited (AFIPL) privacy policy, grievance
            redressal policy and authorize AFIPL to carry out credit enquiries
            on the Credit Bureaus. I give my consent to one or more lending
            partners of AFIPL to make one or multiple credit enquiries with the
            credit bureaus or any other agencies authorized by RBI. I also
            authorize AFIPL to retrieve credit report on my behalf from CRIF
            Highmark Private Limited or Experian India Private Limited or
            Transunion CIBIL Limited.
          </p>
          <p>
            By clicking on verify, you consent to receiving communications
            including but not limited to SMS, e-mails, phone calls, whatsapp
            from us and/or our partners, including Banks and NBFCs, with respect
            to your transaction on the website or for any other purpose. This
            consent will override any registration for DNC/NDNC. You hereby
            consent to AFIPL being appointed as your authorized representative
            to receive your Credit Information from CIBIL and sharing it with
            its affiliate financial partners(Specified Users under CICRA, 2005)
            for the purpose of providing curated products and sharing the Credit
            Information with such affiliate financial partners. ("End User
            Purpose")
          </p>
          <p>
            In this program, we are offering loans with an interest rate of up
            to 35%
          </p>
        </div>

        <b>Privacy Policy</b>
        <div>
          <p>
            Link -{" "}
            <a href="https://www.arthmate.com/helpTopic" target="_blank">
              https://www.arthmate.com/helpTopic
            </a>
          </p>
          <p>
            Please read through the “privacy policy” section. The same policy
            governs the usage/interaction with our web app
          </p>
        </div>

        <b>Grievance Redressal</b>
        <div>
          <p>
            Policy Link -{" "}
            <a href="https://www.arthmate.com/helpTopic" target="_blank">
              https://www.arthmate.com/helpTopic
            </a>
          </p>
          <p>
            Please read through the “grievance redressal policy” section. The
            same policy governs the usage/interaction with our web app
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="lending-container">
      <div className="arrow-container">
        {/* <img src={BackButton} onClick={() => navigate(-1)} /> */}
      </div>

      <div className="lending-body">
        <div className="header-container">
          <div className="title-container">
            <div className="content-container">
              <span className="content-title">
                Get a loan upto ₹5 Lakh in just 5 simple steps.
              </span>
            </div>
            <div className="header-img">
              <img className="lending-img" src={LendingSvg} />
            </div>
          </div>
        </div>

        <div className="steps-journey-container">
          <div style={{ margin: "24px 0" }}>
            {steps.map((step, index) => {
              return (
                <div key={step.id.toString()} className="step-row">
                  <div className="step-profile">
                    <div className="step-profile-bg">
                      <img src={step?.img} />
                    </div>

                    {index !== 4 && <div className="vl" />}
                  </div>
                  <div className="step-content">
                    <p>{step.title}</p>
                    <p>{step.subtitle}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="footer-container">
            <div className="consent-container">
              <input
                type="checkbox"
                checked={tnc}
                onChange={(e) => setTnc(true)}
              />
              <span>
                {EN.i_accept}{" "}
                <span onClick={() => setTncModalVisible(true)}>
                  {EN.terms_n_conditions}
                </span>
                .
              </span>
            </div>
            <div className="consent-container">
              <input
                type="checkbox"
                checked={panConsent}
                onChange={(e) => setPanConsent(true)}
              />
              <span>
                {
                  EN.i_consent_to_a_pan_verification_against_submitted_kyc_information_for_credit_and_risk_evaluation
                }
              </span>
            </div>
            <Button
              label={"Start application"}
              activeBtn={panConsent && tnc}
              onClick={() => {
                startApplicationHandler();
                // setOpenModal(true);
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
      <DocumentModal
        isOpen={TncModalVisible}
        setIsOpen={setTncModalVisible}
        label={EN.Terms_n_conditions}
        viewOnly
        content={<RenderTnc />}
      />
      <LoadingModal isOpen={loading} setIsopen={setLoading} />

      {true && (
        <PermissionModal
          openModal={true}
          setOpenModal={setOpenModal}
          setisLocationPermission={setisLocationPermission}
          setIsCameraPermission={setIsCameraPermission}
          // setOpenEnquiryModal={setOpenEnquiryModal}
        />
      )}
      {/* {openEnquiryModal && (
        <UserEnquiry
          openEnquiryModal={openEnquiryModal}
          setOpenEnquiryModal={setOpenEnquiryModal}
        />
      )} */}
    </div>
  );
}

export default LoanLending;
