import { useEffect, useState } from "react";

const useTimer = (initialTime = 60, onComplete, onStart) => {
  const [time, setTime] = useState(initialTime);
  const [isActive, setIsActive] = useState(false);

  const startTimer = () => {
    setIsActive(true);
    onStart && onStart();
  };

  const stopTimer = () => {
    setIsActive(false);
  };

  const resetTimer = () => {
    setTime(initialTime);
    setIsActive(false);
  };

  useEffect(() => {
    let interval;
    if (isActive && time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time === 0) {
      setIsActive(false);
      onComplete && onComplete();
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  return { time, startTimer, stopTimer, resetTimer, isActive };
};

export default useTimer;
