import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react18-input-otp";
import { APICall } from "../../api/APICall";
import {
  Generate_Aadhaar_OTP,
  Save_User_Consent,
  Update_Residence_Details,
  Validate_Aadhaar_Detail,
} from "../../api/APIs";
import Button from "../../components/Button/Button";
import InputField from "../../components/InputField/InputField";
import Label from "../../components/Label/Label";
import Layout from "../../components/Layout/Layout";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import ModalTitle from "../../components/ModalTitle/ModalTitle";
import Title from "../../components/Title/Title";
import { AppConstants } from "../../constants/AppConstants";
import { EN } from "../../constants/Strings/EN";
import useDedupe from "../../hooks/useDedupe";
import useTimer from "../../hooks/useTimer";
import useToast from "../../hooks/useToast";
import {
  setBackButton,
  setOpenAddressModelOnBack,
  setStep,
} from "../../redux/actions";
import "./Aadhaar.scss";
import VerifiedAadhaar from "./VerifiedAadhaar";

const Aadhaar = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOTP] = useState("");
  const { backButton, openAddressModelOnBack } = useSelector(
    (state) => state.BackButton
  );
  const [aadhaarNum, setAadhaarNum] = useState("");
  const [requestId, setRequestId] = useState("");
  const [aadhaarConsent, setAadhaarConsent] = useState(false);
  const [adhaarVerified, setAdhaarVerified] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [newAddress, setNewAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const { callDedupe } = useDedupe();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setStep({
        step: 2,
      })
    );
    verifyDedupe();
    // callDedupe();
    // if (userData?.currentStage === "KYC_AADHAAR") {
    //   setAadhaarNum(userData?.maskedAadhar);
    //   setAdhaarVerified(true);
    //   setAddressModal(true);
    //   // setNewAddress(true);
    // }
    dispatch(
      setBackButton({
        backButton: false,
      })
    );
    dispatch(
      setOpenAddressModelOnBack({
        openAddressModelOnBack: false,
      })
    );
  }, []);

  useEffect(() => {
    if (openAddressModelOnBack) {
      setNewAddress(false);
      setAddressModal(true);
    }
  }, [openAddressModelOnBack]);

  useEffect(() => {
    aadhaarConsent && aadhaarConsentHandler();
  }, [aadhaarConsent]);

  //OTP Auto submit
  // useEffect(() => {
  //   otp.length === 6 && otpSubmitHandler();
  // }, [otp]);

  const verifyDedupe = async () => {
    callDedupe();
    let userData = JSON.parse(localStorage.getItem("userData"));
    if (userData?.currentStage === "KYC_AADHAAR") {
      setAadhaarNum(userData?.maskedAadhar);
      setAdhaarVerified(true);
      setAddressModal(true);
      // setNewAddress(true);
    }
  };

  const onTimerComplete = () => {};
  const { time, startTimer, stopTimer, resetTimer } = useTimer(
    60,
    onTimerComplete
  );

  const handleInputChange = (event) => {
    setOTP(event);
  };

  const generateOTP = async () => {
    setLoading(true);
    try {
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          aadhaarNumber: aadhaarNum,
        },
        HEADER: {
          AID: userData.mobile,
          MID: userData.mobile,
          userId: userData.userId,
        },
      };
      let res = await APICall({ url: Generate_Aadhaar_OTP, payload: PAYLOAD });

      if (res.RESP_CODE === 300) {
        setRequestId(res.DATA.requestId);
        setIsModalOpen(true);
        startTimer();
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const otpSubmitHandler = async () => {
    resetTimer();
    startTimer();
    setLoading(true);
    try {
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          aadhaarNumber: aadhaarNum,
          requestId: requestId,
          otp: otp,
        },
        HEADER: {
          AID: userData.mobile,
          MID: userData.mobile,
          userId: userData.userId,
        },
      };

      let res = await APICall({
        url: Validate_Aadhaar_Detail,
        payload: PAYLOAD,
      });
      if (res.RESP_CODE === 300) {
        setAddressModal(true);
        setIsModalOpen(false);
        // callDedupe();
        // let localData = JSON.parse(localStorage.getItem("userData"));
        verifyDedupe();
        // setAadhaarNum(localData?.maskedAadhar);
        setAdhaarVerified(true);
      } else {
        setOTP("");
        showToast(res?.RESP_MSG, "error", 3000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const confirmAddressHandler = async () => {
    setLoading(true);
    try {
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          address: {
            addressTypeCode: "AddressType.Current",
            addressLine1: userData.addressLine1,
            addressLine2: userData.addressLine2,
            city: userData.city,
            state: userData.state,
            pincode: userData.pincode,
          },
        },
        HEADER: {
          AID: userData.mobile,
          MID: userData.mobile,
          userId: userData.userId,
        },
      };

      let res = await APICall({
        url: Update_Residence_Details,
        payload: PAYLOAD,
      });
      if (res.RESP_CODE === 300) {
        callDedupe(true);
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const aadhaarConsentHandler = async () => {
    try {
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          consentText:
            EN.i_consent_to_an_OTP_based_Aadhaar_validation_for_the_loan_request,
          consentType: "ConsentType.Aadhaar",
        },
        HEADER: {
          AID: userData?.mobile,
          userId: userData?.userId,
        },
      };
      let res = await APICall({
        url: Save_User_Consent,
        payload: PAYLOAD,
      });
      if (res?.RESP_CODE === 300) {
      } else showToast(res?.RESP_MSG, "error", 3000);
    } catch (e) {}
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "100%",
    width: "65%",
    bgcolor: "#fff",
    p: 6,
    outline: "none",
    borderRadius: "8px",
  };

  return (
    <Layout>
      <div>
        <Title
          title={
            newAddress ? EN.verify_your_current_address : EN.verify_your_aadhaar
          }
          subTitle={
            EN.we_will_use_this_information_to_perform_checks_n_validation_to_process_your_loan
          }
        />
        <div className="input-container">
          <InputField
            label={EN.aadhaar_number}
            maxLength={14}
            value={aadhaarNum.replace(/(.{4})/g, "$1 ").trim()}
            disabled={adhaarVerified}
            tick={adhaarVerified}
            // onChange={(e) => setAadhaarNum(e.target.value)}
            onChange={(e) => setAadhaarNum(e.target.value.replace(/\s/g, ""))}
          />
        </div>
        {newAddress ? (
          <VerifiedAadhaar
            setNewAddress={setNewAddress}
            newAddress={newAddress}
            addressModal={addressModal}
            setAddressModal={setAddressModal}
          />
        ) : (
          <div
            style={{
              position: "sticky",
              bottom: "5vh",
              top: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="consent-container">
              <input
                type="checkbox"
                checked={aadhaarConsent}
                onChange={(e) => setAadhaarConsent(true)}
              />
              <span>
                {
                  EN.i_consent_to_an_OTP_based_Aadhaar_validation_for_the_loan_request
                }
              </span>
            </div>
            <Button
              label={EN.verify}
              activeBtn={
                aadhaarConsent && aadhaarNum?.length == 12 ? true : false
              }
              onClick={() => {
                aadhaarConsent && aadhaarNum?.length == 12 && generateOTP();
              }}
            />
          </div>
        )}
        <Modal
          open={addressModal}
          //   onClose={handleClose}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "75%",
              backgroundColor: "#fff",
              padding: "20px",
              outline: "none",
              borderRadius: "8px",
            }}
          >
            <ModalTitle
              title={EN.confirm_address}
              subtitle={
                EN.is_your_current_address_same_as_aadhaar_registered_address
              }
            />
            <div style={{ margin: "0 0 14px 0" }}>
              <Label label={EN.address} />
              <p
                style={{
                  fontSize: "16px",
                  color: "#767888",
                  fontWeight: "400",
                }}
              >
                {`${userData?.addressLine1 ? userData?.addressLine1 + ", " : ""}
                  ${
                    userData?.addressLine2 ? userData?.addressLine2 + ", " : ""
                  } 
                  ${userData?.city ? userData?.city + ", " : ""} 
                  ${userData?.state ? userData?.state + " - " : ""} 
                  ${userData?.pincode ? userData?.pincode : ""}`}
              </p>
            </div>
            <div style={{ display: "flex", gap: "14px" }}>
              <div style={{ width: "100%" }}>
                <Button
                  label={EN.no}
                  onClick={() => {
                    let result =
                      aadhaarNum
                        .substring(0, aadhaarNum.length - 4)
                        .replace(/./g, "X") +
                      aadhaarNum.substring(aadhaarNum.length - 4);
                    setAadhaarNum(result);
                    setNewAddress(true);
                    setAddressModal(false);
                    // dispatch(setVerifyAddress({
                    //   verifyAddress: true
                    // }))
                  }}
                  activeBtn={true}
                  color="#F1F1F3"
                  textColor="#000"
                />
              </div>
              <div style={{ width: "100%" }}>
                <Button
                  label={EN.yes}
                  onClick={confirmAddressHandler}
                  activeBtn={true}
                />
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={isModalOpen}
          //   onClose={handleClose}
        >
          <Box sx={style}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  color: "#000",
                  fontWeight: "600",
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                {EN.aadhaar_verification}
              </label>
              <label
                style={{
                  color: "#767888",
                  fontWeight: "500",
                  fontSize: "16px",
                  textAlign: "center",
                  margin: "10px 0px",
                }}
              >
                {
                  EN.please_enter_6_digit_OTP_sent_to_your_Aadhaar_linked_mobile_number
                }
              </label>
            </div>

            <OtpInput
              value={otp}
              numInputs={6}
              isInputNum
              separator={<span>&nbsp;</span>}
              containerStyle={{ justifyContent: "center" }}
              inputStyle={{
                // borderColor: "#CCCDD3",
                borderWidth: "1px",
                height: 32,
                width: 32,
                borderRadius: "8px",
              }}
              // isInputSecure
              onChange={handleInputChange}
            />
            <div
              style={{
                margin: "10px 0px",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                }}
                onClick={() => {
                  time === 0 && generateOTP();
                }}
              >
                {EN.resend_otp}
                {time != 0 ? " in " + time : null}
              </label>
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <Button
                label={EN.cancel}
                onClick={() => {
                  setIsModalOpen(false);
                  setOTP("");
                }}
                activeBtn={true}
                color="#F1F1F3"
                textColor="#000"
              />
              <Button
                label={EN.submit}
                onClick={() => otpSubmitHandler()}
                activeBtn={otp?.length === 6 ? true : false}
              />
            </div>
          </Box>
        </Modal>

        <LoadingModal
          isOpen={loading}
          setIsopen={setLoading}
          subtitle={EN.we_are_checking_the_verifying_the_information}
        />
      </div>
    </Layout>
  );
};

export default Aadhaar;
