import React from "react";

const ModalTitle = ({ title = "", subtitle = "" }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label
        style={{
          color: "#000",
          fontWeight: "600",
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        {title}
      </label>
      <label
        style={{
          color: "#767888",
          fontWeight: "500",
          fontSize: "16px",
          textAlign: "center",
          margin: "10px 0px",
        }}
      >
        {subtitle}
      </label>
    </div>
  );
};

export default ModalTitle;
