import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { EN } from "../../constants/Strings/EN";
import Title from "../../components/Title/Title";
import "./KycDocument.scss";
import UploadButton from "../../components/UploadButton/UploadButton";
import InstructionIcon from "../../assets/icons/instruction-icon.svg";
import Button from "../../components/Button/Button";
import { APICall } from "../../api/APICall";
import { async } from "q";
import {
  Validate_Udyam_Detail,
  upload_document_multipart,
} from "../../api/APIs";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { AppConstants } from "../../constants/AppConstants";
import useDedupe from "../../hooks/useDedupe";
import { useDispatch } from "react-redux";
import { setBackButton } from "../../redux/actions";
import CardView from "../../components/Layout/CardView";
import InputField from "../../components/InputField/InputField";
import { validateUdyam } from "../../util/util";
import useToast from "../../hooks/useToast";

const udyamHelperNode = (
  <>
    {EN.as_per_your_udyam_Registration_Certificate},&nbsp;
    <span className="udyamHelper">
      <a href="https://udyamregistration.gov.in" target="_blank">
        {EN.dont_have_Udyam_Click_here_to_get_help}
      </a>
    </span>
  </>
);

function KycDocument() {
  let userData = JSON.parse(localStorage.getItem("userData"));
  const { showToast } = useToast();

  const documentTypes = [
    "KycDocumentType.PanDoc",
    "KycDocumentType.AadharFrontDoc",
    "KycDocumentType.AadharBackDoc",
    "DocumentType.UdyamCertificate(4Pager).NoParsing",
  ];
  const { callDedupe } = useDedupe();
  const dispatch = useDispatch();
  const [activeBtn, setactiveBtn] = useState(false);
  const [currentDocIndex, setCurrentDocIndex] = useState(0);
  const [udyam, setUdyam] = useState("");
  const [checkFileLength, setcheckFileLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [udyamCertificate, setUdyamCertificate] = useState("");

  const [certError, setCertError] = useState("");
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const [kycDocArray, setkycDocArray] = useState([
    { id: 1, label: EN.pan_card, file: "", error: "" },
    { id: 2, label: EN.aadhaar_card_front, file: "", error: "" },
    { id: 3, label: EN.aadhaar_card_back, file: "", error: "" },
  ]);
  const [helper, setHelper] = useState({
    udyam: udyamHelperNode,
    businessVintageInYears: "",
  });
  const [udyamVerfied, setUdyamVerfied] = useState(false);
  const [successResCode, setSuccessResCode] = useState(0);
  const [stage, setStage] = useState(userData?.currentStage);

  useEffect(() => {
    dispatch(
      setBackButton({
        backButton: true,
      })
    );
    getUserData();
    getDefault();
  }, []);

  useEffect(() => {
    if (udyam.length > 0) {
      udyamValidation();
    } else {
      setHelper((prev) => ({
        ...prev,
        udyam: udyamHelperNode,
      }));
      setError((prev) => ({
        ...prev,
        udyam: false,
      }));
    }
  }, [udyam]);

  useEffect(() => {
    setStage(userData?.currentStage);
  }, [userData?.currentStage]);

  const getUserData = () => {
    callDedupe();
  };
  const getDefault = async () => {
    setLoading(true);

    if (userData?.udyamNumber) {
      setUdyam(userData?.udyamNumber);
      setUdyamVerfied(true);
      setHelper((prev) => ({
        ...prev,
        udyam: "Business name - " + userData.businessName,
      }));
      setError((prev) => ({
        ...prev,
        udyam: false,
      }));
    }

    setLoading(false);
  };

  const udyamValidation = () => {
    const res = validateUdyam(udyam);
    if (res) {
      verifyUdyam();
    } else {
      setHelper((prev) => ({
        ...prev,
        udyam: EN.please_enter_valid_Udyam_registration_number,
      }));
      setError((prev) => ({
        ...prev,
        udyam: true,
      }));
    }
  };

  const verifyUdyam = async () => {
    try {
      setLoading(true);
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          udyam: udyam,
        },
        HEADER: {
          AID: userData?.mobile,
          MID: userData?.mobile,
          userId: userData?.userId,
        },
      };

      let res = await APICall({
        url: Validate_Udyam_Detail,
        payload: PAYLOAD,
      });

      if (res.RESP_CODE === 300) {
        getUserData();
        setUdyamVerfied(true);
        setError((prev) => ({
          ...prev,
          udyam: false,
        }));
        setHelper((prev) => ({
          ...prev,
          udyam: res?.DATA.businessName,
        }));
      } else if (res.RESP_CODE === 301) {
        setUdyamVerfied(true);
        setError((prev) => ({
          ...prev,
          udyam: false,
        }));
        setHelper((prev) => ({ ...prev, udyam: res?.RESP_MSG }));
      } else if (res.RESP_CODE === 302) {
        setHelper((prev) => ({ ...prev, udyam: res?.RESP_MSG }));
        setUdyamVerfied(true);
        setError((prev) => ({
          ...prev,
          udyam: false,
        }));
        getUserData();
      } else {
        setUdyamVerfied(false);
        setError((prev) => ({
          ...prev,
          udyam: true,
        }));
        setHelper((prev) => ({ ...prev, udyam: res?.RESP_MSG }));
        showToast(res?.RESP_MSG, "error", 3000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // validate slected file size.
  const validateSelectedFile = (item) => {
    const MAX_FILE_SIZE = 5000;
    const fileSizeKiloBytes = item.file?.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      item.error = "File size is greater than 5MB";
    }
  };

  //   useEffect(() => {
  //       const allFilesHaveLength = kycDocArray.every(doc => doc.file.length > 0);
  //       setactiveBtn(allFilesHaveLength);
  //     }, [kycDocArray]);
  useEffect(() => {
    const allFilesPresent = kycDocArray.every(
      (item) => item?.file?.name?.length > 0
    );
    setactiveBtn(allFilesPresent);
  }, [kycDocArray]);

  const handleUpload = async () => {
    try {
      kycDocArray.map((item) => {
        validateSelectedFile(item);
      });
      handleOpen();
      let formData = new FormData();
      formData.append("file", kycDocArray[currentDocIndex].file);
      const documentType = documentTypes[currentDocIndex];
      formData.append(
        "request",
        JSON.stringify({
          DATA: {
            lenderCode: AppConstants.lenderCode,
            documentType: documentType,
          },
          HEADER: {
            AID: userData.mobile,
            MID: userData.mobile,
            userId: userData.userId,
          },
        })
      );
      const res = await APICall({
        url: upload_document_multipart,
        payload: formData,
      });
      if (res.RESP_CODE === 300) {
        if (res.DATA.docType === "KycDocumentType.PanDoc") {
          setCurrentDocIndex(1);
        } else if (res.DATA.docType === "KycDocumentType.AadharFrontDoc") {
          setCurrentDocIndex(2);
          if (userData.currentStage === "SANCTION_OFFER_CONFIRMED") {
            callDedupe(true);
            handleClose();
          }
        } else if (
          (res.DATA.docType = "DocumentType.UdyamCertificate(4Pager).NoParsing")
        ) {
          setCurrentDocIndex(3);
          callDedupe(true);
          handleClose();
        } else {
          handleClose();
        }
      } else {
        // Set the error for the current input
        setkycDocArray((prev) => {
          const updatedArray = [...prev];
          updatedArray[currentDocIndex].error = res.RESP_MSG;
          return updatedArray;
        });
        showToast(res?.RESP_MSG, "error", 3000);
        handleClose();
      }
    } catch (error) {
      handleClose();
    }
  };

  useEffect(() => {
    if (currentDocIndex > 0) {
      if (currentDocIndex < documentTypes.length) {
        handleUpload();
      }
    }
  }, [currentDocIndex]);

  useEffect(() => {
    if (userData.currentStage == "SANCTION_OFFER_CONFIRMED_UDYAM_REQUIRED") {
      setkycDocArray((prev) => {
        const hasId4 = prev.some((item) => item.id === 4);
        if (!hasId4) {
          return [
            ...prev,
            { id: 4, label: EN.udyam_certificate, file: "", error: "" },
          ];
        }
        return prev;
      });
    }
  }, []);
  useEffect(() => {
    udyamCertificate && handleUploadUdyam();
  }, [udyamCertificate]);

  useEffect(() => {
    if (successResCode == 300) {
      callDedupe();
      let intervalId = setInterval(() => {
        let localUser = JSON.parse(localStorage.getItem("userData"));
        if (localUser.currentStage == "LEAD_CONFIRM_UDYAM_MANUAL_SUCCESS") {
          setUdyamVerfied(true);
          clearInterval(intervalId);
          handleClose();
        } else if (
          localUser.currentStage == "LEAD_CONFIRM_UDYAM_MANUAL_FAILED" ||
          localUser.currentStage ==
            "LEAD_CONFIRM_UDYAM_MANUAL_FAILED_INVALID_DOC"
        ) {
          handleClose();
          showToast(localUser?.remark, "error", 3000);
          clearInterval(intervalId);
          setSuccessResCode(0);
        } else {
          callDedupe();
        }
      }, 30000);
    }
  }, [successResCode]);

  const handleUploadUdyam = async () => {
    try {
      handleOpen();
      let formData = new FormData();
      formData.append("file", udyamCertificate);
      formData.append(
        "request",
        JSON.stringify({
          DATA: {
            lenderCode: AppConstants.lenderCode,
            documentType: "DocumentType.UdyamCertificate(4Pager)",
          },
          HEADER: {
            AID: userData.mobile,
            MID: userData.mobile,
            userId: userData.userId,
          },
        })
      );
      const res = await APICall({
        url: upload_document_multipart,
        payload: formData,
      });
      if (res.RESP_CODE === 300) {
        setCertError("");
        setSuccessResCode(res.RESP_CODE);
      } else {
        setCertError(res.RESP_MSG);
        handleClose();
      }
    } catch (error) {
      handleClose();
    }
  };

  return (
    <>
      <Layout>
        <LoadingModal
          isOpen={isOpen}
          subTitle={EN.we_are_checking_the_submitted_document}
        />
        <LoadingModal isOpen={loading} />
        <div className="kyc-document-container">
          <div className="kyc-document-body">
            <Title
              title={EN.upload_kyc_documents}
              subTitle={EN.we_need_this_document_for_performing_the_KYC_checks}
            ></Title>
            <CardView label="Personal documents">
              <div className="kyc-doc-inputBox">
                {kycDocArray?.map((singleKycDoc, index) => {
                  return (
                    <div>
                      <UploadButton
                        setkycDocArray={setkycDocArray}
                        setCurrentDocIndex={setCurrentDocIndex}
                        index={index}
                        label={singleKycDoc.label}
                        // error={singleKycDoc.error}
                        accept={"image/*, application/pdf"}
                      />
                      {/* {singleKycDoc.error && (
                        <p className="kyc-doc-error">{singleKycDoc.error}</p>
                      )} */}
                    </div>
                  );
                })}
              </div>
              <div className="doc-instruction">
                <img alt="instruction-icon" src={InstructionIcon} />
                <p>JPG, JPEG, PNG, PDF upto 5MB</p>
              </div>
            </CardView>
            <CardView label="Business document">
              <div className="kyc-doc-inputBox">
                <InputField
                  label={EN.udyam_Registration_Number}
                  value={udyam}
                  maxLength={19}
                  helperText={helper.udyam}
                  error={error.udyam}
                  disabled={stage == "LEAD_CONFIRM_UDYAM_MANUAL_SUCCESS"}
                  // // disabled={isConfirm}
                  // error={error.udyam}
                  // tick={isConfirm}
                  // // tick={isVerified.udyam}
                  onChange={(e) => setUdyam(e.target.value.toUpperCase())}
                  // onHelperTextClick={() => setFaqVisible(true)}
                />
                <UploadButton
                  error={certError}
                  setError={setCertError}
                  setDocument={setUdyamCertificate}
                  disabled={stage == "LEAD_CONFIRM_UDYAM_MANUAL_SUCCESS"}
                  label="Upload Udyam"
                  accept="application/pdf"
                />
                {udyamCertificate && <p className="error-msg">{certError}</p>}
                {(stage == "LEAD_CONFIRM_UDYAM_MANUAL_FAILED_INVALID_DOC" ||
                  stage == "LEAD_CONFIRM_UDYAM_MANUAL_FAILED") && (
                  <p className="error-msg">{userData?.remark}</p>
                )}
              </div>
              {stage == "LEAD_CONFIRM_UDYAM_MANUAL_SUCCESS" ||
              stage == "LEAD_CONFIRM_UDYAM_MANUAL_FAILED_VINTAGE" ||
              stage == "LEAD_CONFIRM_UDYAM_MANUAL_FAILED_PINCODE" ||
              stage == "LEAD_CONFIRM_UDYAM_MANUAL_FAILED" ||
              stage == "BUSINESS_INFO" ? (
                <div className="udyam-details-container">
                  <InputField
                    label={EN.udyam_Number}
                    value={userData?.udyamNumber}
                    disabled
                  />
                  <InputField
                    label={EN.bussiness_name}
                    value={userData.businessNameOnly}
                    disabled
                  />
                  <InputField
                    label={EN.business_address}
                    value={userData.udyamAddressLine1}
                    disabled
                    helperText={
                      stage == "LEAD_CONFIRM_UDYAM_MANUAL_FAILED_PINCODE"
                        ? userData?.remark
                        : ""
                    }
                    error={stage == "LEAD_CONFIRM_UDYAM_MANUAL_FAILED_PINCODE"}
                  />
                  <InputField
                    label={EN.business_vintage_as_per_date_of_incorporation}
                    value={`${userData?.businessVintageInYears} (year)`}
                    helperText={
                      stage == "LEAD_CONFIRM_UDYAM_MANUAL_FAILED_VINTAGE"
                        ? userData?.remark
                        : ""
                    }
                    error={stage == "LEAD_CONFIRM_UDYAM_MANUAL_FAILED_VINTAGE"}
                    disabled
                  />
                </div>
              ) : (
                <>
                  <div className="doc-instruction">
                    <img
                      alt="InstructionIcon"
                      className="instruction"
                      src={InstructionIcon}
                    />
                    <p>PDF upto 5MB</p>
                  </div>
                  <div className="instructions-container">
                    <p className="instructions-title">
                      Important Instructions:
                    </p>
                    <div>
                      <ul>
                        <li className="instructions-item">
                          Business incorporation date should be before the date
                          of commencement.
                        </li>
                        <li className="instructions-item">
                          The date of commencement should be at least 1 year
                          old.
                        </li>
                        <li className="instructions-item">
                          Ensure your certificate is not cut/folded when
                          printing.
                        </li>
                        <li className="instructions-item">
                          <span>
                            <a
                              href="https://udyamregistration.gov.in"
                              target="_blank"
                            >
                              Edit your certificate
                            </a>
                          </span>{" "}
                          via the Udyam website.
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
            </CardView>
          </div>
          <div className="submit-btn">
            <Button
              activeBtn={
                activeBtn && stage == "LEAD_CONFIRM_UDYAM_MANUAL_SUCCESS"
              }
              label={EN.submit_n_next}
              onClick={handleUpload}
            />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default KycDocument;
