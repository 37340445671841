import React, { useEffect, useState } from "react";
import "./LoanDetailsCard.scss";
import { EN } from "../../constants/Strings/EN";
import { APICall } from "../../api/APICall";
import { Get_Repayment_Schedule } from "../../api/APIs";
import useToast from "../../hooks/useToast";
import { AppConstants } from "../../constants/AppConstants";
import Button from "../Button/Button";
import { Modal } from "@mui/material";
import ModalTitle from "../ModalTitle/ModalTitle";
import { IoMdClose } from "react-icons/io";

function LoanDetailsCard({ loanDetails }) {
  let userData = JSON.parse(localStorage.getItem("userData"));

  const { showToast } = useToast();
  const [replaymentData, setReplaymentData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    getReplaymentData();
  }, []);

  const getReplaymentData = async () => {
    try {
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
        },
        HEADER: {
          AID: userData?.mobile,
          MID: userData?.mobile,
          userId: userData?.userId,
        },
      };

      let res = await APICall({
        url: Get_Repayment_Schedule,
        payload: PAYLOAD,
      });
      if (res.RESP_CODE === 300) {
        setReplaymentData(res.DATA);
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
      }
    } catch (error) {
      console.log("ERR", error);
    }
  };

  return (
    <>
      <div className="loan-card">
        <p className="loan-type">{loanDetails.loanType}</p>
        <div className="loan-stats">
          <p className="loan-id">
            {EN.loan} - {loanDetails.loanId}
          </p>
          <p className="umr-no">
            {EN.utrno} - {loanDetails.umrn}
          </p>
          <div className="loan-params">
            <div className="loan-block">
              <p className="loan-label">{EN.loan_amount}</p>
              <p className="loan-value">{loanDetails.amount}</p>
            </div>
            <div className="loan-block">
              <p className="loan-label">{EN.interest_rate}</p>
              <p className="loan-value">
                {loanDetails.rateOfInterest}% {EN.p_a}
              </p>
            </div>
            <div className="loan-block">
              <p className="loan-label">{EN.tenure}</p>
              <p className="loan-value">
                {loanDetails.tenure} {EN.months}
              </p>
            </div>
          </div>
          <p className="repayment" onClick={() => setModalVisible(true)}>
            Repayment schedule
          </p>
          <p className="repayment">Repayment loan</p>
        </div>
      </div>
      <Modal open={modalVisible}>
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-header">
              <ModalTitle title="Repayment Schedule" />
              <IoMdClose size={30} onClick={() => setModalVisible(false)} />
            </div>
            <div className="modal-content">
              <div className="modal-scroll">
                <table>
                  <thead>
                    <tr>
                      <th>Due date</th>
                      <th>Amount due</th>
                      <th>Principal</th>
                      <th>Interest</th>
                    </tr>
                  </thead>
                  <tbody>
                    {replaymentData.map((user) => (
                      <tr key={user.instalmentNumber}>
                        <td>{user.dueDate}</td>
                        <td>{user.installmentAmount}</td>
                        <td>{user.principalAmount}</td>
                        <td>{user.interestAmount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default LoanDetailsCard;
