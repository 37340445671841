import { Modal } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BankLoan from "../../assets/LoanBank.svg";
import Button from "../../components/Button/Button";
import InputField from "../../components/InputField/InputField";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { EN } from "../../constants/Strings/EN";
import useDedupe from "../../hooks/useDedupe";
import useNavigateDedupe from "../../hooks/useNavigateDedupe";
import { setUser } from "../../redux/actions";
import "./Home.scss";
import useToast from "../../hooks/useToast";

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const { callDedupe } = useDedupe();
  const { getPath } = useNavigateDedupe();
  const { showToast } = useToast();

  useEffect(() => {
    redirectByUrl();
  }, []);
  //
  const redirectByUrl = async () => {
    setLoading(true);
    let url =
      "https://uat-airtel-ui.arthmate.com/?data=DF5BA1E3CA086EE0715636232641B28E0F24FA089F86D0B397FA7DB8452DC5C1939F5789222C66A4B376A14D137B1D56";
    let userInfo = window.location.href
      // url
      .split("data=")[1];
    try {
      let finalUrl = `https://mepwaapi.arthmate.com/lending/pwa/meesho/platform/web/user-dedupe-detail/?data=${userInfo}`;

      let res = await axios.get(finalUrl);

      if (res.data?.RESP_CODE === 300) {
        localStorage.removeItem("userData");
        const userData1 = {
          ...res.data?.DATA?.userData,
          currentStage: res.data?.DATA?.currentStage,
        };
        dispatch(setUser(userData1));
        localStorage.setItem("userData", JSON.stringify(userData1));
        getPath(res.data?.DATA?.currentStage);
      } else {
        showToast(res?.data?.RESP_MSG, "error", 3000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    // <Layout>
    <div className="home-container">
      {/* <div className="body">
        <div className="card-container">
          <div className="card"></div>
          <div className="card"></div>
        </div>

        <div className="loan-card">
          <div className="content-container">
            <p className="card-title">Get a loan upto ₹5 lakhs </p>
            <span className="card-subtitle">In 3 easy steps</span>
            <div className="card-btn">
              <p
                className="btn-title"
                onClick={() => {
                  //  navigate("/LoanLending")
                  setIsOpen(true);
                }}
              >
                KNOW MORE
              </p>
            </div>
          </div>
          <div className="img-container">
            <img src={BankLoan} />
          </div>
        </div>
      </div> */}
      {/* <Footer /> */}
      <Modal open={isOpen} className="enquiry-modal-container">
        <div className="enquiry-modal">
          <h5>Enter Details</h5>
          <InputField
            label="Full Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ margin: "1vh 0px" }}
          />
          <InputField
            label="Mobile Number"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            style={{ margin: "1vh 0px" }}
          />

          <Button
            label="Submit"
            activeBtn={true}
            onClick={() => {
              if (name && mobile) callDedupe(true, { name, mobile });
            }}
          />
          <LoadingModal
            isOpen={loading}
            setIsopen={setLoading}
            subtitle={EN.we_are_checking_the_verifying_the_information}
          />
        </div>
      </Modal>
      <LoadingModal
        isOpen={loading}
        setIsopen={setLoading}
        subtitle={EN.we_are_checking_the_verifying_the_information}
      />
    </div>
    // </Layout>
  );
}

export default Home;
