import React from "react";
import "./Progressbar.scss";
import { useSelector } from "react-redux";

function Progressbar({newClass}) {
  let step = JSON.parse(localStorage.getItem("step"));

  // const flag = 2;
  let currentStep = step || 1;

  return (
    <div className={`progress-container${newClass}`} >
      <div className={`progress-bar ${currentStep >= 1 && "active-bar"}`}></div>
      <div className={`progress-bar ${currentStep >= 2 && "active-bar"}`}></div>
      <div className={`progress-bar ${currentStep >= 3 && "active-bar"}`}></div>
      <div className={`progress-bar ${currentStep >= 4 && "active-bar"}`}></div>
      <div
        className={`progress-bar ${currentStep === 5 && "active-bar"}`}
      ></div>
    </div>
  );
}

export default Progressbar;
