import React from "react";
import "./Title.scss";

const Title = ({ title = "", subTitle = "" }) => {
  return (
    <div className="content-container">
      <p className="title">{title}</p>
      <p className="subTitle">{subTitle}</p>
    </div>
  );
};

export default Title;
