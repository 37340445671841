import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TimerIcon from "../../assets/icons/timerIcon.svg";
import Layout from "../../components/Layout/Layout";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { EN } from "../../constants/Strings/EN";
import useDedupe from "../../hooks/useDedupe";
import "./ESignPending.scss";
import { setBackButton } from "../../redux/actions";
import Button from "../../components/Button/Button";
import { AppConstants } from "../../constants/AppConstants";
import { APICall } from "../../api/APICall";
import { Esign_Status_Check } from "../../api/APIs";
import useToast from "../../hooks/useToast";

const ESignPending = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));

  const { callDedupe } = useDedupe();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [openEsignModel, setOpenEsignModel] = useState(false);

  const URL = useSelector((state) => state?.urls?.eSignUrl);

  const dispatch = useDispatch();

  useEffect(() => {
    openUrl();
    dispatch(
      setBackButton({
        backButton: false,
      })
    );
  }, []);

  const openUrl = async () => {
    URL != "" && setOpenEsignModel(true);

    let intet = setInterval(() => {
      let userData = JSON.parse(localStorage.getItem("userData"));
      if (
        userData?.currentStage === "ESIGN_SUCCESS" ||
        userData?.currentStage === "ESIGN_FAILED"
      ) {
        clearInterval(intet);
        callDedupe(true);
        setLoading(false);
        setOpenEsignModel(false);
      } else {
        callDedupe();
      }
    }, 5000);
  };

  const verifyHandler = async () => {
    try {
      setLoading(true);
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
        },
        HEADER: {
          AID: userData.mobile,
          MID: userData.mobile,
          userId: userData.userId,
        },
      };
      let res = await APICall({ url: Esign_Status_Check, payload: PAYLOAD });
      if (res.RESP_CODE === 300) {
        callDedupe(true);
        setLoading(false);
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="enach-pending-container">
        <div className="process-img-container">
          <img src={TimerIcon} />
        </div>
        <div className="process-main-content">
          <p className="process-subtitle">
            {
              EN.your_e_sign_process_has_been_initiated_please_complete_the_same_to_submit_your_application_Click_on_verify_and_complete_the_rest_of_the_process_to_successfully_submit_your_application
            }
          </p>
          <Button label="Verify" activeBtn onClick={verifyHandler} />
        </div>
      </div>
      <Modal open={openEsignModel}>
        <div className="esign-model-container">
          <div
            onClick={() => {
              setOpenEsignModel(false);
            }}
          >
            <p style={{ paddingBottom: "7px" }}>Esign Register</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            <iframe
              height="100%"
              width="100%"
              src={URL}
              title="esign auth url"
            ></iframe>
          </div>
        </div>
      </Modal>
      <LoadingModal isOpen={loading} />
    </Layout>
  );
};

export default ESignPending;
