import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { APICall } from "../../api/APICall";
import {
  Generate_E_Agreement,
  Generate_Esign_Url,
  Generate_Sanction_Letter,
  Get_Key_Fact_Details,
} from "../../api/APIs";
import Button from "../../components/Button/Button";
import DocumentModal from "../../components/DocumentModal/DocumentModal";
import Layout from "../../components/Layout/Layout";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import PDFViewLayout from "../../components/PDFViewLayout/PDFViewLayout";
import { AppConstants } from "../../constants/AppConstants";
import { EN } from "../../constants/Strings/EN";
import useDedupe from "../../hooks/useDedupe";
import useToast from "../../hooks/useToast";
import { setBackButton, setEsignUrl } from "../../redux/actions";
import "./ViewFiles.scss";

const ViewFiles = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let showHelper = JSON.parse(localStorage.getItem("showHelper"));
  const [loading, setLoading] = useState(false);
  const [SLUrl, setSLUrl] = useState("");
  const [LBAUrl, setLBAUrl] = useState("");
  const [SLConsent, setSLConsent] = useState(false);
  const [LBAConsent, setLBAConsent] = useState(false);
  const [SLModal, setSLModal] = useState(false);
  const [LBAModal, setLBAModal] = useState(false);
  const [KFSData, setKFSData] = useState();
  const { showToast } = useToast();
  const { callDedupe } = useDedupe();
  const dispatch = useDispatch();
  useEffect(() => {
    getKFS();
    dispatch(
      setBackButton({
        backButton: false,
      })
    );
  }, []);

  useEffect(() => {
    SLConsent && getSL();
  }, [SLConsent]);

  useEffect(() => {
    LBAConsent && getLBA();
  }, [LBAConsent]);

  const getSL = async () => {
    try {
      setLoading(true);

      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          consentText: EN.i_accept + " KFS and " + EN.sanction_letter,
          consentType: "ConsentType.SL and LBA",
        },
        HEADER: {
          AID: userData?.mobile,
          MID: userData?.mobile,
          userId: userData?.userId,
        },
      };

      let res = await APICall({
        url: Generate_Sanction_Letter,
        payload: PAYLOAD,
      });

      if (res.RESP_CODE === 300) {
        setSLUrl(res.DATA?.signedUrl);
        setSLModal(true);
      } else showToast(res?.RESP_MSG, "error", 3000);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const getLBA = async () => {
    try {
      setLoading(true);

      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          consentText: EN.i_accept + " " + EN.lender_borrower_agreement,
          consentType: "ConsentType.Aggrement",
        },
        HEADER: {
          AID: userData?.mobile,
          MID: userData?.mobile,
          userId: userData?.userId,
        },
      };

      let res = await APICall({
        url: Generate_E_Agreement,
        payload: PAYLOAD,
      });

      if (res.RESP_CODE === 300) {
        setLBAUrl(res.DATA?.signedUrl);
        setLBAModal(true);
      } else showToast(res?.RESP_MSG, "error", 3000);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const getKFS = async () => {
    try {
      setLoading(true);

      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
        },
        HEADER: {
          AID: userData?.mobile,
          MID: userData?.mobile,
          userId: userData?.userId,
        },
      };

      let res = await APICall({
        url: Get_Key_Fact_Details,
        payload: PAYLOAD,
      });

      if (res.RESP_CODE === 300) {
        let kfsar = [
          { label: "Borrower Name", value: res.DATA?.borrowerName },
          { label: "Lead ID", value: res.DATA?.leadId },
          { label: "Loan ID", value: res.DATA?.loanId },
          { label: "Lender Name", value: res.DATA?.lenderName },
          { label: "Loan Amount", value: res.DATA?.loanAmount },
          { label: "Interest Rate", value: res.DATA?.interestRate },
          { label: "Total Interest", value: res.DATA?.totalInterest },
          { label: "Processing Fees", value: res.DATA?.processingFees },
          {
            label: "Other Charges (Stamp charge)",
            value: res.DATA?.otherCharge,
          },
          { label: "Net Disbursement", value: res.DATA?.disburseAmount },
          {
            label: "Total amount to be paid by borrower",
            value: res.DATA?.totalAmountPaid,
          },
          { label: "APR", value: res.DATA?.anualPerRate },
          { label: "Tenure", value: res.DATA?.tenure },
          { label: "Repayment Type", value: res.DATA?.repaymentType },
          { label: "Installment Amount", value: res.DATA?.emiAmount },
          { label: "LSP Name", value: res.DATA?.lspName },
          {
            label: "Account details of the borrower",
            value: res.DATA?.bankAcountNumber,
          },
        ];
        setKFSData(kfsar);
      } else showToast(res?.RESP_MSG, "error", 3000);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const submitHandler = async () => {
    try {
      setLoading(true);

      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
        },
        HEADER: {
          AID: userData?.mobile,
          MID: userData?.mobile,
          userId: userData?.userId,
        },
      };

      let res = await APICall({
        url: Generate_Esign_Url,
        payload: PAYLOAD,
      });

      if (res.RESP_CODE === 300) {
        dispatch(
          setEsignUrl({
            eSignUrl: res.DATA?.esignWebUrl,
          })
        );
        callDedupe(true);
        setLoading(false);
      } else {
        showToast(res?.RESP_MSG, "error", 3000);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const KFSContent = () => {
    return KFSData ? (
      <div className="kfs-contnet">
        {KFSData?.map((i) => {
          return (
            <div>
              <p>{i.label}</p>
              <p>{i.value}</p>
            </div>
          );
        })}
      </div>
    ) : (
      <center>
        <h4>Loading...!</h4>
      </center>
    );
  };

  return (
    <Layout>
      <div className="view-files-container">
        <div>
          <div>
            <PDFViewLayout
              label={EN.key_fact_statement}
              viewOnly
              content={<KFSContent />}
              // contentStyle={
              //   showHelper ? { height: "90%", padding: "0 0 10px 0" } : {}
              // }
            />
          </div>
          <div>
            <div className="consent-container">
              <input
                type="checkbox"
                checked={SLConsent}
                onChange={(e) => setSLConsent(true)}
              />
              <span>
                {EN.i_accept}&nbsp;KFS and&nbsp;
                <span
                  style={{ textDecorationLine: SLUrl ? "underline" : "none" }}
                  onClick={() => SLUrl && setSLModal(true)}
                >
                  {EN.sanction_letter}
                </span>
              </span>
            </div>
            <div className="consent-container">
              <input
                type="checkbox"
                checked={LBAConsent}
                onChange={(e) => setLBAConsent(true)}
              />
              <span>
                {EN.i_accept}&nbsp;
                <span
                  style={{ textDecorationLine: LBAUrl ? "underline" : "none" }}
                  onClick={() => LBAUrl && setLBAModal(true)}
                >
                  {EN.lender_borrower_agreement}
                </span>
              </span>
            </div>
          </div>
        </div>
        {showHelper && (
          <div className="helper-container">
            <p>{EN.view_files_descn}</p>
          </div>
        )}
        <Button
          label={EN.verify}
          activeBtn={SLConsent && LBAConsent}
          onClick={submitHandler}
        />
      </div>
      <DocumentModal
        isOpen={SLModal}
        setIsOpen={setSLModal}
        label={EN.sanction_letter}
        src={SLUrl}
      />
      <DocumentModal
        isOpen={LBAModal}
        setIsOpen={setLBAModal}
        label={EN.lender_borrower_agreement}
        src={LBAUrl}
      />
      <LoadingModal isOpen={loading} setIsopen={setLoading} />
    </Layout>
  );
};

export default ViewFiles;
