import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { APICall } from "../../api/APICall";
import { Get_Contact_Details } from "../../api/APIs";
import { AppConstants } from "../../constants/AppConstants";
import { EN } from "../../constants/Strings/EN";
import useToast from "../../hooks/useToast";
import Title from "../Title/Title";
import "./ContactUsModal.scss";
// import { MailIcon, PhoneIcon } from '../../constants/SVG';
import mailIcon from "../../assets/icons/mail.svg";
import phoneIcon from "../../assets/icons/phone.svg";
import ModalTitle from "../ModalTitle/ModalTitle";
import { IoMdClose } from "react-icons/io";

function ContactUsModal({ isOpen = true, handleClose }) {
  const [contactDetails, setContactDetails] = useState([]);
  const { showToast } = useToast();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const getContactDetails = async () => {
    const payload = {
      DATA: {
        lenderCode: AppConstants.lenderCode,
      },
      HEADER: {
        AID: userData.mobile,
        MID: userData.mobile,
        userId: userData.userId,
      },
    };

    await APICall({ url: Get_Contact_Details, payload: payload })
      .then((backendRes) => {
        if (backendRes.RESP_CODE === 300) {
          setContactDetails(backendRes.DATA);
        } else {
        }
      })
      .catch((error) => {
        // setLoading(false);
      });
  };
  useEffect(() => {
    if (isOpen) {
      getContactDetails();
    }
  }, [isOpen]);

  return (
    contactDetails.length > 0 && (
      <Modal open={isOpen} onClose={handleClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            backgroundColor: "#fff",
            padding: "1rem",
            outline: "none",
            borderRadius: "8px",
          }}
        >
          {contactDetails?.length > 0 ? (
            <div className="contact-container">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <ModalTitle title={EN.contact_us} />
                <IoMdClose size={30} onClick={handleClose} />
              </div>
              {contactDetails?.map((person) => {
                return (
                  <div className="contact-card">
                    <div className="service-company">
                      <img
                        className="company-logo"
                        src={person?.logoUrl}
                        alt="service-logo"
                      />
                    </div>
                    <div className="contact-info">
                      <div className="contact-container">
                        <div className="contact-label">
                          <img src={phoneIcon} alt="phone" srcset="" />
                          {/* <PhoneIcon viewBox='0 0 24 24' /> */}
                          {EN.call_us}
                        </div>
                        <div className="contact-details">
                          <p className="contact-name">{person?.name}</p>
                          <p className="contact">{person?.mobile}</p>
                        </div>
                      </div>
                      <div className="contact-container">
                        <div className="contact-label">
                          <img src={mailIcon} alt="mail id" srcset="" />
                          {/* <MailIcon viewBox='0 0 24 24' /> */}
                          {EN.email}
                        </div>
                        <div className="contact-details">
                          <p className="contact-name"></p>
                          <p
                            className="contact"
                            //   style={{ display: "flex", overflowWrap: "anywhere" }}
                          >
                            {person?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p>Unable to fetch contact details!</p>
          )}
        </div>
      </Modal>
    )
  );
}

export default ContactUsModal;
