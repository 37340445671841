import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APICall } from "../api/APICall";
import { User_Dedupe_Detail } from "../api/APIs";
import { setStep, setUser } from "../redux/actions";
import { AppConstants } from "../constants/AppConstants";
import useNavigateDedupe from "./useNavigateDedupe";
// import { userData } from "../util/getLocalData";

const useDedupe = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getPath } = useNavigateDedupe();

  const callDedupe = async (navigateFlag = false, manualData = {}) => {
    const { name, mobile } = manualData;

    try {
      let PAYLOAD = {
        DATA: {
          lenderCode: AppConstants.lenderCode,
          applicationName: name ? name : userData?.applicationName,
        },
        HEADER: {
          AID: mobile ? mobile : userData.mobile,
          MID: mobile ? mobile : userData.mobile,
        },
      };

      let res = await APICall({
        url: User_Dedupe_Detail,
        payload: PAYLOAD,
      });
      if (res?.RESP_CODE === 300) {
        const userData1 = {
          ...res?.DATA?.userData,
          currentStage: res?.DATA?.currentStage,
        };
        dispatch(setUser(userData1));
        localStorage.setItem("userData", JSON.stringify(userData1));
        navigateFlag && getPath(res.DATA?.currentStage);
      }
    } catch (e) {}
  };

  return { callDedupe };
};

export default useDedupe;
