import React, { Fragment, useMemo, useState } from "react";
// import LeftArrow from "../../assets/icons/leftArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { HelpIcon, LeftArrow } from "../../constants/SVG";
import {
  setBackButton,
  setIsOfferConfirm,
  setOpenAddressModelOnBack,
} from "../../redux/actions";
import BackModel from "../BackModel/BackModel";
import ContactUsModal from "../ContactUsModal/ContactUsModal";
import Progressbar from "../ProgressBar/Progressbar";
import "./Header.scss";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [openContactUsModal, setOpenContactUsModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const backButtonFlag = useSelector((state) => state.BackButton);
  const { user } = useSelector((state) => state.user);
  const userData = JSON.parse(localStorage.getItem("userData"));

  let headerExpData = ["/Regret", "/CibilRegret"];

  let backBtnExpData = [
    "/Regret",
    "/CibilRegret",
    "/LoanApplicationPending",
    "/LoanDisbursementPending",
    "/LoanOfferPending",
    "/dashboard",
    "/Details",
  ];

  let progressBarExpData = [
    "/dashboard",
    "/profile",
    "/my-loans",
    "/ESignPending",
    "/EnachPending",
    "/LoanOfferPending",
    "/LoanApplicationPending",
    "/LoanDisbursementPending",
    ...(user?.currentStage === "ENACH_INITIATED" ? ["/ENachDetails"] : []),
  ];

  const handleBack = () => {
    switch (pathname) {
      case "/profile":
        navigate("/dashboard");
        break;
      case "/my-loans":
        navigate("/dashboard");
        break;
      case "/KycDocument":
        navigate("/Offer");
        break;
      case "/AdditionalDocument":
        navigate("/LoanDisbursementPending");
        break;

      case "/Offer":
        dispatch(
          setIsOfferConfirm({
            isOfferConfirm: false,
          })
        );
        break;

      case "/Aadhaar":
        dispatch(
          setBackButton({
            backButton: false,
          })
        );
        dispatch(
          setOpenAddressModelOnBack({
            openAddressModelOnBack: true,
          })
        );
        break;

      default:
        break;
    }
  };

  return (
    <Fragment>
      <div
        className={
          !headerExpData.includes(pathname) ? "header-progress" : "header"
        }
      >
        <BackModel isOpen={isOpen} setIsOpen={setIsOpen} />
        {!backBtnExpData.includes(pathname) && backButtonFlag.backButton ? (
          <LeftArrow viewBox="0 -2 15 24" onClick={() => handleBack()} />
        ) : (
          ""
        )}
        {!headerExpData.includes(pathname) &&
          // user?.currentStage !== "ENACH_INITIATED" &&
          user && (
            <>
              <Progressbar
                newClass={
                  progressBarExpData.includes(pathname) ? " hide-progress" : ""
                }
              />
              <HelpIcon
                className="help-icon"
                onClick={() => setOpenContactUsModal(true)}
              />
            </>
          )}
        <ContactUsModal
          isOpen={openContactUsModal}
          handleClose={() => setOpenContactUsModal(false)}
        />
      </div>
    </Fragment>
  );
};

export default Header;
