import React, { useEffect, useState } from "react";
import UploadButton from "../../components/UploadButton/UploadButton";
import Button from "../../components/Button/Button";
import { EN } from "../../constants/Strings/EN";
import "./BetterBankStatement.scss";
import useDedupe from "../../hooks/useDedupe";
import InputField, {
  FileInputField,
} from "../../components/InputField/InputField";
import { AppConstants } from "../../constants/AppConstants";
import { APICall } from "../../api/APICall";
import { upload_Bank_Statement } from "../../api/APIs";
import LoadingModal from "../../components/LoadingModal/LoadingModal";

function BetterBankStatement() {
  let userData = JSON.parse(localStorage.getItem("userData"));
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [activeBtn, setactiveBtn] = useState(true);
  const [statementDoc, setStatementDoc] = useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const { callDedupe } = useDedupe();
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const [dynamicInputs, setdynamicInput] = useState([
    { id: 1, documentName: "", file: "" },
  ]);
  const [isFilepresent, setisFilepresent] = useState();

  useEffect(() => {
    setError(
      `Please upload the bank statement from ${
        JSON.parse(userData?.remark)?.start
      } to ${JSON.parse(userData?.remark)?.end}`
    );
  }, []);

  // validate slected file size.
  const validateSelectedFile = () => {
    const MAX_FILE_SIZE = 10000;
    const fileSizeKiloBytes = statementDoc.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setError("File size is greater than 10MB");
    }
  };

  const handleAddInput = () => {
    const lastNumericPart =
      dynamicInputs.length > 0
        ? Math.max(...dynamicInputs.map((input) => Number(input.id)))
        : 0;
    const newInputId = lastNumericPart + 1;
    const newInput = { id: newInputId, label: `Document ${newInputId}` };
    setdynamicInput((prevInputs) => [...prevInputs, newInput]);
  };

  const onChange = (e) => {
    setPassword(e.target.value);
  };

  const handleDeleteDoc = (index) => {
    const dynamicInputsCopy = [...dynamicInputs];
    dynamicInputsCopy.splice(index, 1);
    setdynamicInput(dynamicInputsCopy);
  };

  const handleSubmit = async () => {
    try {
      validateSelectedFile();
      if (!error) {
        handleOpen();

        let formData = new FormData();
        const fileNamesArray = dynamicInputs.map((input) => input.file);
        fileNamesArray.forEach((fileName, index) => {
          formData.append(`file`, fileName);
        });
        formData.append(
          "request",
          JSON.stringify({
            DATA: {
              lenderCode: AppConstants.lenderCode,
              documentType: "IncomeProofDocument.BankStatement",
              documentPassword: password ? password : "",
            },
            HEADER: {
              AID: userData.mobile,
              MID: userData.mobile,
              userId: userData.userId,
            },
          })
        );
        if (!error) {
          let res = await APICall({
            url: upload_Bank_Statement,
            payload: formData,
          });

          if (res.RESP_CODE !== 300) {
            setError(res.RESP_MSG);
            handleClose();
          } else {
            setError("");
            handleClose();
            callDedupe(true);
          }
        }
      }
    } catch (error) {
      handleClose();
    }
  };
  return (
    <>
      <LoadingModal
        isOpen={isOpen}
        subTitle={EN.we_are_checking_the_submitted_document}
      />
      <div className="better-bank-statement-box">
        <div className="line-container">
          <div className="line"></div>
          <div className="or-text">OR</div>
          <div className="line"></div>
        </div>
        <div className="bank-statement-instruction">
          <p>
            Want a better offer? Try again with a different bank statement that
            shows more transactions.
          </p>
          <p>
            If the files are password-protected, please provide the password.
          </p>
        </div>
        <div className="better-statement-input-box">
          {/* <UploadButton
            error={error}
            setError={setError}
            accept={"application/pdf"}
            setDocument={setStatementDoc}
            label={EN.bank_statement_6_months}
          />
          {statementDoc && <p className="error-msg">{error}</p>} */}

          {dynamicInputs.map((singleInput, index) => (
            <FileInputField
              handleDeleteDoc={handleDeleteDoc}
              index={index}
              setisFilepresent={setisFilepresent}
              inputId={index + 1}
              singleInput={singleInput}
              key={index}
              error={
                error.includes("Please upload the bank statement from")
                  ? false
                  : error
              }
              setError={setError}
              dynamicInputs={dynamicInputs}
              label={EN.bank_statement_6_months}
            />
          ))}
          {isFilepresent && <p className="error-msg">{error}</p>}
          {error.includes("Please upload the bank statement from") && (
            <p className="non-error-helper-msg">{error}</p>
          )}

          {!isFilepresent?.name?.length ? (
            ""
          ) : (
            <p
              className="upload-additional-doc"
              onClick={handleAddInput}
              style={{
                display: dynamicInputs[dynamicInputs.length - 1].file
                  ? "inline-block"
                  : "none",
              }}
            >
              {EN.upload_additional_bank_statements}
            </p>
          )}
        </div>
        <div className="file-pass-instruction">
          {/* <p>
              If the files are password-protected, please provide the password.
            </p> */}
          <InputField
            value={password}
            onChange={onChange}
            label={EN.enter_file_password}
          />
        </div>
        <div className="submit-btn">
          <Button
            onClick={handleSubmit}
            activeBtn={isFilepresent?.name?.length && activeBtn}
            label={EN.submit_n_next}
          />
        </div>
      </div>
    </>
  );
}

export default BetterBankStatement;
