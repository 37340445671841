import { SvgIcon } from "@mui/material";
import { ReactComponent as LeftArrowAVG } from "../assets/icons/leftArrow.svg";
import { ReactComponent as DownloadIconSVG } from "../assets/icons/download.svg";
import { ReactComponent as KYCStepSVG } from "../assets/KYCStep.svg";
import { ReactComponent as InfoIcon } from "../assets/InfoIcon.svg";
import { ReactComponent as NotFoundSVG } from "../assets/notFound.svg";
import { ReactComponent as PhoneSVG } from "../assets/icons/phone.svg";
import { ReactComponent as MailSVG } from "../assets/icons/mail.svg";
import { ReactComponent as ColoredProfileSVG } from "../assets/coloredProfile.svg";
import { ReactComponent as GreenTickSVG } from "../assets/icons/greenTick.svg";

const LeftArrow = (props) => {
  return (
    <SvgIcon {...props}>
      <LeftArrowAVG />
    </SvgIcon>
  );
};

const DownloadIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <DownloadIconSVG />
    </SvgIcon>
  );
};

const GreenTickIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <GreenTickSVG />
    </SvgIcon>
  );
};

const DashboardProfileIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <ColoredProfileSVG />
    </SvgIcon>
  );
};

const KYCStep = (props) => {
  return (
    <SvgIcon {...props}>
      <KYCStepSVG />
    </SvgIcon>
  );
};

const HelpIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <InfoIcon />
    </SvgIcon>
  );
};

const NotFoundIcon = (props) => {
  <SvgIcon {...props}>
    <NotFoundSVG />
  </SvgIcon>;
};

const PhoneIcon = (props) => {
  <SvgIcon {...props}>
    <PhoneSVG />
  </SvgIcon>;
};

const MailIcon = (props) => {
  <SvgIcon {...props}>
    <MailSVG />
  </SvgIcon>;
};

export {
  LeftArrow,
  KYCStep,
  HelpIcon,
  DownloadIcon,
  NotFoundIcon,
  PhoneIcon,
  MailIcon,
  DashboardProfileIcon,
  GreenTickIcon,
};
