export const panFormatter = (panNumber) => {
  if (panNumber.length === 10) {
    const panRegex = /^([A-Z]){3}P([A-Z]){1}([0-9]){4}([A-Z]){1}?$/;
    const validPAN = panRegex.test(panNumber);

    return validPAN;
  }
};

export const validateEmail = (email) => {
  const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validEmail = reg.test(email);
  return validEmail;
};

export const validateUdyam = (no) => {
  if (no.length === 19) {
    const reg = /^UDYAM-[A-Z]{2}-\d{2}-\d{7}$/;
    const validUdyam = reg.test(no);
    return validUdyam;
  }
};

export const validateMobileNumber = (number) => {
  const reg = /^[6-9]\d{9}$/;
  const isValidMobileNumber = reg.test(number);
  return isValidMobileNumber;
};
