import { Modal } from "@mui/material";
import React from "react";
import Label from "../Label/Label";
import { EN } from "../../constants/Strings/EN";
import PDFViewLayout from "../PDFViewLayout/PDFViewLayout";

const DocumentModal = ({
  isOpen = false,
  setIsOpen,
  src = "",
  label = "",
  content = "",
  viewOnly = false,
}) => {
  return (
    <Modal
      open={isOpen}
      //   onClose={handleClose}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          backgroundColor: "#fff",
          padding: "1rem",
          outline: "none",
          borderRadius: "8px",
        }}
      >
        <PDFViewLayout
          label={label}
          src={src}
          isModal
          onClose={() => setIsOpen(false)}
          content={content}
          viewOnly={viewOnly}
        />
      </div>
    </Modal>
  );
};

export default DocumentModal;
